import React, { Fragment } from "react";
// import { Router, Link as RouterLink } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import PageHeading from "../components/page-heading";
import { Typography, Box, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  narrowPageBox: theme.narrowPageBox,
  insuranceHeading: {
    [theme.breakpoints.only("xs")]: {
      marginBottom: 14
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: 26
    }
  },
  requirementsPreamble: {
    [theme.breakpoints.only("xs")]: {
      marginBottom: 12
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: 22
    }
  },
  key: { fontWeight: 500 },
  lastRequirementParagraph: { marginBottom: 18 },
}));

export default function InsuranceRequirements({ ...other }) {
  const classes = useStyles();

  return (
    <Fragment>
      <Box className={classes.narrowPageBox}>
        <PageHeading className={classes.insuranceHeading}>Insurance Requirements</PageHeading>
        <Typography variant="body1" className={classes.requirementsPreamble}>Start an auto insurance policy for the car. Be sure your name matches your driver license exactly.</Typography>
        <Grid container className={classes.requirementsContainer}>
          <Grid item xs={12} sm={6} md={5}><Typography className={classes.key}>Additional Insured and Loss Payee</Typography></Grid>
          <Grid item xs={12} sm={6} md={7}>
            <Typography variant="body1">SKIP'S LLC</Typography>
            <Typography variant="body1">145 S Route 303, Ste 100</Typography>
            <Typography variant="body1" className={classes.lastRequirementParagraph}>West Nyack, NY 10994-2713</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={5}><Typography className={classes.key}>Bodily Injury Liability coverage</Typography></Grid>
          <Grid item xs={12} sm={6} md={7}>
            <Typography variant="body1" className={classes.lastRequirementParagraph}>minimum $100,000 each person, $300,000 each accident</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={5}><Typography className={classes.key}>Property Damage Liability coverage</Typography></Grid>
          <Grid item xs={12} sm={6} md={7} className={classes.lastRequirementParagraph}>
            <Typography variant="body1">minimum $50,000</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={5}><Typography className={classes.key}>Collision and Comprehensive coverage</Typography></Grid>
          <Grid item xs={12} sm={6} md={7} className={classes.lastRequirementParagraph}>
            <Typography variant="body1">maximum deductible $1,000</Typography>
          </Grid>
        </Grid>
      </Box>
    </Fragment >
  );
}
