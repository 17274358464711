import React, { Fragment } from "react";
import { useQuery, useApolloClient, useMutation } from "@apollo/react-hooks";
import { navigate } from "@reach/router";
import gql from "graphql-tag";
import {
  Loading,
  DateDetail,
  LocationDetail,
  CarDetail,
  CarImage,
  PageHeading, LeaseReserve, LeaseIncluded, NextBarButton, ChooseLease
} from "../components";
import { ActionButton } from "../containers";
import { GET_CART } from "./details";
import { GET_CAR_DETAILS } from "./car";
import { GET_CAR_CUSTOMER, GET_CUSTOMER } from "./cars";
import { periodEndDate } from "../dates";
import LeaseDetail from "../components/lease-detail";
import RegistrationDetail from "../components/registration-detail";
import Box from "@material-ui/core/Box";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { UPDATE_CARTLEASE } from "./reserve";

const defaultTheme = createMuiTheme();
const useStyles = makeStyles((theme) => ({
  leaseDetailContainer: {
    [defaultTheme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  leaseDetailBox: {
    [defaultTheme.breakpoints.up("md")]: {
      maxWidth: 580,
      paddingRight: 34
    }
  },
  mediumPageBox: theme.mediumPageBox
}))

// how to allow for data to come over as a uri parameter?
// vin could help load the cartCar but then we need the customer sales tax and all that
// create a table with quotes and link using an id?
// for now just have the vin and go to a car page where you can create a lease again

export default function Lease() {
  // const client = useApolloClient();
  const { data, loading, error } = useQuery(GET_CUSTOMER);

  const theme = useTheme();

  const isSmallMedia = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();

  // const handleReserveClick = event => {
  //   event.preventDefault();
  //   client.writeData({ data: { cart: vin } });
  // };

  const [
    updateCartLease,
    { loading: cartLeaseLoading, error: cartLeaseError },
  ] = useMutation(UPDATE_CARTLEASE);

  const handleReserveClick = async (event) => {
    // why doesn't this seem to update?
    // try awaiting a mutation?
    const cartLeaseResponse = await updateCartLease({
      variables: {
        cartLeaseUpdates: {
          customerTaxRate: data.customerTaxRate,
          taxRateAccuracy: data.taxRateAccuracy,
          customerState: data.customerState,
          stateAccuracy: data.stateAccuracy,
          insuranceMonthly: data.insuranceMonthly,
          insuranceAccuracy: data.insuranceAccuracy,
          createdDate: new Date().toISOString(),
        },
      },
    });
    console.log(cartLeaseResponse);
    navigate("/reserve/review");
  };

  if (loading || !data) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;
  // what changed here, why does it error?  typename off somewhere?  changed?  lease fields?
  console.log(data)
  if (!data.cartLease || !data.cartCar) return <ChooseLease />;

  console.log(data.cartLease)

  return (
    <Box className={classes.mediumPageBox}>
      <PageHeading>Lease details</PageHeading>
      <Box className={classes.leaseDetailContainer}>
        <Box className={classes.leaseDetailBox}>
          <DateDetail
            pickupDate={data.customerPickupDate}
            dropoffDate={
              new Date(
                periodEndDate(
                  new Date(data.customerPickupDate),
                  data.customerLeaseMonths
                )
              )
            }
            customerLeaseMonths={data.customerLeaseMonths}
          />
          <LocationDetail
            pickupLocation={data.cartLease.pickupLocation}
            dropoffLocation={data.cartLease.dropoffLocation}
          />
          <CarDetail car={data.cartCar} yearMakeModel={data.cartLease.yearMakeModel} excessMileageDollars={data.cartLease.excessMileageDollars} customerState={data.customerState}
            stateAccuracy={data.stateAccuracy}
            taxRateAccuracy={data.taxRateAccuracy} listingType="flex-lease" />
          {/* <RegistrationDetail
        customerState={data.customerState}
        stateAccuracy={data.stateAccuracy}
        taxRateAccuracy={data.taxRateAccuracy}
      /> */}
        </Box>
        <Box>
          <LeaseDetail
            vin={data.cartCar.vin}
            customerLeaseMonths={data.customerLeaseMonths}
            customerPickupDate={data.customerPickupDate}
            insuranceMonthly={data.insuranceMonthly}
            insuranceAccuracy={data.insuranceAccuracy}
            leaseQuote={data.cartLease}
            customerState={data.customerState}
            stateAccuracy={data.stateAccuracy}
            taxRateAccuracy={data.taxRateAccuracy}
            customerTaxRate={data.customerTaxRate}
            carLocation={data.cartCar.location}
          />
          {/* <Button onClick={() => {}}>RESERVE</Button> */}
          <LeaseIncluded customerState={data.customerState} cartLease={data.cartLease} carLocation={data.cartCar.location} model={data.cartCar.model} />
          <NextBarButton
            onClick={handleReserveClick}
            buttonText="Start Reservation"
            isFixed={false}
          />
        </Box>
      </Box >
      {/* should this not be sticky unless you're in the checkout */}
    </Box>
  );
}
