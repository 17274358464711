import React, { Fragment } from "react";
import {
  useQuery,
  useApolloClient,
  useMutation,
  useLazyQuery,
} from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Loading, CarDetail, DriverReserveForm, PageHeading, ChooseLease, ImagesUploadForm, CarImage } from "../components";
import { ActionButton } from "../containers";
import { GET_CART } from "./details";
import { GET_CAR_DETAILS } from "./car";
import { GET_CAR_CUSTOMER, GET_CUSTOMER, LEASE_DATA } from "./cars";
import { periodEndDate } from "../dates";
import LeaseDetail from "../components/lease-detail";
import RegistrationDetail from "../components/registration-detail";
import LoadLeaseQuote from "../components/load-leasequote";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import LeaseHighlights from "../components/lease-highlights";
import { Grid } from "@material-ui/core";
import Login from "./login";

// loadStripe.setLoadParameters({advancedFraudSignals: false})
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const useStyles = makeStyles((theme) => ({
  imagesGrid: { marginBottom: 12 },
  reserveHeading: { marginBottom: 6 },
}))

export const GET_LEASEACCOUNT_CONDITION = gql`
  query leaseAccountCondition($skipsLeaseId: ID!) {
    account(skipsLeaseId: $skipsLeaseId) {
      conditionImages
      yearMakeModel
    }
    isLoggedIn @client
  }
`;

const PUSH_CONDITION_IMAGES = gql`
  mutation pushConditionImages($imageUris: [String!], $skipsLeaseId: String!) {
    pushConditionImages(imageUris: $imageUris, skipsLeaseId: $skipsLeaseId) {
      conditionImages
      yearMakeModel
    }
  }
`;

export default function ConditionImages({ "*": skipsLeaseId }) {
  const classes = useStyles();
  const client = useApolloClient();
  // const { data, loading, error } = useQuery(GET_LEASEACCOUNT_CONDITION, {
  //   variables: { accountId },
  // });

  const [
    getAccount,
    { loading, data }
  ] = useLazyQuery(GET_LEASEACCOUNT_CONDITION, {
    variables: { skipsLeaseId },
    fetchPolicy: "network-only"
  });

  const [pushConditionImages, { loading: pushLoading, error: pushError }] = useMutation(
    PUSH_CONDITION_IMAGES, {
    refetchQueries: [{ query: GET_LEASEACCOUNT_CONDITION }],
    awaitRefetchQueries: true,
  }
  );

  const handleSubmit = async (imageUris) => {
    const { data } = await pushConditionImages({ variables: { imageUris, skipsLeaseId } })
    console.log(data)
    if (data.pushConditionImages.__typename === "Account") {
      // assume the image was pushed if the type is correct, not getting any other fields for some reason
      // setImageUris([""])
    }
  }

  if (loading) return <Loading />;
  if (!data) return <Button onClick={event => getAccount()} disableElevation variant="contained" color="primary" >QUERY</Button>
  // if (error) return <p>ERROR: {error.message}</p>;
  if (!data.isLoggedIn) return <Login />

  console.log("data");
  console.log(data);

  return (
    <Fragment>
      <PageHeading className={classes.reserveHeading}>Condition images</PageHeading>
      <Typography>{skipsLeaseId}</Typography>
      <Typography>{data.account.yearMakeModel}</Typography>
      {data.account.conditionImages && <Grid container spacing={1} className={classes.imagesGrid}>
        {data.account.conditionImages.map(imageUri => <Grid md={4} item><CarImage url={imageUri} /></Grid>)}
      </Grid>}
      <Button onClick={event => getAccount()} disableElevation variant="contained" color="primary" >QUERY</Button>
      <ImagesUploadForm
        onSubmit={handleSubmit}
      />
    </Fragment>
  );
}
