import React, { Fragment } from "react";
import {
  useApolloClient,
  useMutation,
  useQuery,
  useLazyQuery,
} from "@apollo/react-hooks";
import gql from "graphql-tag";
import TaxJurisdictionForm from "../components/tax-jurisdiction-form";

import { Loading } from "../components";
import Box from "@material-ui/core/Box";
import { Typography, makeStyles } from "@material-ui/core";
import { UPDATE_CUSTOMER_STATE, UPDATE_CUSTOMER_COUNTY } from "../components/customer-form";
import { GET_RATE } from "../components/lease-price-table";
import { GET_CARS_LEASEQUOTE, GET_VIEW_CUSTOMERTAX_CARTLEASEVIN } from "./cars";
import { GET_CARTLEASE_CARTCAR } from "./reserve";
import { REQUERY_LEASEQUOTE } from "../components/quote-location-form";

const useStyles = makeStyles((theme) => ({
  taxStatementContainer: { display: "flex", marginTop: 36 },
  jurisdictionBox: { flex: "auto" },
  rateBox: { flex: "initial" },
  formBox: { marginTop: 16 }
}))

export const GET_CUSTOMERSTATE_TAXRATE = gql`
  query getCustomerTaxrate {
    customerState @client
    stateAccuracy @client
    customerTaxRate @client
    taxRateAccuracy @client
    customerCounty @client
  }
`;

export default function TaxJurisdiction() {
  const classes = useStyles();
  const client = useApolloClient();

  const { data, loading, error } = useQuery(GET_VIEW_CUSTOMERTAX_CARTLEASEVIN);

  // const [
  //   updateCustomerState,
  //   { loading: customerLoading, error: customerError }
  // ] = useMutation(UPDATE_CUSTOMERSTATE);


  const [
    updateCustomerState,
    { loading: customerLoading, error: customerError },
  ] = useMutation(UPDATE_CUSTOMER_STATE);

  const [
    requeryLeasequote,
    { loading: requeryLeasequoteLoading, error: requeryLeasequoteError },
  ] = useMutation(REQUERY_LEASEQUOTE, {
    onCompleted({ requeryLeasequote }) {
      // how do we get viewCars here?  otherwise need to requery for it

      console.log(requeryLeasequote)
      const cartCar = requeryLeasequote.viewCars.find(updatedViewCar => updatedViewCar.vin === requeryLeasequote.cartLease.vin)
      const data = {
        cartCar,
        cartLease: cartCar.leaseQuote
      }
      client.writeQuery({ query: GET_CARTLEASE_CARTCAR, data })
      // onCh prop functions

    },
  });

  const [
    updateCustomerStateRequery,
    { loading: customerStateRequeryLoading, error: customerStateRequeryError },
  ] = useMutation(UPDATE_CUSTOMER_STATE, {
    refetchQueries: [{ query: GET_CARS_LEASEQUOTE }],
    awaitRefetchQueries: true,
    onCompleted(updateCustomerState) {
      console.log(data.cartLease)
      if (data.cartLease.vin) {
        requeryLeasequote({
          // try getting viewCars from data (rather than passing it pointlessly through updateCustomerLeaseMonths)
          // since viewCars isn't requeried anyway
          variables: { viewCars: data.viewCars, cartLease: data.cartLease },
        });
      }
    }
  });

  const [
    updateCustomerCountyRequery,
    { loading: customerCountyRequeryLoading, error: customerCountyRequeryError },
  ] = useMutation(UPDATE_CUSTOMER_COUNTY, {
    refetchQueries: [{ query: GET_CARS_LEASEQUOTE }],
    awaitRefetchQueries: true,
    onCompleted(updateCustomerCounty) {
      console.log(data)
      if (data.cartLease.vin) {
        requeryLeasequote({
          // try getting viewCars from data (rather than passing it pointlessly through updateCustomerLeaseMonths)
          // since viewCars isn't requeried anyway
          variables: { viewCars: data.viewCars, cartLease: data.cartLease },
        });
      }
    }
  });

  const [getRate, { loading: rateLoading, data: rateData }] = useLazyQuery(
    // think this is really just for New York now
    GET_RATE,
    {
      onCompleted(data) {
        console.log(data)
        client.writeQuery({
          query: GET_CUSTOMERSTATE_TAXRATE,
          data: {
            customerTaxRate: data.rate.rate,
            taxRateAccuracy: "positive",
            customerCounty: data.rate.taxJurisdiction,
          },
        });
        // need to refresh the car's leaseQuote somewhere
        updateCustomerCountyRequery({ variables: { customerCounty: data.rate.taxJurisdiction } });
      },
    }
  );

  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;

  const handleStateChange = async (customerState) => {
    updateCustomerStateRequery({ variables: { customerState } });
    // client.writeQuery({
    //   query: GET_CUSTOMERSTATE_TAXRATE,
    //   data: { customerState, stateAccuracy: "positive" },
    // });
  };

  const handleJurisdictionChange = (customerState, taxJurisdiction) => {
    // resolver that will query the taxJurisdictions API?
    console.log(taxJurisdiction)
    const rateResponse = getRate({ variables: { countyName: taxJurisdiction } });
    console.log(rateResponse)
    // updateCustomerCountyRequery({ variables: { customerCounty: rateResponse.rate.rate.taxJurisdiction } });
  };

  // there's only a jurisdictionChange if the state has distinct tax jurisdiction (like New York) and if the user makes that selection
  // so we need to call the handler on both

  // need cartlease or return select..

  return (
    <Fragment>
      <Box className={classes.formBox}>
        <TaxJurisdictionForm
          onStateChange={handleStateChange}
          onJurisdictionChange={handleJurisdictionChange}
          initialCustomerState={data.customerState}
          initialTaxJurisdiction={data.customerCounty}
        />
      </Box>
      {data.taxRateAccuracy === "positive" && (
        <Box className={classes.taxStatementContainer}>
          <Box className={classes.jurisdictionBox}>
            <Typography>
              Sales tax in{" "}
              {data.customerCounty
                ? data.customerCounty + " County"
                : data.customerState}
            </Typography>
          </Box>
          <Box className={classes.rateBox}>
            <Typography variant="h4">
              {Number(data.customerTaxRate).toFixed(3)}%
            </Typography>
          </Box>
        </Box>
      )}
    </Fragment>
  );
}
