import React, { Fragment } from "react";
import { Link as RouterLink } from "@reach/router";
// import { Router, Link as RouterLink } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import PageHeading from "../components/page-heading";
import { Typography, Box, } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  heading: { marginBottom: 6 },
  paragraph: { marginBottom: 4 },
  lastParagraph: { marginBottom: 10 },
  spanLead: { fontStyle: "italic" },
  endNote: { fontStyle: "italic" },
  narrowPageBox: theme.narrowPageBox
}));

export default function PrivacyPolicy({ ...other }) {
  const classes = useStyles();

  return (
    <Box className={classes.narrowPageBox}>
      <PageHeading>Privacy Policy</PageHeading>
      <Typography variant="body1" className={classes.heading}>WHAT THIS PRIVACY POLICY COVERS</Typography>
      <Typography variant="body1" className={classes.heading}>Your personal information</Typography>
      <Typography variant="body1" className={classes.paragraph}>Welcome to SKIP&rsquo;S LLC (&ldquo;Skip&rsquo;s,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo;).&nbsp; This Privacy Policy describes how we treat personal information that we collect while you are visiting our website, using our products and services, and working and communicating with members of our team. Personal information is information about you that is personally identifiable and that is not otherwise publicly available.</Typography>
      <Typography variant="body1" className={classes.lastParagraph}>By visiting our website, viewing our content, contacting us, and/or using any of our products or services, you consent to this Privacy Policy.</Typography>
      <Typography variant="body1" className={classes.heading}>This policy only applies to Skip&rsquo;s</Typography>
      <Typography variant="body1" className={classes.lastParagraph}>This policy does not apply to the practices of other companies, or to people that Skip&rsquo;s does not employ.&nbsp; We have no responsibility for the practices of third parties that we provide personal information to.</Typography>
      <Typography variant="body1" className={classes.heading}>INFORMATION WE COLLECT</Typography>
      <Typography variant="body1" className={classes.lastParagraph}>We collect personal information when you visit our website, when you contact us, when you use&nbsp;our products or services, and when you communicate with members of our team. We may combine information about you that we have with information we obtain from third parties.</Typography>
      <Typography variant="body1" className={classes.heading}>Information you provide to us</Typography>
      <Typography variant="body1" className={classes.lastParagraph}>When you make contact with us we ask for information such as your name, email address, and phone number.&nbsp; When you use our products or services we may ask for information such as your mailing address, driver&rsquo;s license number, birth date, credit card and bank account information, social security number, automobile insurance policy details, and details about the car you are interested in buying or the car you are interested in selling.&nbsp; Details may include VIN, registration plate number, make, model, trim, color, year, mileage, and a brief explanation of your decision to sell or purchase a car.</Typography>
      <Typography variant="body1" className={classes.heading}>Information we collect</Typography>
      <Typography variant="body1" className={classes.paragraph}>Skip&rsquo;s automatically receives and records information from your computer or mobile device and your browser, including your&nbsp;IP address, cookie&nbsp;information, geographic location, software and hardware attributes, and page requests.</Typography>
      <Typography variant="body1" className={classes.paragraph}>Skip&rsquo;s may obtain information about you from third parties, such as social networks, data aggregators, vehicle data providers, credit bureaus, and public databases.&nbsp; This information may include your name, address, biographical information (e.g. professional experience, educational background, etc.), contacts and friends, vehicle ownership, and vehicle history reports.</Typography>
      <Typography variant="body1" className={classes.lastParagraph}>Skip&rsquo;s obtains information about the car you are interested in selling when we schedule and perform an inspection, including photos, condition, accident history, maintenance history, and details about the functional and cosmetic condition of your car&rsquo;s various components.</Typography>
      <Typography variant="body1" className={classes.heading}>How we use information</Typography>
      <Typography variant="body1" className={classes.lastParagraph}>Skip&rsquo;s may use information for the following general purposes: to fulfill your requests for products and services, contact you, conduct research, improve our products and services, and provide anonymous reporting for internal and external clients.</Typography>
      <Typography variant="body1" className={classes.heading}>INFORMATION SHARING AND DISCLOSURE TO THIRD PARTIES</Typography>
      <Typography variant="body1" className={classes.paragraph}>Skip&rsquo;s may share your personal information with third parties in order to provide products or services or to send you information about products or services we think you may be interested in.&nbsp; By visiting our website, viewing our content, contacting us, and/or using any of our products or services, you consent to disclosure of your information to third parties in the manner described below.</Typography>
      <Typography variant="body1" className={classes.paragraph}><span className={classes.spanLead}>Service Providers.</span>&nbsp; We engage third party vendors and service providers to support our products and services and our operations and we may provide personal information to these Service Providers.</Typography>
      <Typography variant="body1" className={classes.paragraph}><span className={classes.spanLead}>Web Hosts.</span>&nbsp; Our website is hosted by ServerCentral through Shopify Inc.&nbsp; ServerCentral is a data center infrastructure provider that provides hosting for Shopify Inc., our ecommerce platform.&nbsp; All information you provide to us on our website is processed and stored on servers that are owned and operated by ServerCentral and Shopify Inc.</Typography>
      <Typography variant="body1" className={classes.paragraph}><span className={classes.spanLead}>Web Analytics Providers.</span>&nbsp; Skip&rsquo;s may provide your personal information to Web Analytics Providers we engage to help us understand how visitors use our website and how we can improve our products and services.</Typography>
      <Typography variant="body1" className={classes.paragraph}><span className={classes.spanLead}>Email Hosts.</span>&nbsp; Our email is hosted by GoDaddy Inc.&nbsp; All information you provide to us through email is collected and stored on servers that are owned and operated by GoDaddy Inc.</Typography>
      <Typography variant="body1" className={classes.paragraph}><span className={classes.spanLead}>Email Marketing Providers.</span>&nbsp; Skip&rsquo;s may provide personal information, such as your name and email address, to Email Marketing Providers that help us create and deliver emails.&nbsp; This information is processed and stored on servers that are owned and operated by these Email Marketing Providers.</Typography>
      <Typography variant="body1" className={classes.paragraph}><span className={classes.spanLead}>Chat Providers.</span>&nbsp;Skip&rsquo;s uses Chat Providers to communicate with customers and those visiting our website.&nbsp; The information you provide and discuss in these chats and other information that is collected automatically and stored on servers that are owned and operated by these Chat Providers.</Typography>
      <Typography variant="body1" className={classes.paragraph}><span className={classes.spanLead}>Social Networks.</span>&nbsp; Skip&rsquo;s may provide personal information, such as your name, details about your car, and your review of our products and services, to Social Networks such as Yelp, LinkedIn, Facebook, Twitter, and Instagram.</Typography>
      <Typography variant="body1" className={classes.paragraph}><span className={classes.spanLead}>Vehicle History Providers.</span>&nbsp; Skip&rsquo;s may provide personal information to Vehicle History Providers such as Carfax, Inc. and AutoCheck in order to obtain information about the car you are interested in selling.</Typography>
      <Typography variant="body1" className={classes.paragraph}><span className={classes.spanLead}>Auto Classifieds Sites.</span>&nbsp; We advertise on Auto Classified Sites such as CarGurus and Craigslist and may post information such as your name, general location and details about your car to these websites when you sell a car through Skip&rsquo;s.</Typography>
      <Typography variant="body1" className={classes.paragraph}><span className={classes.spanLead}>Buyer or Seller.</span>&nbsp; The buyer or seller whom you transact with through Skip&rsquo;s may also have access to your personal information when making a sale or purchase or when handling documents related to the sale or purchase.</Typography>
      <Typography variant="body1" className={classes.paragraph}><span className={classes.spanLead}>Motor Vehicle Agencies.&nbsp;&nbsp;</span>We provide your personal information to motor vehicle authorities (such as the New York Department of Motor Vehicles, Connecticut Department of Motor Vehicles, or the New Jersey Motor Vehicle Commission) in order to facilitate registration and title applications in connection with the sale or purchase of a car.&nbsp; We are required to produce records of this information to these authorities on request.</Typography>
      <Typography variant="body1" className={classes.paragraph}><span className={classes.spanLead}>Payment Processors and Financial Institutions.&nbsp;&nbsp;</span>Skip&rsquo;s may transfer certain personal information to Payment Processors and Financial Institutions in order to facilitate a sale or purchase of our products or services.&nbsp; This information may include your name, address, credit card information, and bank account information.</Typography>
      <Typography variant="body1" className={classes.paragraph}><span className={classes.spanLead}>Financing Partners and Financing Platforms.</span>&nbsp; Skip&rsquo;s may provide your personal information such as your name, address, credit score, employment history, and automobile insurance policy details to Financing Partners in order to apply for and facilitate your auto loan.&nbsp; We may provide your personal information to a Financing Platform in order to connect with these Partners.</Typography>
      <Typography variant="body1" className={classes.paragraph}><span className={classes.spanLead}>Credit Bureaus.</span>&nbsp; Skip&rsquo;s may provide personal information to Credit Bureaus such as TransUnion, Experian, and Equifax in order to obtain a credit report, credit score, and other information.</Typography>
      <Typography variant="body1" className={classes.paragraph}><span className={classes.spanLead}>Warranty Providers.&nbsp;&nbsp;</span>Skip&rsquo;s may provide your personal information to third party Warranty Providers in order to supply or offer you a warranty or vehicle service contract.&nbsp; These providers may share your personal information with underwriters and administrators in order to insure and support your policy.</Typography>
      <Typography variant="body1" className={classes.paragraph}><span className={classes.spanLead}>Shipping Providers.</span>&nbsp; We use Shipping Providers such as USPS and FedEx and automotive transport providers to deliver certain products, services, and information to you.&nbsp; Skip&rsquo;s may provide your personal information, including your name and address, to such providers when you use our products or services or to send you information about products or services we think you may be interested in.</Typography>
      <Typography variant="body1" className={classes.paragraph}><span className={classes.spanLead}>Vehicle Service Providers.</span>&nbsp; Skip&rsquo;s may engage Vehicle Service Providers to perform maintenance, repairs, and inspections on cars we buy and sell.&nbsp; We may provide your personal information to these Vehicle Service Providers when they perform these services.</Typography>
      <Typography variant="body1" className={classes.paragraph}><span className={classes.spanLead}>Accounting Software Providers.</span>&nbsp; We use Accounting Software Providers such as Intuit QuickBooks and may provide personal information to these providers in order to keep accurate financial records.&nbsp; This information is processed and stored on servers that are owned and operated by these Accounting Software Providers.</Typography>
      <Typography variant="body1" className={classes.paragraph}><span className={classes.spanLead}>Legal Process.</span>&nbsp; We may be required to respond to subpoenas, court orders, or legal process (such as&nbsp;law enforcement requests), or to establish or exercise our legal rights or defend against legal claims.</Typography>
      <Typography variant="body1" className={classes.paragraph}><span className={classes.spanLead}>Law Enforcement Offices and Agencies.</span>&nbsp; We record information concerning each car we buy or sell, including a description, the registration plate number, the odometer reading, inspection sticker number, and personal information about the parties that we buy and sell from, including name, address, and automobile insurance policy details.&nbsp; We are required to produce records of this information to law enforcement offices and agencies on request.</Typography>
      <Typography variant="body1" className={classes.lastParagraph}><em>Fraud, Threats, Illegal Activities, and Violations.&nbsp;&nbsp;</em>We may share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, potential threats to the physical safety of any person, violations of our terms of use, or as otherwise required by law.</Typography>
      <Typography variant="body1" className={classes.lastPeading}>COOKIES AND OTHER INFORMATION</Typography>
      <Typography variant="body1" className={classes.heading}>Cookies</Typography>
      <Typography variant="body1" className={classes.paragraph}>Skip&rsquo;s may set and access first-party or third-party cookies&nbsp;on your computer.&nbsp; Cookies record anonymous information about you when you visit our website that can help us research and analyze usage trends and patterns so we may improve our products and services.&nbsp; We may&nbsp;enable&nbsp;third parties to collect&nbsp;information&nbsp;about you using Cookies.</Typography>
      <Typography variant="body1" className={classes.lastParagraph}>If you prefer not to allow Skip&rsquo;s to place cookies on your computer, you should set your browser preferences to block cookies before you visit our website.</Typography>
      <Typography variant="body1" className={classes.heading}>How to modify your personal information</Typography>
      <Typography variant="body1" className={classes.lastParagraph}>If you would like to modify or update your personal information, please email us at hi@driveskips.com&nbsp;or contact us at&nbsp;(347) 754 7794.</Typography>
      <Typography variant="body1" className={classes.heading}>How to unsubscribe</Typography>
      <Typography variant="body1" className={classes.paragraph}>You can remove yourself from our email lists at any time by responding with &ldquo;unsubscribe&rdquo; in the subject line or by asking any member of our team.</Typography>
      <Typography variant="body1" className={classes.paragraph}>You can&nbsp;opt-out of&nbsp;text messages at any time by responding with "STOP" or by asking any member of our team.</Typography>
      <Typography variant="body1" className={classes.lastParagraph}>We reserve the right to send you certain communications we believe are necessary, such as important announcements and administrative messages without offering you the opportunity to opt out of receiving them.</Typography>
      <Typography variant="body1" className={classes.heading}>Children&rsquo;s privacy</Typography>
      <Typography variant="body1" className={classes.lastParagraph}>Skip&rsquo;s does not knowingly collect information from children under 13 years of age.</Typography>
      <Typography variant="body1" className={classes.heading}>CHANGES TO THIS PRIVACY POLICY</Typography>
      <Typography variant="body1" className={classes.lastParagraph}>Skip&rsquo;s may update this policy. We will notify you about significant changes in the way we treat personal information by sending a notice to your email address or by placing a prominent notice on our website.</Typography>
      <Typography variant="body1" className={classes.heading}>QUESTION AND SUGGESTIONS</Typography>
      <Typography variant="body1" className={classes.paragraph}>If you have questions, suggestions, or wish to make a complaint, please email us at hi@driveskips.com or contact us at&nbsp;(347) 754 7794.</Typography>
      <Typography variant="body1" className={classes.endNote}>Last Updated: August 15, 2019</Typography>
    </Box>
  );
}
