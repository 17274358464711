import React, { Fragment } from "react";
import { useApolloClient, useMutation, useQuery } from "@apollo/react-hooks";
import { pure } from 'recompose'
import {
  GET_SORT_CARS,
  GET_FILTERS_VIEW
} from "../pages/cars";
import {
  SORT_CARS,
  ADD_OR_REMOVE_FILTERS_CARS,
  CLEAR_FILTERS_CARS,
  SORT_CARS_WRITE_FILTERS,
  FILTER_IS_IN_STOCK_CARS,
} from "../components/cars-forms";
// import { typeDefs } from "../resolvers";

import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Slider from "@material-ui/core/Slider";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import Collapse from "@material-ui/core/Collapse";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import blue from "@material-ui/core/colors/blue";
import ClearAllIcon from '@material-ui/icons/ClearAll';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import {
  filterAttributesNames,
  filterAttributes,
  numericalAttributesNames,
  MAXTOTAL,
  MAXPRICE,
  MAXMILEAGE,
  sizes,
  booleanAttributesNames
} from "../constants";
import { FormControl, FormControlLabel, AppBar, Tabs, Tab, useMediaQuery } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { priceFormat, numberFormat, ksFormat } from "../number-formats";

const useStyles = makeStyles((theme) => ({
  chipScrollbox: {
    overflowX: "auto",
    whiteSpace: "nowrap",
    "&::-webkit-scrollbar": { display: "none" },
    // prevents margin collapse but seems to block scrolling
    // display: "inline-block",
    marginTop: 5,
  },
  filtersetScrollbox: {
    overflowY: "auto",
    width: "100%"
  },
  formControlScrollbox: {
    [theme.breakpoints.only("xs")]: {
      height: "unset !important",
      // allow for an additional 43px for height normally filled by now-absolute FiltersetButtons
      maxHeight: 223,
      width: "100%",
      overflowY: "auto"
    }
  },
  buttonBoxOverlay: {
    position: "absolute",
    bottom: 0,
    right: 0,
    marginBottom: 14,
    [theme.breakpoints.up("md")]: {
      marginRight: `calc(${theme.spacing(1) * 3}px + 20px)`,
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1) * 1.5,

    }
  },
  overlayButton: {
    background: "#fff"
  },
  deleteChip: {
    marginRight: 6,
    marginBottom: 3,
    color: theme.palette.text.primary,
  },
  deleteChipsBox: {
    marginBottom: 12
  },
  // filteredChip first so the color is overwritten by selectedChip
  filteredChip: {
    backgroundColor: blue[100],
    "&:hover": {
      backgroundColor: blue[200],
    },
  },
  filteredCount: {
    color: blue[200]
  },
  selectedChip: {
    color: theme.palette.primary.light,
    fontWeight: 500,
  },
  scrollboxContainer: {
    // prevents inline-block scrollbox from creating a huge width
    overflow: "hidden",
  },
  filtersetButtonBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  collapseButton: {
    marginLeft: 8,
    [theme.breakpoints.up("sm")]: {
      display: "none"
    },
  },
  // collapse: { paddingLeft: theme.switchbankRow.paddingLeft },
  subFormGroup: { paddingLeft: 14, marginBottom: 8 },
  // move these right so that background can exist with normal padding around checkbox
  headingControlLabel: {
    width: "100%",
    background: theme.backgroundGrey,
    marginLeft: 0,
    marginRight: 0,

  },
  headedFormLabel: { marginBottom: 9 },
  subControlLabel: { marginLeft: 12 },
  // three times the height of the InputContainer div
  checkboxesFormGroup: {
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row"
    },
    [theme.breakpoints.down("sm")]: {
      height: "calc(100% - 16px)",
    }
  },
  checkboxesFormGroupScrollBox: {
    // scroll the FormGroup on .up, this class is applied on the makeModel filterset
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      height: "unset !important",
      maxHeight: 223,
      width: "100%",
      overflowY: "auto"
    },
    [theme.breakpoints.down("sm")]: {
      height: "calc(100% - 16px)",
    }

  },
  // 21px puts the active shadow at the boundary
  sliderBox: { padding: "4px 6px" },
  countBox: {
    position: "absolute",
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
    width: 48,
    // mirror switchbankBox
    [theme.breakpoints.down("sm")]: {
      left: 0
    },
  },
  overlayBox: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    // believe width 100 was creating a page wider than screen
    // width: "100%",
    // zIndex needs to be greater than the curtainBox
    zIndex: 4,
    background: "#fff",
    touchAction: "none"
  },
  curtainBox: {
    position: "fixed",
    // zIndex needs to be greater than the sort IconButton
    zIndex: 3,
    background: theme.curtainBackground,
    left: 0,
    right: 0,
    top: 0,
    height: "100vh",
    touchAction: "none"
  },
  collapse: {
    overflow: "hidden",
  },
  filterFormContainer: {
    [theme.breakpoints.only("xs")]: {
      height: 180
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: 20
    },
  },
  collapseWrapperInner: {
    margin: `14px ${theme.spacing(1) * 1.5}px`
  },
  filterChip: {
    margin: "3px 4px 3px",
    maxWidth: "calc(100 % - 6px)"
  },
  labelSubtext: { color: theme.palette.text.secondary },
  formControlTight: { marginBottom: -4 },
  discountSwitch: {
    "&.Mui-checked": {
      color: green[600],
      "&:hover": {
        backgroundColor: "rgba(67, 160, 71, 0.04)"
        // green[600] with alpha
      },
      "& + .MuiSwitch-track": {
        backgroundColor: green[600],
      }
    }
  },

  formElement: theme.formElement,
  switchbankRow: theme.switchbankRow,
  switchbankIconMenu: theme.switchbankIconMenu,
  switchbankBox: theme.switchbankBox,
  none: theme.none
}));

// controlled or uncontrolled, this still isn't responding quickly
const FilterCheckbox = ({ checked, onChange, value, name, ...props }) => {

  const handleChange = () => onChange(name, value)
  return <Checkbox className={"filterCheckbox"} onChange={handleChange} checked={checked} {...props} />

};

// try recompose, not sure this helps - actually causes more renders but I think they're faster, don't include the FilterCheckboxes
const PureFilterCheckbox = pure(FilterCheckbox)

function FiltersetButtons({ onClear, onClose, isFiltered, isClearAll = true, isOverlay = false }) {
  const classes = useStyles();

  return <Box className={!isOverlay ? classes.filtersetButtonBox : classes.buttonBoxOverlay}>
    <Button
      size="small"
      onClick={onClear}
      className={isFiltered ? (isOverlay ? classes.overlayButton + " " + classes.collapseButton : classes.collapseButton) : classes.none}
    >
      {isClearAll ? "Clear all" : "Clear"}
    </Button>
    <Button
      size="small"
      variant="outlined"
      onClick={onClose}
      className={!isOverlay ? classes.collapseButton : (classes.collapseButton + " " + classes.overlayButton)}
    >
      Done
    </Button>
  </Box>
}


function AttributeFilterset({
  // cars,
  valueSet,
  attribute,
  attributeName,
  propValues,
  initialFilterValues = [],
  onChange,
  onClear,
  onClose,
  isMakeSet = false,
  // checked is a curried function
}) {
  // may need to rethink this structure - whenever the state changes all the handlers have to reload
  // because they depend on what's in state
  // BUT there's no way to check if the input is checked without knowing state
  // therefore a few sub filtersets might be the answer
  // when one checkbox is checked in a make, the other filtersets don't need to rerender
  // maybe this is getting set too soon and needs to be another hook
  // doesn't look like it
  const [filterValues, setFilterValues] = React.useState(initialFilterValues);

  const classes = useStyles();

  React.useEffect(() => {
    if (!!propValues) {
      setFilterValues(propValues)
    }
  }, [propValues]);

  // create a makeSet
  // consider useRef here
  const makeSet = Object.keys(valueSet["makeModel"]).reduce((accumulator, makeModelKey) => {
    const make = makeModelKey.split(":")[0];
    const makeModelMinDaily = valueSet["makeModel"][makeModelKey].minDaily
    accumulator[make] = !accumulator[make] ? { count: valueSet["makeModel"][makeModelKey].count, minDaily: makeModelMinDaily, makeModels: [makeModelKey] } : { count: accumulator[make].count + 1, minDaily: makeModelMinDaily < accumulator[make].minDaily ? makeModelMinDaily : accumulator[make].minDaily, makeModels: [...accumulator[make].makeModels, makeModelKey] }
    return accumulator
  }, {})

  const handleChange = (attribute, value) => {
    const isChecked = !filterValues.includes(value)
    setFilterValues(isChecked ? [...filterValues, value] : filterValues.filter(filterValue => filterValue !== value))
    // synthesize an event for onChange prop (for now)
    onChange(isMakeSet ? "makeModel" : attribute, value, isChecked)
  }

  const handleMakeChange = (attribute, make) => {
    const makeModels = makeSet[make].makeModels
    const isChecked = !makeModels.every(makeModel => filterValues.includes(makeModel));
    if (isChecked) {
      setFilterValues([...new Set([...filterValues, ...makeModels])])
    } else {
      setFilterValues(filterValues.filter(filterValue => !makeModels.includes(filterValue)))
    }
    // attribute, value, isChecked
    onChange("makeModel", makeModels, isChecked)
  }

  const composeLabel = (value, minDaily, count) => <Fragment>{value} <span className={classes.labelSubtext}>{`$${minDaily}${count > 1 ? "+" : ""} (${count})`}</span></Fragment>

  if (!Object.keys(valueSet).length || !valueSet) return null


  return (
    <Fragment>
      <FormControl className={attribute !== "makeModel" ? classes.filterFormContainer : (classes.filterFormContainer + " " + classes.formControlScrollbox)}>
        <FormLabel className={attribute === "makeModel" ? classes.headedFormLabel : undefined} focused={false}>{attributeName}</FormLabel>
        {/* column is throwing an error but it's read as a prop on the root (Grid) element */}
        <Box>
          <FormGroup column={attribute !== "makeModel"} classes={attribute !== "makeModel" ? { root: classes.checkboxesFormGroup } : { root: classes.checkboxesFormGroupScrollBox }}>
            {attribute === "makeModel" ?
              // makeModel checkboxes
              Object.keys(makeSet).sort((a, b) => a.localeCompare(b)).map(makeKey => {
                return <Fragment>
                  <FormControlLabel className={classes.formControlTight + " " + classes.headingControlLabel} control={
                    <PureFilterCheckbox name="make" value={makeKey} checked={makeSet[makeKey].makeModels.every(makeModel => filterValues.includes(makeModel))} onChange={handleMakeChange} />
                  }
                    // not sure why we need to replace ":" in make string
                    label={composeLabel(makeKey.replace(":", " "), makeSet[makeKey].minDaily, makeSet[makeKey].count)} key={makeKey} />
                  {
                    makeSet[makeKey].makeModels.map(makeModel =>
                      <FormControlLabel
                        className={classes.subControlLabel}
                        control={
                          <PureFilterCheckbox name="makeModel" value={makeModel} checked={filterValues.includes(makeModel)} onChange={handleChange} />
                        }
                        label={composeLabel(makeModel.split(":").pop(), valueSet.makeModel[makeModel].minDaily, valueSet.makeModel[makeModel].count)} key={makeModel}
                      />
                    )
                  }
                </Fragment>
              })
              :
              // regular attribute checkboxes
              (attribute === "size" ? Object.keys(valueSet.size).sort((a, b) => sizes.indexOf(a) - sizes.indexOf(b)) : Object.keys(valueSet[attribute]).sort((a, b) => valueSet[attribute][a].minDaily - valueSet[attribute][b].minDaily)).map((valueKey) => {
                return (
                  <FormControlLabel control={<PureFilterCheckbox
                    name={attribute}
                    value={valueKey}
                    // shouldn't need this here except for initialValues
                    checked={filterValues.includes(valueKey)}
                    onChange={handleChange}
                  />}
                    label={composeLabel(valueKey, valueSet[attribute][valueKey].minDaily, valueSet[attribute][valueKey].count)}
                    // label={valueKey}
                    key={valueKey}
                  />
                );
              })
            }
          </FormGroup>
        </Box>
      </FormControl>
      <FiltersetButtons onClear={
        () => {
          setFilterValues([]);
          onClear(attribute);
        }
      } isFiltered={filterValues.length !== 0} onClose={onClose} isOverlay={attribute === "makeModel"} />
    </Fragment>
  );
}

function NumericalAttributeFilter({
  attribute,
  attributeName,
  propValue,
  initialValue = MAXMILEAGE,
  onChange,
  onClear,
  onClose,
  ...other
}) {
  // yes, would make more sense for each Filer component to have its own state, simpler than a whole filter state object with ten attributes.  and maybe even quicker responding.
  // this is preventing the 
  const [value, setValue] = React.useState(initialValue);

  let maxValue = ((attribute) => {
    switch (attribute) {
      case "retailPrice":
        return MAXPRICE;
      case "mileage":
        return MAXMILEAGE;
      default:
        return MAXTOTAL;
    }
  })(attribute);

  const classes = useStyles();

  React.useEffect(() => {
    setValue(propValue)
  }, [propValue]);

  return (
    <Fragment>
      <FormGroup className={classes.filterFormContainer} {...other}>
        <FormLabel focused={false}>{attributeName}</FormLabel>
        <Box className={classes.sliderBox}>
          <Slider
            min={0}
            max={maxValue}
            value={value}
            defaultValue={maxValue}
            step={attribute === "mileage" ? 5000 : 100}
            aria-label={attributeName}
            name={attributeName}
            valueLabelFormat={attribute === "mileage" ? (value) => `${value / 1000}k` : priceFormat}
            valueLabelDisplay="auto"
            onChange={(event, newValue) => setValue(newValue)}
            onChangeCommitted={(event, value) => onChange(attribute, value)}
            {...other}
          />
        </Box>
        <FormLabel focused={false}>{attribute === "mileage" ? `${numberFormat(value)} miles` : priceFormat(value)}</FormLabel>
      </FormGroup>
      <FiltersetButtons onClear={
        () => {
          setValue(maxValue);
          onClear(attribute);
        }
      } onClose={onClose} isFiltered={value !== maxValue} isClearAll={false} />
    </Fragment>
  );
}

function BooleanAttributeFilter({
  attribute,
  attributeName,
  attributeHeading,
  propValue,
  initialValue,
  onChange,
  onClose }) {
  // consider making controlled
  // yes, would make more sense for each Filer component to have its own state, simpler than a whole filter state object with ten attributes.  and maybe even quicker responding.
  // this is preventing the 
  const [value, setValue] = React.useState(initialValue);

  const classes = useStyles();

  // do we need this?
  React.useEffect(() => {
    setValue(propValue)
    console.log("propValue", propValue)
  }, [propValue]);

  const handleChange = () => {
    // const value = event.target.value
    // console.log(event)
    console.log(value)
    setValue(!value);
    onChange(attribute, !value);
  }

  return (
    <Fragment>
      <FormGroup column={true} classes={{ root: classes.switchesFormGroup }}>
        <FormLabel className={classes.headedFormLabel} focused={false}>{attributeHeading}</FormLabel>
        {/* column is throwing an error but it's read as a prop on the root (Grid) element */}
        <FormControlLabel control={
          <Switch checked={value} classes={{ colorSecondary: classes.discountSwitch }} onChange={handleChange} />
        }
          // not sure why we need to replace ":" in make string
          label={attributeName} />
      </FormGroup>
      <FiltersetButtons onClear={
        () => {
          setValue(false);
          handleChange();
        }
      } onClose={onClose} isFiltered={value} isClearAll={false} />
    </Fragment>
  );
}

export default function QuoteFilterForms({ sortAttribute, isSortReverse, onFilteredChange, isInStockDiscount = false, initialViewField = null }) {
  const [valueSet, setValueSet] = React.useState({});
  const [stateCars, setStateCars] = React.useState([]);
  const [stateViewField, setStateViewField] = React.useState(initialViewField);

  const isShouldAddInStockFilter = React.useRef(isInStockDiscount)

  const client = useApolloClient();

  const classes = useStyles();

  const theme = useTheme();
  const isSmallMedia = useMediaQuery(theme.breakpoints.up("sm"))

  const quoteAttributesNames = [...filterAttributesNames, ...numericalAttributesNames.filter(attributeObject => attributeObject.attribute !== "retailPrice"), ...booleanAttributesNames]

  // this didn't seem to help, why not - because we didn't create a static valueSet to go along with it
  // let staticCars = []

  // find tighter query
  const { data, loading, error } = useQuery(GET_FILTERS_VIEW);

  // will moving this handler up here prevent AttributeFilterset from rerendering?
  // async handler is still really slow, consider reworking the filter resolver
  // had to move this down below useQuery again because we need customerPickupDate
  const handleFilterChange = (
    attribute, value, isChecked
  ) => {
    // simpler function based on handleFilterChangeCurry, did not seem to be using chips at all
    const isMake = attribute === "make"
    // could be a string or an array if user checked a make
    const values = Array.isArray(value) ? value : [value]
    // await query for cars
    // we're querying too many fields on cars here
    // try with cars state and just hard code the other stuff
    // better this way, need to put sortState in the parent component
    // actually I think we need this call, otherwise the cars here are missing leaseTotal
    // maybe only use this call conditionally, when that's being filtered
    // didn't solve anything
    // when the handler changes viewCars, data changes and the whole quote-filter-form rerenders, could streamline the resolvers so we're doing less round trips
    // or use state in place of all the other stuff that depends on data
    // seeing rerender because "Hooks changed", but which hooks?  any useState functions being called?
    // OR just get the leaseTotal, why doesn't it load here
    // if (attribute == "leaseTotal") {
    //   // use data instead? cleaner
    //   const { cars } = await client.readQuery({
    //     query: GET_SORT_CARS,
    //   });
    //   handlerCars = cars
    // } else { handlerCars = stateCars }

    const makeModels = Object.keys(valueSet.makeModel)
    let modelValues
    if (isMake) {
      // addOrRemoveMake({
      //   variables: {
      //     value: values[0],
      //     isChecked
      //   }
      // });
      // no longer using makeModels
      modelValues = makeModels.filter(
        (makeModel) => makeModel.substr(0, makeModel.indexOf(":")) === values[0]
      );
    }
    // more efficient mutation/call that doesn't require cars here - cars doesn't change when we apply a filter but I'm sure some mutation is changing it here
    addOrRemoveFiltersCarsSort({
      variables: {
        // because we have addOrRemoveMake adding the make to filters["make"], and we have the addOrRemoveFilterCarsSort adding all the modelValues, that's also checking for all models in a make being checked
        attribute: !isMake ? attribute : "makeModel",
        values: !isMake ? values : modelValues,
        isChecked,
        cars: stateCars,
        makeModels,
        pickupDaysOut: differenceInCalendarDays(new Date(data.customerPickupDate), new Date())
      },
      context: { sortAttribute, isSortReverse, makeModels },
    });
  };


  // useMutation hooks need to be called before return
  const [] = useMutation(
    SORT_CARS
  );

  const [sortCarsWriteFilters] = useMutation(
    SORT_CARS_WRITE_FILTERS
  );

  const [
    addOrRemoveFiltersCarsSort,
  ] = useMutation(ADD_OR_REMOVE_FILTERS_CARS, {
    onCompleted({ addOrRemoveFiltersCars }) {
      // client.writeData({
      //   data: { filters: addOrRemoveFiltersCars.filters, isFiltered: true },
      // });
      // instead of writing to the client, call the sortCars mutate function and let that mutation take care of writing to the client
      // sortCars({
      //   variables: {
      //     cars: addOrRemoveFiltersCars.viewCars,
      //     sortAttribute: addOrRemoveFiltersCars.sortAttribute,
      //     isSortReverse: addOrRemoveFiltersCars.isSortReverse,
      //   },
      // });
      sortCarsWriteFilters({
        variables: {
          filters: addOrRemoveFiltersCars.filters,
          isFiltersClear: addOrRemoveFiltersCars.isFiltersClear,
          cars: addOrRemoveFiltersCars.viewCars,
          sortAttribute: addOrRemoveFiltersCars.sortAttribute,
          isSortReverse: addOrRemoveFiltersCars.isSortReverse,
        },
      });
    },
  });

  const [
    clearFiltersCarsSort,
  ] = useMutation(CLEAR_FILTERS_CARS, {
    onCompleted({ clearFiltersCars }) {
      // client.writeData({
      //   data: { filters: clearFiltersCars.filters, isFiltered: true },
      // });
      // instead of writing to the client, call the sortCars mutate function and let that mutation take care of writing to the client
      sortCarsWriteFilters({
        variables: {
          filters: clearFiltersCars.filters,
          isFiltersClear: clearFiltersCars.isFiltersClear,
          cars: clearFiltersCars.viewCars,
          sortAttribute: clearFiltersCars.sortAttribute,
          isSortReverse: clearFiltersCars.isSortReverse,
        },
      });
    },
  });

  const [
    filterIsInStockCarsSort,
  ] = useMutation(FILTER_IS_IN_STOCK_CARS, {
    onCompleted({ filterIsInStockCars }) {

      sortCarsWriteFilters({
        variables: {
          filters: filterIsInStockCars.filters,
          isFiltersClear: filterIsInStockCars.isFiltersClear,
          cars: filterIsInStockCars.viewCars,
          sortAttribute: filterIsInStockCars.sortAttribute,
          isSortReverse: filterIsInStockCars.isSortReverse,
        },
      });
    },
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  // may be better off using useRef for valueSet instead
  if (!Object.keys(valueSet).length) {
    // unasigned valueSet
    let newValueSet = {};
    [...filterAttributes, "make"].map(attribute => {
      const attributeValueSet = data.cars.reduce((accumulator, car) => {
        const value = car[attribute]
        // e.g. "AWD"
        const leaseTotalDaily = Math.round(car.leaseQuote.leaseTotal / car.leaseQuote.customerLeaseMonths / 30)
        accumulator[value] = !accumulator[value] ? { count: 1, minDaily: leaseTotalDaily } : { count: accumulator[value]["count"] + 1, minDaily: leaseTotalDaily < accumulator[value].minDaily ? leaseTotalDaily : accumulator[value].minDaily }
        return accumulator
      }, {});

      if (attribute === "drivetrain") {
        console.log(attributeValueSet);
        console.log(!!attributeValueSet["AWD"]);

        attributeValueSet["4WD/AWD"] = { count: (attributeValueSet["4WD"] || { count: 0 }).count + (attributeValueSet["AWD"] || { count: 0 }).count, minDaily: Math.min((attributeValueSet["4WD"] || { minDaily: 999 }).minDaily, (attributeValueSet["AWD"] || { minDaily: 999 }).minDaily) }
        // ..["AWD"].count could be undefined
        delete attributeValueSet["4WD"]
        delete attributeValueSet["AWD"]
      }

      newValueSet[attribute] = attributeValueSet

    })

    // define an object for each make
    Object.keys(newValueSet.make).map(make => {
      newValueSet[make] = Object.keys(newValueSet.makeModel).filter(makeModelKey => makeModelKey.split(":")[0] === make).reduce((accumulator, makeModelKey) => {
        accumulator[makeModelKey] = newValueSet.makeModel[makeModelKey];
        return accumulator;
      }, {});
    })

    setValueSet(newValueSet)
  }

  // setting this only once per render is ok for now because none of these filters intersect with e.g. pickup location or leaseTotal for now
  if (stateCars.length === 0) {
    console.log("setStateCars")
    setStateCars(data.cars)
  }

  if (!Object.keys(valueSet).length) return "Loading...";

  console.log(isShouldAddInStockFilter)
  console.log(isInStockDiscount)

  if (isShouldAddInStockFilter.current) {
    // the isInStockFilter prop is set and we haven't added the filter yet
    console.log("filterIsInStockCarsSort")
    filterIsInStockCarsSort({
      variables: { cars: stateCars, pickupDaysOut: differenceInCalendarDays(new Date(data.customerPickupDate), new Date()) },
      context: { sortAttribute, isSortReverse }
    });

    isShouldAddInStockFilter.current = false;
  }

  const handleNumericalChange = (attribute, value) => {
    const values = [value]
    const makeModels = Object.keys(valueSet.makeModel)
    addOrRemoveFiltersCarsSort({
      variables: { attribute, values, isChecked: true, cars: stateCars, makeModels, pickupDaysOut: differenceInCalendarDays(new Date(data.customerPickupDate), new Date()) },
      context: { sortAttribute, isSortReverse, makeModels },
    });
  }

  // if the new use of filtersetButtons doesn't make use of the curried form, revert
  // e.g. onClick={event => onClick()}

  const handleClear = async (attribute) => {
    const makeModels = Object.keys(valueSet.makeModel)
    // await query for cars
    const { cars, sortAttribute, isSortReverse, customerPickupDate } = await client.readQuery({
      query: GET_SORT_CARS,
    });
    // switch to sort/write here
    clearFiltersCarsSort({
      variables: { attribute, cars, makeModels, pickupDaysOut: differenceInCalendarDays(new Date(customerPickupDate), new Date()) },
      context: { sortAttribute, isSortReverse, makeModels },
    });
  };

  const handleAttributeClick = (attribute) => {
    window.scrollTo(0, 0);
    setStateViewField(attribute)
    // trying without, does this reduce renders?
    // no perceievable difference
    // if we're sticking with not writing viewField to data, consider renaming stateViewField to filterView or something
    // client.writeData({ data: { viewField: attribute } });
  };

  const handleClose = () => {
    setStateViewField(null)
    // trying without, does this reduce renders?
    // client.writeData({ data: { viewField: null } })
  }

  const DeleteChips = ({ filters }) => {
    // // const attributeValues = [].concat(...Object.keys(filters).filter(filterKey => filterAttributes.includes(filterKey)).map(filterKey => filters.filterKey))
    // console.log(filters)
    // const attributeValues = filterAttributes.map(attribute => {
    //   console.log(attribute)
    //   console.log(filters[attribute])
    //   return {[attribute]: filters[attribute]
    // }).flat()
    // console.log(attributeValues)
    // Object.keys(filters).filter(filterKey => filterAttributes.includes(filterKey)).map(filterKey => )
    return <Box className={classes.deleteChipsBox}>
      {filterAttributes.map(attribute => filters[attribute].map(value => <Chip size="small" className={classes.deleteChip} label={attribute === "makeModel" ? value.replace(":", " ") : value} onDelete={() => handleFilterChange(attribute, value, false)} />))}
      {numericalAttributesNames.map(attributeObject => {
        const value = filters[attributeObject.attribute]
        return value === attributeObject.defaultValue ? null : <Chip size="small" className={classes.deleteChip} label={`<${(attributeObject.isPrice ? priceFormat(value) : ksFormat(value))} ${attributeObject.labelSuffix}`} onDelete={() => handleNumericalChange(attributeObject.attribute, attributeObject.defaultValue)} />
      })}
      {booleanAttributesNames.filter(attributeObject => filters[attributeObject.attribute]).map(attributeObject => <Chip size="small" className={classes.deleteChip} label={attributeObject.heading} onDelete={() => handleFilterChange(attributeObject.attribute, false)} />)}
    </Box>

  }

  return (
    <Fragment>
      {/* mobile */}
      {/* {stateViewField === null ? */}
      {/* // /* filter chips */}
      {!isSmallMedia && <Box className={stateViewField === null ? classes.switchbankRow : classes.none}>
        {/* <FilterListIcon /> */}
        <Box className={classes.countBox}>
          <Typography variant="body2" className={[...numericalAttributesNames, ...filterAttributesNames].every(attributeObject => (attributeObject.defaultValue && data.filters[attributeObject.attribute] === attributeObject.defaultValue) || data.filters[attributeObject.attribute].length === 0) ? undefined : classes.filteredCount}>{`(${data.viewCars.length})`}</Typography>
        </Box>
        <Box
          // invisible scrollbar still adding height to this div?
          className={`${classes.switchbankBox} ${classes.scrollboxContainer}`}
        >

          <Box className={classes.chipScrollbox}>
            {quoteAttributesNames.map(
              (attributeObject, index) => {
                // may not need this with new tab setup
                const isViewField = stateViewField === attributeObject.attribute
                const isFiltered = !(
                  data.filters[attributeObject.attribute].length === 0 ||
                  data.filters[attributeObject.attribute] === attributeObject["defaultValue"]
                )
                // const isFiltered = false
                return <Chip
                  clickable
                  onClick={isViewField ? handleClose : () => handleAttributeClick(attributeObject.attribute)}
                  onDelete={isFiltered ? () => handleClear(attributeObject.attribute) : undefined}
                  label={attributeObject.heading || attributeObject.name}
                  className={`${classes.filterChip} ${isFiltered && classes.filteredChip
                    } ${isViewField &&
                    classes.selectedChip
                    }`}
                  deleteIcon={<ClearAllIcon />}
                  key={index}
                // consider a custom delete icon that's a filter with a strikethrough
                />
              }
            )}
          </Box>
        </Box>
      </Box>}
      {!isSmallMedia && <Box className={stateViewField === null ? classes.none : classes.overlayBox}>
        <AppBar position="static" color="default" elevation={0}>
          <Tabs value={stateViewField || "makeModel"} onChange={(event, value) => handleAttributeClick(value)} indicatorColor="primary" variant="scrollable" scrollButtons="auto">
            {quoteAttributesNames.map((attributeObject, index) =>
              <Tab label={attributeObject.heading || attributeObject.name} value={attributeObject.attribute} key={index} />
            )}
          </Tabs>
        </AppBar>
        {/* in={!!data.viewField} */}
        <Collapse in={true} classes={{ container: classes.collapse, wrapperInner: classes.collapseWrapperInner }}>
          <DeleteChips filters={data.filters} />
          {/* // className={filterAttributes.includes(data.viewField) ? classes.formControlContainer : classes.none} */}
          {filterAttributesNames.map(attributeObject =>
            <Box className={attributeObject.attribute === stateViewField ? classes.formControlContainer : classes.none} >
              <AttributeFilterset
                // cars={data.cars}
                valueSet={valueSet}

                attribute={attributeObject.attribute}
                attributeName={attributeObject.name}
                // causes component to rerender when filters changes
                // leaving this out speeds up response by about 40%, still takes two mississippi
                // don't see how to get the filters to persist through other pages without this
                // maybe the initial values can live in a variable that's only set once so this doesn't rerender on every click
                initialFilterValues={data.filters ? data.filters[attributeObject.attribute] : undefined}
                propValues={data.filters ? data.filters[attributeObject.attribute] : undefined}
                onChange={handleFilterChange}
                onClear={handleClear}
                onClose={handleClose}
                onFilteredChange={onFilteredChange}
              />
            </Box>
          )}
          {/* {booleanAttributesNames.map((attributeObject, index) => (
            <Box>
              ..
            </Box>
          ))} */}
          {numericalAttributesNames.map((attributeObject) => (
            // how to assign keys here?
            <Box
              className={
                attributeObject.attribute === stateViewField
                  ? classes.formControlContainer
                  : classes.none
              }
            >
              <NumericalAttributeFilter
                className={
                  attributeObject.attribute === stateViewField
                    ? null
                    : classes.none
                }
                attribute={attributeObject.attribute}
                attributeName={attributeObject.name}
                onChange={handleNumericalChange}
                onClear={handleClear}
                onClose={handleClose}
                propValue={data.filters ? data.filters[attributeObject.attribute] : undefined}
                initialValue={data.filters ? data.filters[attributeObject.attribute] : undefined}
              />
            </Box>
          ))}
          {booleanAttributesNames.map((attributeObject) => {
            if (data.filters[attributeObject.attribute] !== undefined) {
              // how to assign keys here?
              return <Box
                className={
                  attributeObject.attribute === stateViewField
                    ? classes.formControlContainer
                    : classes.none
                }
              >
                <BooleanAttributeFilter
                  className={
                    attributeObject.attribute === stateViewField
                      ? null
                      : classes.none
                  }
                  attribute={attributeObject.attribute}
                  attributeName={attributeObject.name}
                  attributeHeading={attributeObject.heading}
                  onChange={handleFilterChange}
                  onClear={handleFilterChange}
                  onClose={handleClose}
                  initialValue={data.filters ? data.filters[attributeObject.attribute] : undefined}
                  propValue={data.filters[attributeObject.attribute]}
                />
              </Box>
            }
          })}
        </Collapse>
      </Box>}
      {!isSmallMedia && <Box className={stateViewField === null ? classes.none : classes.curtainBox} onClick={() => handleClose()} />}

      {/* desktop */}
      {isSmallMedia && <Box>

        {/* filterChips */}
        <Box>
          <DeleteChips filters={data.filters} />
        </Box>
        {filterAttributesNames.map(attributeObject =>
          <Box className={classes.filterSectionBox} >
            <AttributeFilterset
              // cars={data.cars}
              valueSet={valueSet}

              attribute={attributeObject.attribute}
              attributeName={attributeObject.name}
              // causes component to rerender when filters changes
              // leaving this out speeds up response by about 40%, still takes two mississippi
              // don't see how to get the filters to persist through other pages without this
              // maybe the initial values can live in a variable that's only set once so this doesn't rerender on every click
              initialFilterValues={data.filters ? data.filters[attributeObject.attribute] : undefined}
              propValues={data.filters ? data.filters[attributeObject.attribute] : undefined}
              onChange={handleFilterChange}
              onClear={handleClear}
              onClose={handleClose}
              onFilteredChange={onFilteredChange}
            />
          </Box>
        )}
        {numericalAttributesNames.filter(attributeObject => attributeObject.attribute !== "retailPrice").map((attributeObject) => (
          // how to assign keys here?
          <Box
            className={classes.filterSectionBox}
          >
            <NumericalAttributeFilter
              attribute={attributeObject.attribute}
              attributeName={attributeObject.name}
              onChange={handleNumericalChange}
              onClear={handleClear}
              onClose={handleClose}
              propValue={data.filters ? data.filters[attributeObject.attribute] : undefined}
              initialValue={data.filters ? data.filters[attributeObject.attribute] : undefined}
            />
          </Box>
        ))}
        {booleanAttributesNames.map((attributeObject) => {
          if (data.filters[attributeObject.attribute] !== undefined) {
            return <Box
              className={classes.filterSectionBox}
            >
              <BooleanAttributeFilter attribute={attributeObject.attribute} attributeName={attributeObject.name} attributeHeading={attributeObject.heading} initialValue={isInStockDiscount} propValue={data.filters[attributeObject.attribute]} onChange={handleFilterChange} />
            </Box>
          }
        })}
      </Box>}
    </Fragment>
  );
}