import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { makeStyles } from "@material-ui/core/styles";
import { ksFormat, priceFormat, numberFormat } from "../number-formats";
import CarImage from "./car-image";
import Card from "@material-ui/core/Card";
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Carousel from 'react-material-ui-carousel'
import { INCLUDED_MILES } from "../constants";
import { whetherInStockDiscount } from "../lease-total";


const useStyles = makeStyles((theme) => ({
  detailContainer: theme.detailContainer,
  detailIconBox: theme.detailIconBox,
  detailIcon: theme.detailIcon,
  detailBox: theme.detailBox,
  discountedPrice: theme.discountedPrice,
  strikeoutPrice: theme.strikeoutPrice,
  promotionSubheading: theme.promotionSubheading,
  promotionIcon: theme.promotionIcon,
  cardImage: { paddingTop: "40%" },
  carousel: {
    backgroundColor: theme.backgroundGrey,
    "& .MuiIconButton-root": {
      zIndex: 1
    },
  },
  CardContent: {
    backgroundColor: theme.backgroundGrey
  },
  phrase: { whiteSpace: "nowrap" }
}))

export default function CarDetail({ car, yearMakeModel, excessMileageDollars }) {
  const classes = useStyles();


  // const carDirectory = `${car.make
  //   .replace(" ", "-")
  //   .toLowerCase()}-${car.model
  //     .replace(" ", "-")
  //     .toLowerCase()}-${car.vin.slice(9, 17).toLowerCase()}`;
  // // const fileName = carDirectory + "-lead";
  // // let leadImage = "";
  // // try {
  // //   leadImage = require("../assets/images/cars/" + fileName + ".jpg");
  // // } catch (error) {
  // //   leadImage = defaultImage;
  // // }
  // const carouselItems = [1, 2, 3, 4].map(imageNumber => {
  //   const imagePrefix = "image-" + imageNumber
  //   let leadImage = ""
  //   try {
  //     // these images aren't in the directory, switched to -lead images at some point
  //     leadImage = require("../assets/images/cars/" + carDirectory + "/" + imagePrefix + ".jpg");
  //   } catch (error) {
  //     leadImage = defaultImage;
  //   }
  //   return { uri: leadImage }
  // })
  // // map to a list of CarImage

  console.log(car)

  const isInStockDiscount = whetherInStockDiscount(car)

  return (
    <Box>
      <Card variant="outlined">
        {/* <CardActionArea> */}
        <CardMedia
        // className={classes.cardImage}
        // image={leadImage}
        // title="Contemplative Reptile"
        >
          <Carousel autoPlay={false} navButtonsAlwaysVisible={true} className={classes.carousel}>
            {car.images.map((imageUrl) => <Box><CarImage url={imageUrl} aspectRatio={60} /></Box>)}
          </Carousel>
        </ CardMedia>

        <CardContent className={classes.CardContent}>
          <Typography gutterBottom variant="h5" component="h2">
            {yearMakeModel}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {ksFormat(car.mileage) + " miles"}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {car.trim}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {car.drivetrain}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {car.type}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {car.size}
          </Typography>
          {isInStockDiscount ?
            <Fragment>
              <Typography variant="body2" color="textSecondary">
                <span className={classes.strikeoutPrice}>{priceFormat(car.leaseMonthly * 2)}</span> <span className={classes.discountedPrice}>{priceFormat(car.leaseFirst)}</span> First Month, {priceFormat(car.leaseMonthly)} monthly
              </Typography>
              <Typography variant="body2" className={classes.promotionSubheading}><MonetizationOnIcon className={classes.promotionIcon} /> In-Stock Discount</Typography>
            </Fragment>
            :
            <Typography variant="body2" color="textSecondary">
              {priceFormat(car.leaseFirst) + " First Month, " + priceFormat(car.leaseMonthly) + " monthly"}
            </Typography>
          }
          <Typography variant="body2" color="textSecondary">
            <span>{numberFormat(INCLUDED_MILES) + " miles per month, "}</span>
            <span className={classes.phrase}>{priceFormat(excessMileageDollars, true) + " per mile excess"}</span>
          </Typography>
        </CardContent>
        {/* </CardActionArea> */}
      </Card>
    </Box>
  );
};

// export default CarDetail;
