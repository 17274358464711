import React, { Fragment } from "react";
import { useQuery } from "@apollo/react-hooks";
import styled, { css } from "react-emotion";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { GET_CARS_VIEW, GET_SORT } from "../pages/cars";
import { SORT_CARS } from "./cars-forms";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import { colors, unit } from "../styles";
import { IconButton } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import SimpleMenu from "./simple-menu";
import IconMenu from "./icon-menu";
import Loading from "./loading";

// import StyledSelect from "./cars-forms";
// import StyledForm from "./cars-forms";

// export { GET_SORT_VIEW, GET_CARS_VIEW };

const useStyles = makeStyles((theme) => ({
  sortMenuBox: { position: "relative" },
  sortMenu: { position: "absolute", right: 0, zIndex: 2 },
}));

export default function SortForm({ isLease, onChange }) {
  const client = useApolloClient();

  const classes = useStyles();

  // let { sortAttribute, isSortReverse } = client.readQuery({
  //   query: GET_SORT,
  // });

  const { data, loading, error } = useQuery(GET_SORT);

  const [sortCars, { loading: sortLoading, error: sortError }] = useMutation(
    SORT_CARS
  );

  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;

  // if (!sortAttribute) {
  //   sortAttribute = isLease ? "leaseTotal" : "retailPrice";
  //   client.writeData({ data: { sortAttribute, isSortReverse: false } });
  // }


  const handleSortChange = (event, value) => {
    const sortAttribute = value.replace("-reverse", "");
    const isSortReverse = value.includes("-reverse");
    const { cars, viewCars } = client.readQuery({
      query: GET_CARS_VIEW,
    });
    sortCars({
      variables: {
        sortAttribute,
        isSortReverse,
        cars: viewCars.length === 0 ? cars : viewCars,
      },
    });
    onChange(sortAttribute, isSortReverse)
    localStorage.setItem("sortAttribute", sortAttribute);
    localStorage.setItem("isSortReverse", isSortReverse);
  };

  return (
    <Box className={classes.sortMenuBox}>
      <IconMenu
        // make optione depend on isLease
        options={[
          { name: "price", value: "leaseTotal" },
          { name: "reverse price", value: "leaseTotal-reverse" },
          { name: "make", value: "make" },
          { name: "reverse make", value: "make-reverse" },
        ]}
        onChange={handleSortChange}
        icon={<ImportExportIcon />}
        className={classes.sortMenu}
        value={data.isSortReverse ? `${data.sortAttribute}-reverse` : data.sortAttribute}
      />
    </Box>
  );
}