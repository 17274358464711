import React from "react";
import { Link as RouterLink } from "@reach/router";
// import { Router, Link as RouterLink } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import PageHeading from "../components/page-heading";
import { Typography, Box } from "@material-ui/core";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  heading: { marginBottom: 6 },
  paragraph: { marginBottom: 4 },
  lastParagraph: { marginBottom: 10 },
  spanLead: { fontStyle: "italic" },
  endNote: { fontStyle: "italic" },
  narrowPageBox: theme.narrowPageBox
}));

export default function TermsOfUse({ ...other }) {
  const classes = useStyles();

  return (
    <Box className={classes.narrowPageBox}>
      <PageHeading>Terms of Use</PageHeading>
      <Typography variant="body1" className={classes.lastParagraph}>Welcome to Skip&rsquo;s.&nbsp; These Terms of Use (the &ldquo;Terms&rdquo;) govern all use of our website, content, products, and services (collectively the &ldquo;Services&rdquo;) made available by SKIP&rsquo;S LLC (&ldquo;Skip&rsquo;s,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo;).&nbsp; By using our Services in any manner, you (agree that you have read and accepted these Terms.&nbsp; The Terms are legally binding and we encourage you to read them carefully.&nbsp; If you have any questions about how Skip&rsquo;s works or about these Terms, please call us at (347) 754 7794 or email us at hi@driveskips.com.</Typography>
      <ol>
        <li><Typography variant="body1" className={classes.heading}>SUMMARY</Typography></li>
      </ol>
      <Typography variant="body1" className={classes.paragraph}>Skip&rsquo;s is a service that primarily facilitates used motor vehicle purchases by buyers.&nbsp; We provide information about vehicles that are available to us and offer to make efforts to purchase a vehicle on behalf of a buyer.</Typography>
      <Typography variant="body1" className={classes.lastParagraph}>Your use of our services imposes certain obligations and certain restrictions on you as defined throughout these Terms.&nbsp;</Typography>
      <ol start="2">
        <li><Typography variant="body1" className={classes.heading}>ACCESS AND USE OF THE SERVICES</Typography></li>
      </ol>
      <Typography variant="body1" className={classes.heading}>Scope of this agreement</Typography>
      <Typography variant="body1" className={classes.paragraph}>By accessing our website and/or using our Services in any manner, you agree that you have read and accepted these Terms.&nbsp; The Terms consist of these terms of use as well as those set forth in our Privacy Policy available at&nbsp;<Link componant={RouterLink} href="https://driveskips.com/pages/privacy-policy">www.driveskips.com/pages/privacy-policy</Link>.</Typography>
      <Typography variant="body1" className={classes.paragraph}>In addition to these Terms, you may be subject to other agreements with Skip&rsquo;s or our third party partners.&nbsp; For instance if you purchase a vehicle using our Services, will be subject to a Bill of Sale agreement with Skip&rsquo;s in addition to these Terms.</Typography>
      <Typography variant="body1" className={classes.heading}>Intended use</Typography>
      <Typography variant="body1" className={classes.paragraph}>Our Services are intended for use only by residents of the United States of America who are 18 years of age or older, and who have the capacity to enter into a valid contract.&nbsp; By accessing and/or using any part of our Services, you represent and warrant that you meet these requirements.&nbsp; If you are accepting these Terms on behalf of an organization, you represent that you have the authority to do so.&nbsp; Skip&rsquo;s does not knowingly collect information from minors, nor do we direct any of our Services to minors.</Typography>
      <Typography variant="body1" className={classes.paragraph}>In order to use certain services, you are required to contact us and provide information about yourself and the car you&rsquo;re shopping for.&nbsp; By using our Services or contacting us in any way, you warrant that you will not provide false, inaccurate, or misleading information.</Typography>
      <Typography variant="body1" className={classes.paragraph}>Our Services are offered to retail buyers only.&nbsp; Skip&rsquo;s does not offer our services to dealers or their agents or associates.&nbsp; By accessing and/or using any part of our Services, you represent and warrant that you will not (i) use our Service to facilitate price boosting or &ldquo;shilling&rdquo;; (ii) use our service to locate a vehicle and then complete a transaction independent of Skip&rsquo;s in order to circumvent the obligation to pay Skip&rsquo;s any fees; (iii) pose as a retail buyer while using our Services.&nbsp; In the event that you are found to be engaged in such activities, Skip&rsquo;s may pursue legal or law enforcement action against you.</Typography>
      <Typography variant="body1" className={classes.heading}>Modification to the Terms</Typography>
      <Typography variant="body1" className={classes.lastParagraph}>We reserve the right to modify these Terms at any time, effective upon posting the amended Terms on this website.&nbsp; If we make changes, we will notify you by, at a minimum, revising the date at the top of these Terms.&nbsp; You are responsible for regularly reviewing these Terms. Continued use of the Services after any such changes will constitute your consent to such changes.</Typography>
      <ol start="3">
        <li><Typography variant="body1" className={classes.heading}>VEHICLE PURCHASE SERVICE</Typography></li>
      </ol>
      <Typography variant="body1" className={classes.paragraph}>Skip&rsquo;s is licensed as a motor vehicle dealer in New York.&nbsp; We provide a service that primarily facilitates a used motor vehicle purchase for buyers.&nbsp; Once a buyer contacts Skip&rsquo;s, we provide information about vehicles that are available to us and offer to make efforts to purchase a vehicle on behalf of the buyer.&nbsp; Buyers pay a refundable Retainer.&nbsp; If we are successful in purchasing the vehicle, we offer it for sale to the buyer.&nbsp; If the buyer elects to purchase the vehicle from us, Skip&rsquo;s will complete the sale to the buyer by (i) giving all purchase-related disclosures to the buyer, (ii) executing all required purchase and sale documents with the buyer, (iii) receiving payment for the vehicle from the buyer, and (iv) delivering the vehicle to the buyer.</Typography>
      <Typography variant="body1" className={classes.paragraph}>In offering our Service, we provide buyers with information about vehicles that are available to us in the wholesale marketplace and elsewhere.&nbsp; We do not have these vehicles in stock.&nbsp; We do not own these vehicles and we are not offering them for sale; we are offering to make efforts to purchase these vehicles for buyers.&nbsp; The information we provide may include exemplar images, vehicle specifications, prices, inspection reports and associated component service life estimates, and third party history reports.&nbsp; Skip&rsquo;s disclaims any responsibility for the accuracy of these reports.</Typography>
      <Typography variant="body1" className={classes.paragraph}>Due to changes in market conditions and other circumstances, we may not always be able to purchase the vehicle and offer it for sale.&nbsp; Skip&rsquo;s disclaims any responsibility for the availability of the vehicles we offer to make efforts to purchase.</Typography>
      <Typography variant="body1" className={classes.paragraph}>The Fingers Crossed Price includes our acquisition fee, but does not include taxes, title, registration, delivery fees (if any), and optional extended service contracts.&nbsp; The final purchase price depends on the buyer&rsquo;s place of residence and other factors such as whether the buyer is purchasing an extended service contract for the vehicle.&nbsp; Skip&rsquo;s can provide buyers with the final purchase price before the buyer pays the Retainer or at any time at their request.</Typography>
      <Typography variant="body1" className={classes.paragraph}>If we are successful in purchasing the vehicle, Skip&rsquo;s will notify the buyer and transport the vehicle to our office or to the buyer&rsquo;s home or place of business where the buyer is invited to test drive the vehicle.&nbsp; If the buyer elects to purchase the vehicle, Skip&rsquo;s will complete the sale to the buyer.&nbsp; If the buyer decides not to purchase the vehicle, Skip&rsquo;s will refund the Retainer.</Typography>
      <Typography variant="body1" className={classes.paragraph}>Skip&rsquo;s will have complied with applicable inspection and used vehicle certification laws for the vehicle.&nbsp;&nbsp; Skip&rsquo;s will provide the buyer with all certifications and disclosures as required by state and federal law.</Typography>
      <Typography variant="body1" className={classes.paragraph}>The buyer and Skip&rsquo;s will mutually execute the appropriate sales agreements and ownership document.&nbsp; Skip&rsquo;s will refund the Retainer and accept payment in the amount of the final purchase price.&nbsp; A buyer can pay for the vehicle by cashier&rsquo;s check, bank wire, or with a check from a verified auto lender if the vehicle is financed.</Typography>
      <Typography variant="body1" className={classes.paragraph}>In some cases Skip&rsquo;s will offer the buyer the option of taking possession of the certificate of sale and ownership document and submitting for title and registration on his/her own.&nbsp; Otherwise the buyer and Skip&rsquo;s will mutually execute the title and registration application documents and Skip&rsquo;s will submit the application to the relevant state motor vehicle authority on behalf of the buyer.</Typography>
      <Typography variant="body1" className={classes.paragraph}>If the buyer is trading in a vehicle, the buyer must provide us with satisfactory proof that they own the trade-in vehicle. The buyer warrants that the vehicle does not have a welded or bent frame, that the motor block is not cracked, welded, or repaired, and that the vehicle has not been flood damaged or declared a total loss for insurance purposes. The buyer must disclose any and all liens on the trade-in to Skip&rsquo;s and provide us with sufficient loan details so we may contact the lienholder to confirm the balance owed and so we may satisfy the lien. The buyer acknowledges that if he or she provides Skip&rsquo;s with any false, incomplete, inaccurate, or misleading information, any sales agreements including but not limited to the Bill of Sale entered into between the buyer and Skip&rsquo;s are null and void and of no force or effect and the buyer will be responsible to compensate Skip&rsquo;s for any costs, losses, or damages resulting from any false, incomplete, inaccurate, or misleading information. Skip&rsquo;s may provide fair, good, and very good trade-in allowance estimates for a buyer&rsquo;s vehicle. These values are estimates only. We will provide a final trade-in allowance only after completing a physical inspection and a test drive. If at the time the trade-in vehicle is delivered, the value of the vehicle has been materially diminished as a result of physical damage, alteration or deterioration in mechanical condition other than normal wear and tear, Skip&rsquo;s will have the right to reduce the trade-in allowance.</Typography>
      <Typography variant="body1" className={classes.paragraph}>Every vehicle purchased from Skip&rsquo;s comes with a 7-Day Money Back Guarantee.&nbsp; As long as (i) the vehicle is materially different from the one in our description, (ii) the vehicle has not been driven more than a total of 1,000 miles, and (iii) the vehicle is in the same condition it was in at the time of delivery (collectively the &ldquo;7-Day Money Back Guarantee Conditions&rdquo;) a buyer can, within 7 days of delivery, return the vehicle to Skip&rsquo;s and receive a full refund.</Typography>
      <Typography variant="body1" className={classes.paragraph}>&nbsp;</Typography>
      <Typography variant="body1" className={classes.paragraph}>In the event that, after accepting delivery of a vehicle, a buyer finds a discrepancy between our description for the vehicle and the vehicle itself, the buyer may, as long as the 7-Day Money Back Guarantee Conditions are met, return the vehicle and receive a full refund.</Typography>
      <Typography variant="body1" className={classes.paragraph}>&nbsp;</Typography>
      <Typography variant="body1" className={classes.lastParagraph}>Every vehicle purchased from Skip&rsquo;s comes with a Pitcrew Warranty Limited Warranty, which can be found at&nbsp;<a href="http://driveskips.com/pages/pitcrew-warranty-limited-warranty">www.driveskips.com/pages/pitcrew-warranty-limited-warranty</a>.&nbsp; Our Warranty covers repairs for critical components like the engine, transmission, brakes, and steering.</Typography>
      <ol start="4">
        <li><Typography variant="body1" className={classes.heading}>USER PROVIDED CONTENT</Typography></li>
      </ol>
      <Typography variant="body1" className={classes.paragraph}>We may from time to time permit you to submit, upload, publish, or otherwise make available to us textual, visual, audio, and/or video content including commentary, feedback, ideas, and support requests.&nbsp; By providing content (&ldquo;User Content&rdquo;) to Skip&rsquo;s you grant us a worldwide, perpetual, irrevocable, transferrable, royalty-free license, with the right to sublicense, to use, copy, modify, create derivative works of, distribute, publicly display, and otherwise exploit in any manner your User Content without further notice to or consent from you, and without the requirement of payment to you or any other person or entity.</Typography>
      <Typography variant="body1" className={classes.paragraph}>You represent and warrant that: (i) you are the sole author and owner of the intellectual property and other rights to the User Content, or you have a lawful right to submit the User Submission and grant Skip&rsquo;s the license to the User Content as set forth above; and (ii) the User Content is true and accurate; (iii) our use and your submission of the User Content does not infringe or violate any third party&rsquo;s intellectual property or proprietary rights; (iv) the User Content complies with all applicable laws and regulations.</Typography>
      <Typography variant="body1" className={classes.paragraph}>You agree not to: (i) provide User Content that is defamatory, indecent, libelous, hateful, violent, obscene, pornographic, or unlawful; (ii) use the Services to threaten, harass, or violate the legal rights of others; (iii) use a false email address or pretend to be someone other than yourself; (iv) upload files that contain a virus or other corrupted data; (v) falsify the source or origin of software or other material contained in a file that you upload; (vi) falsely purport to be an employee or agent of Skip&rsquo;s.</Typography>
      <Typography variant="body1" className={classes.paragraph}>Skip&rsquo;s is not responsible for the content of, and any harm resulting from, your User Submissions or those of others.&nbsp; We do not assume any responsibility or liability for any User Content posted on the Services.</Typography>
      <Typography variant="body1" className={classes.heading}>Accuracy of website information</Typography>
      <Typography variant="body1" className={classes.paragraph}>We work to provide users with up-to-date information about our Service, but it is inevitable that some inaccuracies may be present.&nbsp; Photos and exemplars of motor vehicles on our website or in our email correspondence with you may appear differently based on your monitor and color settings.&nbsp; Subject to applicable state or federal law, Skip&rsquo;s will not be responsible for errors found on this website, including but not limited to pricing errors or an incorrect statement about the options or accessories on a particular vehicle.&nbsp; Unless stated otherwise, we make no guarantees regarding the accuracy, completeness, timeliness, or reliability of any of the materials or information on this website, and you should not rely on it without independent verification.</Typography>
      <Typography variant="body1" className={classes.heading}>Links to third party content</Typography>
      <Typography variant="body1" className={classes.lastParagraph}>The Services may contain links to third party websites (&ldquo;Third Party Websites&rdquo;).&nbsp; Access to Third Party Websites is at your own risk, and Skip&rsquo;s is not responsible for the accuracy, appropriateness, availability, or reliability of any content made available on Third Party Websites.</Typography>
      <ol start="5">
        <li><Typography variant="body1" className={classes.heading}>PROPRIETARY RIGHTS AND TRADEMARK LICENSE</Typography></li>
      </ol>
      <Typography variant="body1" className={classes.paragraph}>All information contained in this website and in our email correspondence with you, unless otherwise stated, is owned solely and exclusively by Skip&rsquo;s. You acknowledge and agree that all contents available on this website, unless otherwise stated, are protected by copyrights, trademarks, service marks, trade secrets, and/or other proprietary or intellectual property rights and laws.&nbsp; By permitting you to access this website, Skip&rsquo;s has not granted you a license of any kind to use this information for any purpose.</Typography>
      <Typography variant="body1" className={classes.lastParagraph}>All other trademarks (whether registered or unregistered), logos, service marks, and/or company or product names on this website are the property of their respective owners.&nbsp;</Typography>
      <ol start="6">
        <li><Typography variant="body1" className={classes.heading}>DISCLAIMER OF WARRANTIES</Typography></li>
      </ol>
      <Typography variant="body1" className={classes.lastParagraph}>The Services are provided &ldquo;as is&rdquo; and &ldquo;as available.&rdquo;&nbsp; Except as otherwise required by applicable law, Skip&rsquo;s disclaims all representations and warranties, expressed or implied, of the information and related graphics on this website not expressly set out in these Terms, including the implied warranties of merchantability and fitness for a particular purpose, title and non-infringement, and any warranties arising from course of performance, course of dealing, or usage in trade.&nbsp; In addition, to the extent permitted by applicable law, we are not liable, and you agree not to hold us responsible for any indirect, consequential, incidental, or punitive damages or losses, including, without limitation, lost profits or revenues, costs of replacement of goods, or for damage resulting from the use of or reliance on the information contained herein.&nbsp; Some states do not allow the disclaimer of warranties or exclusion of damages, so such disclaimers and exclusions may not apply to you.</Typography>
      <ol start="7">
        <li><Typography variant="body1" className={classes.heading}>AGREEMENT TO ARBITRATE ALL DISPUTES AND LEGAL CLAIMS</Typography></li>
      </ol>
      <Typography variant="body1" className={classes.paragraph}>You agree that any dispute, claim or controversy arising out of or relating to these terms or the breach, termination, enforcement, interpretation or validity thereof or the use of the Services that cannot be resolved informally will be settled by binding arbitration between you and Skip&rsquo;s.&nbsp; The arbitration will be performed by the American Arbitration Association under its Commercial Arbitration Rules, or as otherwise mutually agreed by you and Skip&rsquo;s.&nbsp; Judgement on the arbitration award may be entered in any court having jurisdiction thereof.&nbsp; Claims shall be brought within the time required by applicable law.&nbsp; You agree that any claim, action, or proceeding must be brought in your individual capacity, and not as plaintiff or class member in any purported class, collective, or representative proceeding.&nbsp; The arbitrator may not consolidate more than one person&rsquo;s claims, and may not otherwise preside over any form of a representative, collective, or class proceeding.</Typography>
      <Typography variant="body1" className={classes.lastParagraph}>YOU ACKNOWLEDGE AND AGREE THAT YOU AND SKIP&rsquo;S ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING.</Typography>
      <ol start="8">
        <li><Typography variant="body1" className={classes.heading}>VIOLATIONS OF THESE TERMS</Typography></li>
      </ol>
      <Typography variant="body1" className={classes.lastParagraph}>In the event you violate or attempt to violate any part of these Terms, Skip&rsquo;s reserves the right to terminate your access to this website immediately, at our sole discretion, with or without notice. &nbsp;We also reserve the right to pursue legal or law enforcement action against you.</Typography>
      <ol start="9">
        <li><Typography variant="body1" className={classes.heading}>INDEMNITY</Typography></li>
      </ol>
      <Typography variant="body1" className={classes.lastParagraph}>You will defend, indemnify, and hold us and our affiliates harmless from any claims, actions, suits, losses, costs, liabilities and expenses (including reasonable attorneys&rsquo; fees) relating to or arising out of your use of the Services, regardless of the nature of the cause of action or claim.&nbsp;</Typography>
      <ol start="10">
        <li><Typography variant="body1" className={classes.heading}>SEVERABILITY</Typography></li>
      </ol>
      <Typography variant="body1" className={classes.paragraph}>If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced to the fullest extent under law.&nbsp; Our failure to enforce any right or provision in these Terms will not constitute a waiver of such right or provision unless acknowledged and agreed to by Skip&rsquo;s in writing.</Typography>
      <Typography variant="body1" className={classes.paragraph}>&nbsp;</Typography>
      <Typography variant="body1" variant="body1" className={classes.endNote}>Last Updated: August 30, 2018</Typography>
    </Box>
  );
}
