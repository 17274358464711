import React, { Fragment } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery, useApolloClient } from "@apollo/react-hooks";
import { GET_CARTLEASE, UPDATE_CARTLEASE } from "../pages/reserve";
import { Link, useNavigate } from "@reach/router";

import styled from "react-emotion";
import { size } from "polished";

import { unit, colors } from "../styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import MenuItem from "./menu-item";
import { GET_CUSTOMER } from "../pages/cars";
import { GET_CART } from "../pages/details";

const useStyles = makeStyles((theme) => ({
  formSubmit: theme.formSubmit,
  checkoutBarBottom: theme.checkoutBarBottom,
  checkoutToolbar: theme.checkoutToolbar
}))

// export const ADD_TO_CART = gql`
//   mutation addCarToCart($vin: String!) {
//     addCarToCart(vin: $vin) @client
//   }
// `;

export default function LeaseReserve({
  customerTaxRate,
  taxRateAccuracy,
  customerState,
  stateAccuracy,
  insuranceMonthly,
  insuranceAccuracy
}) {
  // const [toReserve, setToLease] = React.useState(false);
  const client = useApolloClient();

  const classes = useStyles();

  const navigate = useNavigate();

  const theme = useTheme();

  const isSmallMedia = useMediaQuery(theme.breakpoints.down("sm"));

  const [
    updateCartLease,
    { loading: cartLeaseLoading, error: cartLeaseError },
  ] = useMutation(UPDATE_CARTLEASE);

  // this would probably make more sense as a prop
  const handleClick = async (event) => {
    // why doesn't this seem to update?
    // try awaiting a mutation?
    const cartLeaseResponse = await updateCartLease({
      variables: {
        cartLeaseUpdates: {
          customerTaxRate,
          taxRateAccuracy,
          customerState,
          stateAccuracy,
          insuranceMonthly,
          insuranceAccuracy,
          createdDate: new Date().toISOString(),
        },
      },
    });
    // client.writeQuery({
    //   query: GET_CARTLEASE,
    //   data: {
    //     cartLease: {
    //       customerTaxRate,
    //       taxRateAccuracy,
    //       customerState,
    //       stateAccuracy,
    //       insuranceMonthly,
    //       insuranceAccuracy,
    //     },
    //   },
    // });
    console.log(cartLeaseResponse);
    navigate("/reserve/review");
  };

  // const { data, loading, error } = useQuery(GET_CART);

  return (
    isSmallMedia ?
      <Fragment>

        <AppBar
          position="fixed"
          // classes={{ colorPrimary: classes.colorPrimary }}
          className={classes.checkoutBarBottom}
        // elevation={0}
        >
          <Toolbar className={classes.checkoutToolbar}>
            <Button
              // component={Link}
              variant="contained"
              color="primary"
              onClick={handleClick}
            >
              Start Reservation
        </Button>
          </Toolbar>
        </AppBar>
        {/* // meant to create space on page as detailed here: https://material-ui.com/components/app-bar/#fixed-placement */}
        <Toolbar />
      </Fragment> :

      <Box className={classes.formSubmit}>
        <Button
          // component={Link}
          variant="contained"
          color="primary"
          onClick={handleClick}
        >
          Start Reservation
        </Button>
      </Box>
  );
}
