import React from "react";
import ReactDOM from "react-dom";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloProvider, useQuery } from "@apollo/react-hooks";
import { persistCache } from 'apollo-cache-persist';
import gql from "graphql-tag";
import differenceInDays from "date-fns/differenceInDays";
import { createUploadLink } from "apollo-upload-client";
import Pages from "./pages";
import Login from "./pages/login";
import { resolvers, typeDefs } from "./resolvers";
import { dateOnly } from "./dates";
import addDays from "date-fns/addDays";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import blue from "@material-ui/core/colors/blue";
import purple from "@material-ui/core/colors/purple";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  filtersInitial,
  cartLeaseInitial,
} from "./constants";
import { UPDATE_CUSTOMER_STATE } from "./components/customer-form";
import { indigo, green } from "@material-ui/core/colors";
import { GET_CARTLEASE_CARTCAR_CUSTOMER } from "./pages/reserve-confirm";
// import _ from "./env";

const initialData = {
  isLoggedIn: false,
  // cartItems: [],
  sortAttribute: null,
  isSortReverse: false,
  isFilterView: false,
  searchText: "",
  pickupText: "",
  dropoffText: "",
  customerState: "New York",
  customerZip: null,
  customerCounty: null,
  customerTaxRate: 8,
  // changed this from "guess", see what happens
  taxRateAccuracy: "guess",
  viewCars: [],
  filters: filtersInitial,
  // not sure if we're still using isFiltered
  isFiltered: false,
  isFiltersClear: true,
  // filteredCars: [],
  searchMakes: [],
  // add a typename here:
  searchMakeModels: [],
  isZipDialog: false,
  isUsingDeposit: false,
  // temporary
  // don't think this is in use
  cartVin: "5NPD84LF9KH436823",
  cartCar: null,
  // temporary
  customerEmail: null,
  customerPhone: null,
  customerLicenseUri: null,
  // customerEmail: "mevonstein@gmail.com",
  // customerPhone: "(203) 333 3333",
  // customerLicenseUri:
  //   "https://skips-virginia.s3.amazonaws.com/license-Scan0001.jpg",
  customerPickupLocation: null,
  customerDropoffLocation: "West Nyack, NY",
  customerPickupLocations: [],
  customerPickupDate: dateOnly(addDays(new Date(), 7)).toISOString(),
  customerLeaseMonths: 3,
  // temp
  viewField: null,
  // not sure we need these still
  // firstSuggestionAddress: null,
  // firstSuggestionLatitude: null,
  // firstSuggestionLongitude: null,
  // closestLocationCity: null,
  // closestLocationDistance: null,
  // secondClosestCity: null,
  // secondClosestDistance: null,
  isShowMore: false,
  customerCoordinates: { lat: null, lng: null, __typename: "Coordinates" },
  cartLease: cartLeaseInitial,
  insuranceMonthly: 150,
  insuranceAccuracy: "guess",
  stateAccuracy: "guess",
};

const generateClientOld = () => {

  // Set up our apollo-client to point at the server we created
  // this can be local or a remote endpoint
  const cache = new InMemoryCache();

  const client = new ApolloClient({
    cache,
    // link: new HttpLink({
    //   uri: "http://localhost:4000/graphql",
    //   headers: {
    //     authorization: localStorage.getItem("token"),
    //     "client-name": "Space Explorer [web]",
    //     "client-version": "1.0.0",
    //   },
    // }),
    //@ts-ignore, don't need this we're working in js
    link: createUploadLink({
      // using the ip throws a cors error
      uri: process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_SERVER_DEVELOPMENT_URI
        : process.env.REACT_APP_SERVER_PRODUCTION_URI,
      // uri: process.env.REACT_APP_SERVER_PRODUCTION_URI,
    }),
    resolvers,
    typeDefs,
  });

  // this throws an error, can this be put in a hook instead?  getDerivedStateFromProps?
  // const { data } = useQuery(GET_CARS);

  const localStorageState = localStorage.getItem("state");

  console.log("default state")
  // write Apollo cache with default state
  client.writeData({
    data: initialData
  });

  /**
   * Render our app
   * - We wrap the whole app with ApolloProvider, so any component in the app can
   *    make GraphqL requests. Our provider needs the client we created above,
   *    so we pass it as a prop
   * - We need a router, so we can navigate the app. We're using Reach router for this.
   *    The router chooses between which component to render, depending on the url path.
   *    ex: localhost:3000/login will render only the `Login` component
   */

  !!localStorage.getItem("sortAttribute") &&
    client.writeData({
      data: {
        isSortReverse: localStorage.getItem("isSortReverse"),
        sortAttribute: localStorage.getItem("sortAttribute"),
      },
    });

  // call updateCustomerState to seed customerTaxRate and associated accuracy fields
  !!localStorageState &&
    client.mutate({
      mutation: UPDATE_CUSTOMER_STATE,
      variables: { customerState: localStorageState },
    });


}

//  don't think we're using isLoggedIn any longer
const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

function IsLoggedIn() {
  const { data } = useQuery(IS_LOGGED_IN);
  if (!data.isLoggedIn) {
    return <Login />;
  }
}

const siteWidth = 960, wideSiteWidth = 1120, readingWidth = 620;

// generic default theme allows us to use breakpoints when creating the theme we'll use
const defaultTheme = createMuiTheme();
const theme = createMuiTheme({
  siteWidth,
  wideSiteWidth,
  readingWidth,

  // colors
  grey: "#131313",
  green: "#4cae4c",
  red: "#f44336",
  skipsYellow: "#fed136",
  pavement: "#80827f",
  backgroundGrey: "#f5f5f5",
  backgroundPlaceholderGrey: "#d4d4d4",
  curtainBackground: "rgba(0, 0, 0, 0.54)",
  placeholderGrey: "rgba(0, 0, 0, 0.54)",
  headingGrey: grey[700],
  noteGrey: grey[500],
  none: {
    display: "none"
  },
  hidden: {
    visibility: "hidden"
  },
  palette: {
    type: "light",
    background: { default: "#fff" },
    primary: blue,
    secondary: purple,
    text: { primary: grey[900] }
  },
  typography: {
    h5: { color: grey[700] },
    h6: { color: grey[700] }
  },
  //
  // global classes
  //
  appBar: {
    alignItems: "center",
    marginBottom: 16
    // no good for map view
  },
  toolBar: { width: "100%", maxWidth: siteWidth },
  // forms that are primary to page function - e.g. checkout form
  formElement: {
    marginTop: 22,
  },
  mediumInput: {
    minWidth: 240
  },
  // embedded form - "switchbank"
  switchbankRow: {
    marginBottom: 12,
  },
  switchbankIconMenu: {
    position: "absolute",
    marginTop: 6,
    [defaultTheme.breakpoints.only("xs")]: {
      // this overrides the pageContainer padding
      left: 0,
    },
  },
  switchbankBox: {
    [defaultTheme.breakpoints.up("sm")]: {
      paddingLeft: 62,
    },
    [defaultTheme.breakpoints.only("xs")]: {
      paddingLeft: 36,
    },
  },
  detailContainer: { display: "flex", marginBottom: 8 },
  detailIconBox: { paddingRight: 8, paddingBottom: 8, paddingTop: 2 },
  detailIcon: { color: grey[600] },
  detailBox: { flexGrow: 1 },
  subsectionEndBox: { marginBottom: 26 },
  caption: { display: "block" },
  formSubmit: { marginTop: 32, marginBottom: 28 },
  checkoutBarBottom: { top: "auto", bottom: 0, backgroundColor: "white" },
  checkoutToolbar: { justifyContent: "flex-end" },
  heroImageBox: {
    width: "100%",
    backgroundSize: "cover",
    [defaultTheme.breakpoints.up("sm")]: { backgroundPosition: "center" },
    [defaultTheme.breakpoints.only("xs")]: { backgroundPosition: "24%" },
  },
  highlight: {
    // otherwise background is full-width
    display: "inline",
    background: "rgba(0,0,0,0.2)",
    padding: "5px 0",
    boxShadow: "12px 0 0 rgba(0,0,0,0.2),-12px 0 0 rgba(0,0,0,0.2)",
    // space element away from gutters and things since it's padded
    margin: "5px 12px",
    boxDecorationBreak: "clone",
    color: "#fff",
    // lineHeight: 1.49
    lineHeight: 1.62
  },
  mediumPageBox: {
    width: "100%",
    maxWidth: 840,
    margin: "0 auto",
  },
  narrowPageBox: {
    width: "100%",
    maxWidth: 710,
    margin: "0 auto",
  },
  overrides: {
    MuiLink: {
      root: {
        color: indigo[500]
      }
    }
  },
  containedIconButton: {
    minWidth: "unset",
    padding: 10,
    borderRadius: "50%",
  },
  fileSectionContainer: {
    display: "flex",
    alignItems: "flex-start",
    // maxWidth: 600
  },
  fileInputComponent: {
    marginRight: 14,
  },
  fileInputBox: {
    flexShrink: "0",
    flexBasis: "auto",
  },
  reviewImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    objectPosition: "left",
    marginTop: 9,
  },
  reviewImageBox: {
    // width: 140,
    // height: 140,
    flexGrow: "2",
    flexShrink: "2",
    // smaller flex-basis helps the .fileInputBox maintain enough width
    flexBasis: 180,
    marginRight: 10
  },
  discountedPrice: {
    color: green[600]
  },
  strikeoutPrice: {
    textDecoration: "line-through",
    color: grey[800]
  },
  promotionSubheading: {
    display: "flex",
    alignItems: "center",
    color: green[600],
    fontSize: 12,
  },
  promotionIcon: {
    marginRight: 3,
    fontSize: "0.9rem"
  },
  tileSlideBox: {
    overflowX: "scroll",
    whiteSpace: "nowrap",
    WebkitOverflowScrolling: "touch",
    "&::-webkit-scrollbar": { display: "none" },
  }

});

// how to
// do we need this?
// injectStyles();
// ReactDOM.render(
//   <ApolloProvider client={client}>
//     <ThemeProvider theme={theme}>
//       <CssBaseline />
//       <Pages />
//     </ThemeProvider>
//   </ApolloProvider>,
//   document.getElementById("root")
// );

// source: https://github.com/yucigou/graphql-local-state-management/blob/master/src/index.js
const cache = new InMemoryCache();

const init = async () => {
  // only do this when the cookie for lastCache is null or is over one day old?

  const localStorageCacheDate = localStorage.getItem("cacheDate");
  console.log("localStorageCacheDate ", localStorageCacheDate)
  if (differenceInDays(dateOnly(new Date()), localStorageCacheDate) > 0 || !localStorageCacheDate) {
    // if (true) {
    // don't persist the cache, set a new cacheDate
    console.log("setting a new cacheDate, cars should load from server")
    localStorage.setItem("cacheDate", dateOnly(new Date()))
  } else {
    // persist the cache, we're back in under a day
    console.log("persisting cache")
    await persistCache({
      cache,
      storage: window.localStorage
    });
  }

  const client = new ApolloClient({
    cache,
    link: createUploadLink({
      // using the ip throws a cors error
      uri: process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_SERVER_DEVELOPMENT_URI
        : process.env.REACT_APP_SERVER_PRODUCTION_URI,
      // uri: process.env.REACT_APP_SERVER_PRODUCTION_URI,
    }),
    resolvers,
    typeDefs,
  });

  try {
    console.log("trying readQuery")
    cache.readQuery({
      query: GET_CARTLEASE_CARTCAR_CUSTOMER
    });
  } catch (error) {
    console.log("readQuery error: ", error)
    // persist works when writeData is commented
    cache.writeData({ data: initialData });
  }

  const ApolloApp = () => (
    <ApolloProvider client={client}>
      {/* the rest of your app goes here */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Pages />
      </ThemeProvider>
    </ApolloProvider>
  )

  ReactDOM.render(<ApolloApp />, document.getElementById('root'))
};

init();


// ReactDOM.render(App(), document.getElementById("root"))


// write sort data to client if it's present

// const [
//   updateCustomerState,
//   { loading: customerLoading, error: customerError },
// ] = useMutation(UPDATE_CUSTOMER_STATE);

// const { data } = useQuery(GET_CARS);

// Invalid hook call. Hooks can only be called inside of the body of a function component. This could happen for one of the following reasons:
// 1. You might have mismatching versions of React and the renderer (such as React DOM)
// 2. You might be breaking the Rules of Hooks
// 3. You might have more than one copy of React in the same app
// See https://fb.me/react-invalid-hook-call for tips about how to debug and fix this problem.

// sortCars({
//   variables: { sortAttribute, isSortReverse: false, cars: data.cars }
// });

// temporary comment becuase we're testing a stripped down client to debug file upload
// client
//   .query({
//     query: gql`
//       query GetCartTest {
//         # cartVin @client
//         # customerPickupDate @client
//         cars {
//           model
//           location
//           deliveryDays
//         }
//       }
//     `,
//   })
//   .then((result) => console.log(result));
