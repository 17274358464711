import React, { Fragment } from "react";
import {
  useApolloClient,
  useQuery,
  writeData,
  useMutation,
} from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useNavigate } from "@reach/router";
import { InsuranceEmailDialog, StripeForm, FileInput } from "../components";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PhoneTextField from "./phone-textfield";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import ButtonBase from "@material-ui/core/ButtonBase";
import { FormGroup, FormHelperText, Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Input from "@material-ui/core/Input";
import NextBarButton from "./next-bar-button";
import { GET_LEASEACCOUNT_CONDITION } from "../pages/condition-images";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  fileInput: {
    display: "none",
  },
  choiceCard: {
    margin: 10,
    flex: "1 1 200px",
  },
  choiceCardCard: {
    width: "100%",
  },
  choiceCardHeader: {
    padding: 12,
  },
  choiceCardContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  formBox: { marginBottom: 28 },

  // theme imports
  formElement: theme.formElement,
  formSubmit: theme.formSubmit,
  checkoutBarBottom: theme.checkoutBarBottom,
  checkoutToolbar: theme.checkoutToolbar,
  fileSectionContainer: theme.fileSectionContainer,
  fileInputComponent: theme.fileInputComponent,
  fileInputBox: theme.fileInputBox,
  reviewImage: theme.reviewImage,
  reviewImageBox: theme.reviewImageBox
}));

const imageExtensionRegex = /\.[jpgen]{3,4}$/gi;
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const UPLOAD_FILE = gql`
  mutation uploadFile($file: Upload!, $label: String!) {
    uploadFile(file: $file, label: $label) {
      filename
      mimetype
      encoding
      uri
    }
  }
`;

const ADD_CONDITION_FILE = gql`
  mutation addConditionFile($file: Upload!, $skipsLeaseId: String!) {
    addConditionFile(file: $file, skipsLeaseId: $skipsLeaseId) {
      filename
      mimetype
      encoding
      uri
    }
  }
`;

export default function ImagesUploadForm({
  onSubmit, label = "condition"
}) {
  const client = useApolloClient();
  const navigate = useNavigate();

  const classes = useStyles();
  const theme = useTheme();

  const isSmallMedia = useMediaQuery(theme.breakpoints.down("sm"));

  const [imageUri, setImageUri] = React.useState("");
  const [imageUris, setImageUris] = React.useState([""]);


  // const [upload, { loading: uploadLoading, error: uploadError }] = useMutation(
  //   UPLOAD_FILE
  // );

  const [upload, { loading: uploadLoading, error: uploadError }] = useMutation(
    UPLOAD_FILE
  );

  // const [sendEmail, { loading: emailLoading, error: emailError }] = useMutation(
  //   SEND_EMAIL
  // );

  // update here-down

  const uploadImageSet = async (file, label) => {
    const { data } = await upload({
      variables: {
        file,
        label: "condition"
      },
    });
    console.log(data.uploadFile.uri)
    setImageUris(imageUris => imageUris[0] === "" ? [data.uploadFile.uri] : [...imageUris, data.uploadFile.uri])
  }

  const handleFileChange = async ({
    target: {
      validity,
      files,
      name,
    },
  }) => {
    // or set some kind of error state
    if (!validity.valid) return;
    console.log(files.length)
    console.log(files)
    if (files.length === 1) {
      const file = files[0]
      const { data } = await upload({
        variables: {
          file,
          label
        },
      });

      setImageUris([data.uploadFile.uri])
    } else {
      Promise.all(Array(files.length).fill(0).map((value, index) => uploadImageSet(files[index])
      ))
    }
  };

  // start with one-step

  // const handleFormSubmit = async (event) => {
  //   event.preventDefault();
  //   // setSubmitStatus("attempted");
  //   // first check that the form's complete and valid
  //   // if (!checkFormValidity()) return;

  //   console.log(imageUris)
  //   console.log(accountId)
  //   const { data } = await pushConditionImage({ variables: { imageUris, skipsLeaseId: accountId } })
  //   console.log(data)
  //   if (data.pushConditionImage.__typename === "Account") {
  //     // assume the image was pushed if the type is correct, not getting any other fields for some reason
  //     // setImageUris([""])
  //   }
  // };

  console.log(imageUris)

  // no error
  return (
    <form>
      <Fragment>
        {/* work out how to display the filename and update instead of the initial input */}
        {
          <Box className={classes.formElement + " " + classes.formBox}>
            <FormLabel
              // error={!formData.imageUri && submitStatus === "attempted"}
              className={classes.labelHeading}
            >
              Damage Image
            </FormLabel>
            <Box className={classes.fileSectionContainer}>
              <Box className={classes.fileInputBox}>
                <FileInput
                  // error={!formData.licenseUri && submitStatus === "attempted"}
                  onChange={handleFileChange}
                  inputName="license"
                  // labelText="Driver License"
                  helperText="Please upload a condition photo"
                  isComplete={!!imageUri}
                  // isLoading={isLicenseLoading}
                  required={true}
                  multiple
                  className={classes.fileInputComponent}
                  multiple="multiple"
                  style={{ display: "block", marginBottom: "18px" }}
                />
              </Box>
              {imageUris !== [""] &&
                imageUris.map(imageUri =>
                  <Box className={classes.reviewImageBox}>
                    <img
                      className={classes.reviewImage}
                      src={imageUri}
                      alt="damage"
                    />
                  </Box>
                  // {/* <Typography>{formData.licenseFilename}</Typography> */ }
                )
              }
            </Box>
          </Box>
        }
      </Fragment>
      {/* // set up state to show this only when radio is selected // add an email
      insurance button */}

      {/* should this card element just be hidden so the customer's data doesn't dissapear when they click to Proof of Insurance and then back */}
      {/* {formData.reserveMethod === "deposit" &&
          (!isPaymentSuccessful ? (
            <Fragment>
              <CardElement
                options={cardElementOpts}
                onChange={handleCardChange}
              />
              {!formData.isCardComplete && (
                <FormHelperText error={submitStatus === "attempted"}>
                  {!formData.cardError
                    ? "Please enter card details"
                    : formData.cardError}
                </FormHelperText>
              )}
            </Fragment>
          ) : (
            <Typography>Payment received, thank you!</Typography>
          ))} */}
      {/* <NextBarButton onClick={handleFormSubmit} buttonText={formData.isLicenseLoading ? "Uploading..." : "Continue"} type="submit" /> */}
      <Button onClick={event => {
        event.preventDefault();
        onSubmit(imageUris);
      }} variant="contained" color="primary" disableElevation disabled={imageUris === [""]} type="submit">Add Images</Button>
    </form >
  );
}
