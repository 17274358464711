import React, { Fragment } from "react";
import { Router } from "@reach/router";
import Lease from "./lease";
import Leases from "./leases";
import ReserveReview from "./reserve-review";
import ReserveDriver from "./reserve-driver";
import ReserveConfirm from "./reserve-confirm";
import LeaseLander from "./lease-lander";
import AboutUs from "./about-us";
import {
  PageContainer,
  NavBar,
  UpdateBar,
  ReserveBar,
  LinkFooter
} from "../components";
import EstimateInsurance from "./estimate-insurance";
import TaxJurisdiction from "./tax-jurisdiction";
import Dates from "./dates";
import Locations from "./locations";
import LeaseStatus from "./lease-status";
import Faq from "./faq";
import ContactUs from "./contact-us";
import ScrollToTop from "../helpers/scroll-to-top"
import InsuranceRequirements from "./insurance-requirements";
import PrivacyPolicy from "./privacy-policy";
import TermsOfUse from "./terms-of-use";
import ResumeLease from "./resume-lease";
import ConditionImages from "./condition-images";
import ConditionImagesMenu from "./condition-images-menu";
import CarAdmin from "./car-admin";
import AccountAdmin from "./account-admin";
import AccountsMenu from "./accounts-menu";
import HappyCustomers from "./happy-customers";
import WhyFlexLease from "./why-flex-lease";
import YoungDriver from "./young-driver";
import HowItWorks from "./how-it-works";

export default function Pages() {
  return (
    <Fragment>
      <Router primary={false} component={Fragment}>
        {/* change path or add another component  */}
        <NavBar path="/*" />
        <NavBar path="/" isHero={true} />
        {/* find a better way, problem with the default navigate(-1) is it's clearing client data */}
        <UpdateBar path="update/:pageName" />
        <ReserveBar path="reserve/:pageName" />
        <ReserveBar path="reserve/:pageName/*" />
      </Router>
      {/* not sure why we need the PageContainer - just spacing? */}
      <PageContainer>
        <Router primary={false} component={Fragment}>
          <ScrollToTop path="/">
            <ResumeLease path="resume/:quoteId" />
            <Lease path="lease" />
            <ReserveReview path="reserve/review/*" />
            <ReserveDriver path="reserve/driver/*" />
            <ReserveConfirm path="reserve/reserve" />
            <LeaseStatus path="lease-status/:skipsLeaseId" />
            <AccountAdmin path="account-admin/:skipsLeaseId" />
            <ConditionImagesMenu path="condition-images" />
            <ConditionImages path="condition-images/*" />
            <AccountsMenu path="accounts" subPath="account-admin" label="Account Admin" />
            <CarAdmin path="car-admin/*" />
            {/* <CarAdmin path="car-admin" /> */}
            <EstimateInsurance path="/update/estimate-insurance" />
            <Dates path="/update/dates" />
            <Locations path="/update/locations" />
            {/* are we still using thie? */}
            <TaxJurisdiction path="/update/tax-jurisdiction" />
            <Faq path="/faq/:questionLink" />
            <Faq path="/faq" />
            <AboutUs path="/about-us" />
            <ContactUs path="/contact-us" />
            <HowItWorks path="/how-it-works" />
            <WhyFlexLease path="/why-flex-lease" />
            <InsuranceRequirements path="/insurance-requirements" />
            <HappyCustomers path="/happy-customers" />
            <YoungDriver path="/young-driver" />
            <PrivacyPolicy path="/privacy-policy" />
            <TermsOfUse path="/terms-of-use" />
          </ScrollToTop>
        </Router>
      </PageContainer>
      {/* this extra page container is vausing the grey band */}
      <PageContainer isWide={true}>
        <Router primary={false} component={Fragment}>
          <ScrollToTop path="/">
            <Leases path="leases" />
            <Leases path="leases/*" />
          </ScrollToTop>
        </Router>
      </PageContainer>
      {/* hero pages - no PageContainer */}
      <Router primary={false} >
        <LeaseLander path="/" />
      </Router>
      <LinkFooter />

    </Fragment>
  );
}
