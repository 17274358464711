import React, { Fragment } from "react";
import { useNavigate } from "@reach/router";
import { useApolloClient, useQuery, useMutation } from "@apollo/react-hooks";
import {
  GET_VIEW,
  GET_CARS_VIEW_CUSTOMERHIGHLIGHTS,
} from "../pages/cars";
import { SORT_CARS } from "../components/cars-forms";
import CarImage from "./car-image";
import { priceFormat } from "../number-formats";
import { whetherInStockDiscount } from "../lease-total";
import { makeStyles, createMuiTheme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { ButtonBase } from "@material-ui/core";
import InfiniteScrollList from "./infinite-scroll-list";

// const { data, loading, error } = useQuery(GET_CARFORM, {
//   // fetchPolicy: "cache-and-network"
//   // variables: { customerStateVariable: "New Jersey" }
// });

// sortCars({
//   variables: {
//     cars: data.cars,
//     sortAttribute: data.sortAttribute,
//     isSortReverse: data.isSortReverse
//   }
// });

const defaultTheme = createMuiTheme();
const useStyles = makeStyles((theme) => ({
  quoteList: {
    // zIndex keeps the quotes above the footer until the end
    position: "relative",
    zIndex: 1,
  },
  buttonBox: { marginBottom: 8 },
  button: { width: "100%", display: "block", zIndex: 1 },
  priceTagImageBox: {
    width: "50%",
    display: "inline-block",
  },
  detailsBox: {
    display: "inline-block",
    width: "50%",
    verticalAlign: "top",
    [defaultTheme.breakpoints.up("md")]: {
      marginBottom: 12,
      padding: 10
    },
    [defaultTheme.breakpoints.down("sm")]: {
      padding: "6px 10px",

    },
  },
  subDetailsBox: {
    [defaultTheme.breakpoints.up("md")]: {
      marginBottom: 6,
    },
    [defaultTheme.breakpoints.down("sm")]: {
      marginBottom: 2,
    },
  },
  detailLine: {
    marginBottom: 4,
  },
  detailHeading: {
    [defaultTheme.breakpoints.up("md")]: {
      marginBottom: 4,
    },
    [defaultTheme.breakpoints.down("sm")]: {
      marginBottom: 2,
    },
  },
  discountedPrice: theme.discountedPrice,
  strikeoutPrice: theme.strikeoutPrice,
  promotionSubheading: theme.promotionSubheading,
  promotionIcon: theme.promotionIcon,
  categoryList: {
    marginTop: 0,
    marginBottom: 3,
    paddingLeft: 18
  },
  priceHighlight: {
    display: "inline-block",
    borderBottom: "4px solid #1bff00",
    lineHeight: "0.4",
    fontWeight: 500,
    [defaultTheme.breakpoints.down("sm")]: {
      fontSize: "1.4rem",
    },
  },
  priceSuperscript: {
    fontSize: 13,
    verticalAlign: "top",
    lineHeight: "0.2",
  },
  priceLabel: {
    // borderBottom: "unset",
    fontSize: 13,
    marginLeft: 3,
  },
  hidden: {
    visibility: "hidden",
  },
}));

// let isUnsorted = false;

export default function QuoteListInfinite({ isPricesLoading, isInfinite, sortAttribute, isSortReverse }) {
  const isUnsorted = React.useRef(true)
  // there's gotta be a better way to redirect
  const client = useApolloClient();
  const navigate = useNavigate();

  // const [scrolledViewCars, setScrolledViewCars] = React.useState([]);
  // const [hasMore, setHasMore] = React.useState(true);

  const classes = useStyles();

  const theme = useTheme();

  const isMediumMedia = useMediaQuery(theme.breakpoints.up("md"));

  const composeTransportDetails = (pickupTransferTotal, dropoffTransferTotal, carLocation, customerDropoffLocation) => {
    const pickupTransferLanguage = pickupTransferTotal !== 0 ? `$${pickupTransferTotal} pickup transfer from ${carLocation}` : ""
    const dropoffTransferLanguage = dropoffTransferTotal ? `$${dropoffTransferTotal} return transfer from ${customerDropoffLocation}` : ""

    if (!pickupTransferLanguage && !dropoffTransferLanguage) return

    return "Includes " + pickupTransferLanguage + (pickupTransferLanguage && dropoffTransferLanguage ? " and " : "") + dropoffTransferLanguage
  }

  // "no-cache" here prevented the component from showing the viewCars on load - perhaps the resolver is writing to cache
  const { data, loading, error } = useQuery(GET_CARS_VIEW_CUSTOMERHIGHLIGHTS);

  const handleClick = (car) => {
    client.writeData({
      data: {
        cartCar: car,
        customerPickupLocation: car.leaseQuote.pickupLocation,
        // idea here is to add the car vin and details here so it doesn't need to happen everytime there's inputs to the quote forms
        cartLease: {
          ...car.leaseQuote,
          customerPickupDate: data.customerPickupDate,
          // why's this commented?  because this handler only adds fields that have to do with the car and the customer-related fields are added elsewhere?
          // insuranceMonthly: data.insuranceMonthly,
          // insuranceAccuracy: data.insuranceAccuracy,
          // customerState: data.customerState,
          // stateAccuracy: data.stateAccuracy,
          // taxRate: data.customerTaxRate,
          // taxRateAccuracy: data.taxRateAccuracy,
          // vin: car.vin,
          // yearMakeModel: `${car.year} ${car.make} ${car.model}`,
        },
      },
    });
    // return <Redirect to="/lease" />;
    // setToLease(true);
    navigate("/lease");
  };

  // may need this later if adding sorting
  // not successful in actually getting the component to rerender when data.viewCars changes despite this effort and the "no-cache" policy on the query
  // may be solved in apollo client v3 but for now just adding the query back to leases.js
  const [sortCars] = useMutation(
    SORT_CARS, { refetchQueries: [{ query: GET_VIEW }], awaitRefetchQueries: true }
  );

  // if (toLease === true) {
  //   return <Redirect noThrow to="/lease" />;
  // }

  // why wouldn't this be a seperate component
  const CarListItem = ({ car }) => {
    const classes = useStyles();

    const isInStockDiscount = whetherInStockDiscount(car)

    return (
      <Box className={classes.buttonBox} key={car.vin}>
        <ButtonBase
          onClick={() =>
            // just pass the whole car object
            // this may be hurting performance, changes to car trigger rerender
            // the whole thing rerenders - car is a prop
            handleClick(car)
          }
          component={Box}
          className={classes.button}
        >
          <Box className={classes.priceTagImageBox}>
            <CarImage url={car.images[0]} isGradient={false} />
          </Box>
          <Box className={classes.detailsBox}>
            <Box className={isPricesLoading ? classes.hidden : undefined}>
              <Typography variant="h5" className={classes.priceHighlight}>
                <span className={classes.priceSuperscript}>$</span>
                {Math.round(
                  car.leaseQuote.leaseTotal /
                  Math.round(data.customerLeaseMonths * 30)
                )}
              </Typography>
              <span className={classes.priceLabel}>/day</span>
            </Box>
            <Box className={classes.subDetailsBox}>
              <Typography variant="body1" className={classes.detailHeading}>
                {car.year} {car.make} {car.model}
              </Typography>
              {
                isInStockDiscount ?
                  <Fragment>
                    <Typography variant="body2" className={classes.detailHeading}><Typography variant="body2" component="span" className={classes.strikeoutPrice}>{priceFormat(car.leaseQuote.leaseMonthly * 2)}</Typography> <Typography variant="body2" component="span" className={classes.discountedPrice}>{priceFormat(car.leaseQuote.leaseFirst)}</Typography> First Month, {priceFormat(car.leaseQuote.leaseMonthly)} monthly</Typography>
                    <Typography variant="body2" className={classes.promotionSubheading}><MonetizationOnIcon className={classes.promotionIcon} /> In-Stock Discount</Typography>
                  </Fragment>
                  :
                  <Typography variant="body2" className={classes.detailHeading}>{priceFormat(car.leaseQuote.leaseFirst) + " First Month, " + priceFormat(car.leaseQuote.leaseMonthly) + " monthly"}</Typography>
              }
            </Box>
            {/* not really the lease tota yet */}
            <Box className={classes.subDetailsBox}>
              <Typography
                variant="body2"
                className={
                  isPricesLoading
                    ? classes.detailLine + " " + classes.hidden
                    : classes.detailLine
                }
              >
                {priceFormat(car.leaseQuote.leaseTotal)} lease total
              </Typography>
              <Typography variant="body2" className={classes.detailLine}>
                Pickup in {car.leaseQuote.pickupLocation}
              </Typography>
              {((data.customerPickupLocations.length !== 0 &&
                car.leaseQuote.secondLegFee !== 0) || car.leaseQuote.dropoffLegFee !== 0) && (
                  <Typography
                    variant="body2"
                    className={isPricesLoading ? classes.detailLine + " " + classes.hidden : classes.detailLine}
                  >
                    {composeTransportDetails(car.leaseQuote.secondLegFee, car.leaseQuote.dropoffLegFee, car.location, data.customerDropoffLocation)}
                  </Typography>
                )}
              {isMediumMedia &&
                <ul className={classes.categoryList}>
                  <li>
                    <Typography variant="body2" className={classes.detailHeading}>{car.type.toLowerCase()}</Typography>
                  </li>
                  {car.size && <li><Typography variant="body2" className={classes.detailHeading}>{car.size.toLowerCase()} size</Typography></li>}
                </ul>}
            </Box>
          </Box>
        </ButtonBase>
      </Box>
    );
  }

  // viewCars is empty here

  if (loading || !data.cars) return "Loading...";
  if (error) return `Error! ${error.message}`;

  // const addCars = () => {
  //   if (scrolledViewCars.length >= data.viewCars.length) {
  //     setHasMore(false)
  //     return;
  //   }
  //   setScrolledViewCars(data.viewCars.slice(0, scrolledViewCars.length + 5));
  // }

  // don't seem to be using this, especially for quotes

  // const filters = data.filters;
  // const isFiltered = !Object.keys(filters).every(
  //   key =>
  //     key === "__typename" ||
  //     (integerAttributes.includes(key) &&
  //       (filters[key] === 999999 || filters[key] === MAXPRICE)) ||
  //     (Array.isArray(filters[key]) && filters[key].length === 0)
  // );

  // this seeds viewCars
  // can add sorting later

  if (isUnsorted.current) {
    if (data.isFiltersClear) {
      // no filters, sort data.cars
      // this is working but the component isn't responding for some reason
      // was getting some kind of error loop when passing data. to sortCars directly
      const cars = data.cars;
      // default to sorting by price
      // const sortAttribute = "leaseTotal";
      // const isSortReverse = false;
      // is this overrunning filters?
      // we go straight from cars to sorting
      console.log("sortCars")
      sortCars({
        variables: {
          cars,
          sortAttribute,
          isSortReverse,
        },
      });
    } else {
      // filters present, use viewCars
      console.log(data.isFiltersClear)
    }
    isUnsorted.current = false;
  }


  // this is from the old component
  // if (scrolledViewCars.length === 0 && data.viewCars.length > 0) {
  //   setScrolledViewCars(data.viewCars.slice(0, 5))
  // }

  console.log("quote-list-infinite ")
  console.log(data.viewCars[0])
  console.log(data.viewCars.map(car => car.leaseQuote.leaseTotal))
  // viewCars not updating here on state select

  return (
    <Box className={classes.quoteList}>
      {isInfinite ?
        // true or false, same blank viewCars problem
        <InfiniteScrollList viewCars={data.viewCars} ListItem={CarListItem} /> :
        data.viewCars.map(car => <CarListItem car={car} />)
      }
    </Box >
  );
}