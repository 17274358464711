import React from "react";
import { Link as RouterLink } from "@reach/router";
import pluralize from "pluralize"
import Box from "@material-ui/core/Box";
import DateRangeIcon from "@material-ui/icons/DateRange";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import format from "date-fns/format";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import { dateFormat } from "../dates";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import { InfoTooltip } from ".";


const useStyles = makeStyles((theme) => ({
  tooltip: { marginLeft: 3, verticalAlign: "middle", marginBottom: 3, color: theme.palette.text.primary },
  detailContainer: theme.detailContainer,
  detailIconBox: theme.detailIconBox,
  detailIcon: theme.detailIcon,
  detailBox: theme.detailBox,
  none: theme.none
}))

const DateDetail = ({ pickupDate, dropoffDate, customerLeaseMonths }) => {
  const classes = useStyles();

  const theme = useTheme();

  const isSmallMedia = useMediaQuery(theme.breakpoints.down("sm"));


  return (
    <Box className={classes.detailContainer}>
      <Box className={classes.detailIconBox}>

        <DateRangeIcon fontSize="small" className={classes.detailIcon} />
      </Box>
      <Box className={classes.detailBox}>

        <Typography>Pickup {isSmallMedia ? dateFormat(pickupDate) : <Link component={RouterLink} to="/update/dates">{dateFormat(pickupDate)}</Link>}</Typography>
        <Typography>Return {isSmallMedia ? dateFormat(dropoffDate) : <Link component={RouterLink} to="/update/dates">{dateFormat(dropoffDate)}</Link>}<InfoTooltip message="Our leases are flexible, don't worry if your return date changes." className={classes.tooltip} /></Typography>
        <Typography>
          {(customerLeaseMonths >= 1 ? Math.floor(customerLeaseMonths) +
            ` ${pluralize("months", Math.floor(customerLeaseMonths))} ` : "") +
            (customerLeaseMonths % 1 !== 0
              ? parseInt((customerLeaseMonths % 1) * 30) + " days"
              : "")}
        </Typography>
      </Box>
      <Box className={isSmallMedia ? "" : classes.none}>
        <IconButton component={RouterLink} to="/update/dates"><NavigateNextIcon /></IconButton>
      </Box>
      {/* <UpdateIconButton to="/update/dates" /> */}
    </Box>
  );
};

export default DateDetail;
