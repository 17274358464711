import React, { Fragment } from "react";
import { useQuery, useApolloClient, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Loading, PageHeading, FileInput } from "../components";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import TodayIcon from "@material-ui/icons/Today";
import CloseIcon from "@material-ui/icons/Close";
import { priceFormat } from "../number-formats";
import { orange } from "@material-ui/core/colors";
import {
  TextField,
  MenuItem,
  InputAdornment,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { dateOnly } from "../dates";
import DateFnsUtils from "@date-io/date-fns";
import Login from "./login";

// run this on localhost, the file's written to server/temp

const useStyles = makeStyles((theme) => ({
  sectionHeading: { paddingBottom: 3 },
  return: { paddingBottom: 3 },
  subeading: { fontWeight: 500, color: theme.headingGrey },
  pageSubeading: { marginBottom: 4 },
  tableBox: { maxWidth: 360, marginBottom: 6 },
  lastParagraph: { marginBottom: 10 },
  sectionLast: { marginBottom: 10 },
  openStatus: { color: orange[600] },
  odometerInput: { marginLeft: 12 },
  locationInput: { marginLeft: 12 },
  discountInput: { marginLeft: 12 },
  amountInput: { marginLeft: 12 },
  sectionBox: { marginBottom: 24 },
  formBox: { marginBottom: 18 },
  damageItemContainer: { display: "flex" },
  polaroidBox: { flexBasis: 180 },
  polaroidImage: {
    width: "100%",
    objectFit: "contain",
    objectPosition: "left",
    marginTop: 9,
  },
  DamageItemsForm: { marginBottom: 10 },
  mechanicalInputBox: { marginBottom: 10 },
  // theme imports
  mediumInput: theme.mediumInput,
  fileSectionContainer: theme.fileSectionContainer,
  fileInputComponent: theme.fileInputComponent,
  fileInputBox: theme.fileInputBox,
  reviewImage: theme.reviewImage,
  reviewImageBox: theme.reviewImageBox,
}));

export const GET_LEASEACCOUNT = gql`
  query leaseAccount($skipsLeaseId: ID!) {
    account(skipsLeaseId: $skipsLeaseId) {
      skipsLeaseId
      yearMakeModel
      startDate
      startOdometer
      monthlyMileageAllowance
      leaseCreditFactor
      startValue
      isForSale
      conditionImages
      salesTaxRate
      otherTaxRate
      payments {
        date
        status
        amount
      }
    }
    isLoggedIn @client
  }
`;

const SEND_LEASEEND_STATEMENT = gql`
  mutation sendLeaseendStatementEmail(
    $recipient: String!
    $skipsLeaseId: String!
    $leaseCloseout: LeaseCloseoutInput!
  ) {
    sendLeaseendStatementEmail(
      recipient: $recipient
      skipsLeaseId: $skipsLeaseId
      leaseCloseout: $leaseCloseout
    ) {
      success
      message
    }
  }
`;

const UPLOAD_FILE = gql`
  mutation uploadFile($file: Upload!, $label: String!) {
    uploadFile(file: $file, label: $label) {
      filename
      mimetype
      encoding
      uri
    }
  }
`;

const UPLOAD_FILESYSTEM = gql`
  mutation uploadFileSystem($file: Upload!, $label: String) {
    uploadFileSystem(file: $file, label: $label) {
      filename
      mimetype
      encoding
      uri
    }
  }
`;
// issue where the file ends up empty.  maybe with the update to node.

function DamageItemsForm({
  label,
  onSubmit,
  onFileChange,
  imageCount,
  ...other
}) {
  const damageDescriptions = [
    {
      value: "ding",
      label: "ding",
      charge: 100,
    },
    {
      value: "scratch",
      label: "scratch",
      charge: 30,
    },
    {
      value: "scrape",
      label: "scrape",
      charge: 160,
    },
    {
      value: "gouge",
      label: "gouge",
      charge: 200,
    },
    {
      value: "crack",
      label: "crack",
      charge: 80,
    },
    {
      value: "hole",
      label: "hole",
      charge: 260,
    },
  ];

  const damageLocations = [
    {
      value: "left_fender",
      label: "left fender",
    },
    {
      value: "right_fender",
      label: "right fender",
    },
    {
      value: "left_front_door",
      label: "left front door",
    },
    {
      value: "right_front_door",
      label: "right front door",
    },
    {
      value: "left_rear_door",
      label: "left rear door",
    },
    {
      value: "right_rear_door",
      label: "right rear door",
    },
    {
      value: "left_quarter_panel",
      label: "left quarter panel",
    },
    {
      value: "right_quarter_panel",
      label: "right quarter panel",
    },
    {
      value: "trunk_tailgate_liftgate",
      label: "trunk/tailgate/liftgate",
    },
    {
      value: "rear_bumper",
      label: "rear bumper",
    },
    {
      value: "front_bumper",
      label: "front bumper",
    },
    {
      value: "roof",
      label: "roof",
    },
    {
      value: "windshield",
      label: "windshield",
    },
    {
      value: "front_seat",
      label: "front seat",
    },
    {
      value: "rear_seat",
      label: "rear seat",
    },
  ];

  const classes = useStyles();

  const [imageUri, setImageUri] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [amount, setAmount] = React.useState(0);
  const [isForgiven, setIsForgiven] = React.useState(false);

  // const imageCount = React.useRef(0);

  console.log("imageCount ", imageCount);

  const [upload, { loading: uploadLoading, error: uploadError }] =
    useMutation(UPLOAD_FILE);

  const [
    uploadFilesystem,
    { loading: uploadFilesystemLoading, error: uploadFilesystemError },
  ] = useMutation(UPLOAD_FILESYSTEM, {
    onCompleted(data) {
      console.log(data);
    },
  });

  // const uploadImageSet = async (file, label) => {
  //   const { data } = await upload({
  //     variables: {
  //       file,
  //       label
  //     },
  //   });
  //   console.log(data.uploadFile.uri)
  //   setImageUri(data.uploadFile.uri)
  // }

  const handleFileChangeOld = async ({ target: { validity, files, name } }) => {
    // or set some kind of error state
    if (!validity.valid) return;
    console.log("files ", files);
    if (!files) return;
    console.log("file change");
    console.log(file);
    const file = files[0];
    const { data } = await upload({
      variables: {
        file,
        label,
      },
    });
    setImageUri(data.uploadFile.uri);
  };

  const handleFileChange = async ({ target: { validity, files, name } }) => {
    // or set some kind of error state
    if (!validity.valid) return;
    onFileChange();
    console.log("filesystem file change");
    console.log("imageCount ", imageCount);
    // imageCount.current = imageCount.current + 1
    const file = files[0];
    console.log(file);
    const { data, uplo } = await uploadFilesystem({
      variables: {
        file,
        label: "damage-" + imageCount,
      },
    });
    console.log(uploadFilesystemError);
    console.log(data.uploadFilesystem.uri);
    // setImageUri(data.uploadFile.uri)
    // console.log("setImageCount ", imageCount + 1)
  };

  return (
    <Box {...other}>
      <Box className={classes.fileSectionContainer}>
        <Box className={classes.fileInputBox}>
          <FileInput
            // maybe this throws when the page resets
            onChange={handleFileChange}
            inputName="minor damage"
            helperText="Please upload a photo"
            isComplete={!!imageUri}
            required={true}
            className={classes.fileInputComponent}
          />
        </Box>
        <Box className={classes.reviewImageBox}>
          <img className={classes.reviewImage} src={imageUri} alt={label} />
          <Typography>{imageCount} images loaded to server</Typography>
        </Box>
      </Box>
      <Box className={classes.formBox}>
        <TextField
          select
          value={description}
          onChange={(event) => {
            setDescription(event.target.value);
            setAmount(
              damageDescriptions.find(
                (description) => description.value == event.target.value
              ).charge
            );
          }}
          className={classes.mediumInput}
          label="description"
        >
          {damageDescriptions.map((option, index) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          onChange={(event) => setLocation(event.target.value)}
          className={classes.mediumInput + " " + classes.locationInput}
          label="location"
        >
          {damageLocations.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Typography>{priceFormat(amount)}</Typography>
        <Checkbox
          label="forgiven"
          onClick={(event) => setIsForgiven(event.target.checked)}
        />
      </Box>
      <Button
        onClick={(event) => {
          setDescription("");
          onSubmit({ imageUri, description, location, amount, isForgiven });
        }}
      >
        Add Wear Item
      </Button>
    </Box>
  );
}

export default function AccountAdmin({ skipsLeaseId }) {
  const classes = useStyles();

  const client = useApolloClient();

  const [leaseendDate, setLeasendDate] = React.useState(
    dateOnly(new Date()).toISOString()
  );
  const [endOdometer, setEndOdometer] = React.useState(0);
  const [excessMileageDiscount, setExcessMileageDiscount] = React.useState(0);
  const [excessWearItems, setExcessWearItems] = React.useState([]);
  const [excessWearDiscount, setExcessWearDiscount] = React.useState(0);
  const [accidentDamageAmount, setAccidentDamageAmount] = React.useState(0);
  const [accidentDamageDiscount, setAccidentDamageDiscount] = React.useState(0);
  const [mechanicalDamages, setMechanicalDamages] = React.useState([]);
  const [mechanicalDamageDiscount, setMechanicalDamageDiscount] =
    React.useState(0);
  const [mechanicalDamageDescription, setMechanicalDamageDescription] =
    React.useState("");
  const [mechanicalDamageAmount, setMechanicalDamageAmount] = React.useState(0);
  const [missingAccessories, setMissingAccessories] = React.useState([]);
  const [missingAccessoriesDescription, setMissingAccessoriesDescription] =
    React.useState("");
  const [missingAccessoriesAmount, setMissingAccessoriesAmount] =
    React.useState(0);
  const [missingAccessoriesDiscount, setMissingAccessoriesDiscount] =
    React.useState(0);

  const [imageCount, setImageCount] = React.useState(0);

  const handleLeaseendDateChange = (date) => {
    const strippedDate = dateOnly(date);
    setLeasendDate(strippedDate);
  };

  const [sendStatement, { loading: sendLoading, error: sendError }] =
    useMutation(SEND_LEASEEND_STATEMENT);

  const { data, loading, error } = useQuery(GET_LEASEACCOUNT, {
    variables: { skipsLeaseId },
    // fetchPolicy: "network-only",
  });

  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;
  if (!data.isLoggedIn) return <Login />;

  console.log("data");
  console.log(data);

  return (
    <Fragment>
      <PageHeading>Account Admin</PageHeading>
      <Typography variang="h4">Create Lease-End Statement</Typography>
      <Typography variant="h6" className={classes.pageSubeading}>
        {data.account.yearMakeModel}
      </Typography>
      <Typography variant="h6" className={classes.pageSubeading}>
        {data.account.skipsLeaseId}
      </Typography>
      <Box className={classes.sectionBox}>
        <Typography variant="h5">Basics</Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            label="Lease end date"
            // maxDate={dropoffDate}
            // this should update the customerPickupDate field
            onChange={handleLeaseendDateChange}
            autoOk={true}
            keyboardIcon={<TodayIcon />}
          />
        </MuiPickersUtilsProvider>
        <TextField
          label="End odometer"
          type="number"
          onChange={(event) => setEndOdometer(parseInt(event.target.value))}
          className={classes.odometerInput}
        />
      </Box>
      <Box className={classes.sectionBox}>
        <Typography variant="h5">Excess Mileage</Typography>
        <TextField
          label="discount"
          type="number"
          onChange={(event) =>
            setExcessMileageDiscount(parseInt(event.target.value))
          }
          inputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            min: 0,
            max: 100,
            step: 10,
          }}
        />
      </Box>
      <Box className={classes.sectionBox}>
        <Typography variant="h5">Excessive Wear</Typography>
        <DamageItemsForm
          label="excess-wear-item"
          onSubmit={(damageDescription) =>
            setExcessWearItems([...excessWearItems, damageDescription])
          }
          imageCount={imageCount}
          onFileChange={() => setImageCount(imageCount + 1)}
          className={classes.polaroidForm}
        />
        <Box className={classes.damageItemContainer}>
          {excessWearItems.map((item, index) => (
            <Box className={classes.itemBox}>
              {/* <img className={classes.itemImage} src={item.imageUri} /> */}
              <Typography>{item.description}</Typography>
              <Typography>{item.location}</Typography>
              {item.isForgiven ? (
                <Typography className={classes.green}>forgiven</Typography>
              ) : (
                <Typography>{priceFormat(item.amount)}</Typography>
              )}
              <Checkbox
                checked={item.isForgiven}
                onClick={(event) =>
                  setExcessWearItems([
                    ...excessWearItems.slice(0, index),
                    {
                      ...excessWearItems[index],
                      isForgiven: event.target.checked,
                    },
                    ...excessWearItems.slice(index + 1),
                  ])
                }
                label={"forgiven"}
              />
              <Button
                onClick={(event) =>
                  setExcessWearItems([
                    ...excessWearItems.slice(0, index),
                    ...excessWearItems.slice(index + 1),
                  ])
                }
              >
                Delete
              </Button>
            </Box>
          ))}
        </Box>
        <TextField
          label="discount"
          type="number"
          onChange={(event) =>
            setExcessWearDiscount(parseInt(event.target.value))
          }
          value={excessWearDiscount}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          inputProps={{ min: "0", max: "100", step: "10" }}
        />
      </Box>
      <Box className={classes.sectionBox}>
        <Typography variant="h5">Accident Damage</Typography>
        <TextField
          label="amount"
          type="number"
          onChange={(event) =>
            setAccidentDamageAmount(parseInt(event.target.value))
          }
          value={accidentDamageAmount}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <TextField
          label="discount"
          type="number"
          className={classes.discountInput}
          onChange={(event) =>
            setAccidentDamageDiscount(parseInt(event.target.value))
          }
          value={accidentDamageDiscount}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          inputProps={{ min: "0", max: "100", step: "10" }}
        />
      </Box>
      <Box className={classes.sectionBox}>
        <Typography variant="h5">Mechanical Damage</Typography>
        <Box className={classes.mechanicalInputBox}>
          <Box className={classes.formBox}>
            <TextField
              label="description"
              onChange={(event) => {
                console.log(event.target.value);
                setMechanicalDamageDescription(event.target.value);
              }}
              value={mechanicalDamageDescription}
            />
            <TextField
              label="amount"
              type="number"
              className={classes.amountInput}
              onChange={(event) =>
                setMechanicalDamageAmount(parseInt(event.target.value))
              }
              value={mechanicalDamageAmount}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Box>
          <Button
            onClick={(event) => {
              setMechanicalDamages([
                ...mechanicalDamages,
                {
                  description: mechanicalDamageDescription,
                  amount: mechanicalDamageAmount,
                },
              ]);
              setMechanicalDamageDescription("");
              setMechanicalDamageAmount(0);
            }}
          >
            Add Mechanical Damage
          </Button>
        </Box>
        <Box>
          {mechanicalDamages.length !== 0 && (
            <Table size="small">
              <TableBody>
                {mechanicalDamages.map((damageObject, index) => (
                  <TableRow>
                    <TableCell>{damageObject.description}</TableCell>
                    <TableCell>{priceFormat(damageObject.amount)}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() =>
                          setMechanicalDamages([
                            ...mechanicalDamages.slice(0, index),
                            ...mechanicalDamages.slice(index + 1),
                          ])
                        }
                      >
                        <CloseIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
        <Box>
          <TextField
            label="discount"
            type="number"
            onChange={(event) =>
              setMechanicalDamageDiscount(parseInt(event.target.value))
            }
            value={mechanicalDamageDiscount}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            inputProps={{ min: "0", max: "100", step: "10" }}
          />
        </Box>
      </Box>
      <Box className={classes.sectionBox}>
        <Typography variant="h5">Missing Accessories</Typography>
        <Box className={classes.mechanicalInputBox}>
          <Box className={classes.formBox}>
            <TextField
              label="description"
              onChange={(event) => {
                // switch to autocomplete select with freeform
                setMissingAccessoriesDescription(event.target.value);
                setMissingAccessoriesAmount();
              }}
              value={missingAccessoriesDescription}
            />
            <TextField
              label="amount"
              type="number"
              className={classes.amountInput}
              onChange={(event) =>
                setMissingAccessoriesAmount(parseInt(event.target.value))
              }
              value={missingAccessoriesAmount}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Box>
          <Button
            onClick={(event) => {
              setMissingAccessories([
                ...missingAccessories,
                {
                  description: missingAccessoriesDescription,
                  amount: missingAccessoriesAmount,
                },
              ]);
              setMissingAccessoriesDescription("");
              setMissingAccessoriesAmount(0);
            }}
          >
            Add Missing Accessory
          </Button>
          <Box>
            <TextField
              label="discount"
              type="number"
              className={classes.discountInput}
              onChange={(event) =>
                setMissingAccessoriesDiscount(parseInt(event.target.value))
              }
              value={missingAccessoriesDiscount}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              inputProps={{ min: "0", max: "100", step: "10" }}
            />
          </Box>
        </Box>
        <Box>
          {missingAccessories.length !== 0 && (
            <Table size="small">
              <TableBody>
                {missingAccessories.map((damageObject, index) => (
                  <TableRow>
                    <TableCell>{damageObject.description}</TableCell>
                    <TableCell>{priceFormat(damageObject.amount)}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() =>
                          setMissingAccessories([
                            ...missingAccessories.slice(0, index),
                            ...missingAccessories.slice(index + 1),
                          ])
                        }
                      >
                        <CloseIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </Box>
      <Button
        onClick={(event) => {
          console.log(leaseendDate);
          console.log(imageCount);
          const leaseCloseout = {
            endDate: leaseendDate,
            endOdometer,
            excessMileageDiscount,
            imageCount,
            excessWearDiscount,
            excessWearItems,
            accidentDamageAmount,
            accidentDamageDiscount,
            mechanicalDamages,
            mechanicalDamageDiscount,
            missingAccessories,
            missingAccessoriesDiscount,
          };
          const response = sendStatement({
            variables: {
              recipient: "max@driveskips.com",
              skipsLeaseId,
              leaseCloseout,
            },
          });
        }}
        variant="contained"
        disableElevation
        color="primary"
      >
        Send Statement
      </Button>
    </Fragment>
  );
}
