import React from "react";
import { Link as RouterLink } from "@reach/router";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import Box from "@material-ui/core/Box";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  detailContainer: theme.detailContainer,
  detailIconBox: theme.detailIconBox,
  detailIcon: theme.detailIcon,
  detailBox: theme.detailBox,
  none: theme.none
}))

const LocationDetail = ({ pickupLocation, dropoffLocation }) => {
  const classes = useStyles();

  const theme = useTheme();

  const isSmallMedia = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box className={classes.detailContainer}>
      <Box className={classes.detailIconBox}>
        {/* how about a &$ kind of double locatoin paddle to indicate that the return is different */}
        <LocationOnIcon fontSize="small" className={classes.detailIcon} />
      </Box>
      <Box className={classes.detailBox}>
        <Typography>Pickup in {isSmallMedia ? pickupLocation : <Link component={RouterLink} to="/update/locations">{pickupLocation}</Link>}</Typography>
        <Typography>Return in {isSmallMedia ? dropoffLocation : <Link component={RouterLink} to="/update/locations">{dropoffLocation}</Link>}</Typography>
      </Box>
      <Box className={isSmallMedia ? "" : classes.none}>
        <IconButton component={RouterLink} to="/update/locations"><NavigateNextIcon /></IconButton>
      </Box>
    </Box>
  );
};

export default LocationDetail;
