import React, { Fragment } from "react";
import { useQuery, useApolloClient } from "@apollo/react-hooks";
import gql from "graphql-tag";

import {
  Loading,
  CarsForms,
  CustomerForm,
  CarList,
} from "../components";

// export const GET_CARS = gql`
//   query carList {
//     cars {
//       make
//       model
//       vin
//       deliveryDays @client
//     }
//   }
// `;

// export const GET_VIEW = gql`
//   query getViewCars {
//     viewCars @client {
//       make
//       model
//       vin
//       supplier
//       # deliveryDays
//     }
//   }
// `;

export const LEASE_DATA = gql`
  fragment LeaseData on Lease {
    __typename
    pickupLocation
    dropoffLocation
    transportCost
    secondLegFee
    dropoffLegFee
    leaseTotal
    firstMonthSalesTax
    firstMonthRentalTax
    monthlySalesTax
    monthlyRentalTax
    proration
    dropoffLegSalesTax
    dropoffLegRentalTax
    paymentsTotal
    excessMileageDollars
    leaseFirst
    leaseMonthly
    customerPickupDate
    customerLeaseMonths
    vin
    yearMakeModel
    insuranceMonthly
    insuranceAccuracy
    customerTaxRate
    taxRateAccuracy
    customerState
    stateAccuracy
    createdDate
  }
`;

export const CAR_DATA = gql`
  fragment CarData on Car {
    __typename
    year
    make
    model
    trim
    makeModel
    vin
    mileage
    type
    size
    drivetrain
    retailPrice
    leaseFirst
    leaseMonthly
    transportCost
    exteriorColor
    interiorColor
    supplier
    location
    transportTimeline
    titleBizDays
    images
    deliveryDays @client
    isInStockDiscount @client
    leaseQuote @client {
      ...LeaseData
    }
  }
  ${LEASE_DATA}
`;

// if modifying, these fields are also present in other queries with filers {..
export const FILTERS_DATA = gql`
  fragment FiltersData on Filter {
    make
    makeModel
    mileage
    type
    size
    drivetrain
    retailPrice
    exteriorColor
    # interiorColor
    # leaseTotal
    leaseFirst
    leaseMonthly
    isInStockDiscount
  }
`;

export const GET_CARS_VIEW = gql`
  query getCarsViewCars {
    cars {
      ...CarData
    }
    viewCars @client {
      ...CarData
    }
  }
  ${CAR_DATA}
`;

export const GET_CARS = gql`
  query getCarsCars {
    cars {
      ...CarData
    }
  }
  ${CAR_DATA}
`;

export const GET_CAR = gql`
  query car($vin: String!) {
    car(vin: $vin) {
      ...CarData
    }
  }
  ${CAR_DATA}
`;

export const GET_CAR_ADMIN = gql`
  query car($vin: String!) {
    car(vin: $vin) {
      ...CarData
    }
    isLoggedIn @client
  }
  ${CAR_DATA}
`;

export const GET_VIEW = gql`
  query getCarsViewCars {
    viewCars @client {
      ...CarData
    }
  }
  ${CAR_DATA}
`;

// export const GET_CARS_LEASETOTAL = gql`
//   query getCarsLeasetotal {
//     cars {
//       vin
//       leaseFirst
//       leaseMonthly
//       location
//       transportCost
//       leaseTotal @client
//     }
//     customerPickupLocations @client
//   }
// `;

export const GET_CARS_LEASEQUOTE = gql`
  query getCarsLeasetotal {
    cars {
      vin
      leaseFirst
      leaseMonthly
      location
      transportCost
      year
      make
      model
      # need retailPrice for leaseQuote.excessMileageDollars
      retailPrice
      # tried this to fix undefined viewCars in dates
      # deliveryDays @client
      leaseQuote @client {
        ...LeaseData
      }
    }
    customerPickupLocations @client
    customerDropoffLocation @client
  }
  ${LEASE_DATA}
`;

export const GET_CARSLOCATION = gql`
query getCarsLocation {
  cars {
    location
  }
}
`;

export const GET_VIEW_LEASETOTAL = gql`
query getCarsLeasetotal {
  viewCars @client {
    leaseTotal
  }
}
`;

export const GET_VIEW_LEASEQUOTE = gql`
query getCarsLeasetotal {
  viewCars @client {
    leaseQuote {
        ...LeaseData
    }
  }
}
${LEASE_DATA}
`;

export const GET_VIEW_LEASETOTALQUOTE = gql`
query getCarsLeasetotal {
  viewCars @client {
    leaseTotal
    leaseQuote {
      secondLegFee
      pickupLocation
    }
  }
}
`;

export const GET_FILTERS = gql`
query getFilters @client {
  filters @client {
      ...FiltersData
  }
}
${FILTERS_DATA}
`;

export const GET_SORT_CARS = gql`
query getSortAndViewCars {
  cars {
      ...CarData
  }
  sortAttribute @client
  isSortReverse @client
  customerPickupDate @client
}
${CAR_DATA}
`;

export const GET_SORT_VIEW = gql`
query getSortAndViewCars {
  viewCars @client {
      ...CarData
  }
  sortAttribute @client
  isSortReverse @client
  isFiltered @client
}
${CAR_DATA}
`;

export const GET_SORT_VIEW_FILTERS = gql`
query getSortAndViewCarsAndFilters {
  viewCars @client {
      ...CarData
  }
  sortAttribute @client
  isSortReverse @client
  isFiltered @client
  isFiltersClear @client
  filters @client {
    make
    makeModel
    mileage
    type
    size
    drivetrain
    retailPrice
    exteriorColor
      # interiorColor
      # leaseTotal
    leaseFirst
    leaseMonthly
    isInStockDiscount
  }
}
${CAR_DATA}
`;

export const GET_SORT = gql`
query getSortAndViewCars {
  sortAttribute @client
  isSortReverse @client
}
`;

// export const GET_CARFORM_VARIABLE = gql`
//   query getCarsAndForm($customerState: String!) {
//     cars {
//       make
//       model
//       vin
//       supplier
//       deliveryDays(customerStateVarible: $customerState) @client
//     }
//     viewCars @client {
//       make
//       model
//       vin
//       supplier
//       # deliveryDays
//     }
//     isAlphabetical @client
//     searchText @client
//     customerState @client
//     filters @client {
//       makeModel
//     }
//     searchMakes @client
//     searchMakeModels @client
//   }
// `;

export const GET_CARFORM = gql`
  query getCarsAndForm {
    cars {
      ...CarData
    }
    viewCars @client {
      ...CarData
    }
    sortAttribute @client
    isSortReverse @client
    isFilterView @client
    searchText @client
    customerState @client
    filters @client {
      # ...FiltersData
      # hard coding filter instead of setting up
      make
      makeModel
      mileage
      type
      size
      drivetrain
      retailPrice
      exteriorColor
      interiorColor
      # leaseTotal
      leaseFirst
      leaseMonthly
      isInStockDiscount
    }
    isFiltered @client
    searchMakes @client
    searchMakeModels @client
    viewField @client
  }
  ${CAR_DATA}
`;

export const GET_FILTERS_VIEW = gql`
  query getFiltersView {
    # trimming back cars
    # had to revert because writing to GET_SORT_VIEW in sortCars resolver was throwing a missing field error
    # for all the other leaseQuote fields
    cars {
      ...CarData
    }
    viewCars @client {
      ...CarData
    }
    # sortAttribute @client
    # isSortReverse @client
    # isFilterView @client
    # searchText @client
    # customerState @client
    filters @client {
      # ...FiltersData
      # hard coding filter instead of setting up
      make
      makeModel
      mileage
      type
      size
      drivetrain
      retailPrice
      exteriorColor
      # interiorColor
      # leaseTotal
      leaseFirst
      leaseMonthly
      isInStockDiscount
    }
    viewField @client
    customerPickupDate @client
  }
  ${CAR_DATA}
`;

export const GET_FILTERS_CARS = gql`
  query getFiltersView {
    # trimming back cars
    # had to revert because writing to GET_SORT_VIEW in sortCars resolver was throwing a missing field error
    # for all the other leaseQuote fields
    cars {
      ...CarData
    }
    # sortAttribute @client
    # isSortReverse @client
    # isFilterView @client
    # searchText @client
    # customerState @client
    filters @client {
      # ...FiltersData
      # hard coding filter instead of setting up
      make
      makeModel
      mileage
      type
      size
      drivetrain
      retailPrice
      exteriorColor
      # interiorColor
      # leaseTotal
      leaseFirst
      leaseMonthly
      isInStockDiscount
    }
    customerLeaseMonths @client
    customerPickupDate @client
    # here for QuickQuote click handler in lease-lander
  }
  ${CAR_DATA}
`;

export const GET_CUSTOMER = gql`
  query getCustomer {
    customerState @client
    customerZip @client
    customerCounty @client
    customerTaxRate @client
    taxRateAccuracy @client
    isZipDialog @client
    customerEmail @client
    customerPickupLocation @client
    customerPickupLocations @client
    customerDropoffLocation @client
    customerPickupDate @client
    customerLeaseMonths @client
    cartVin @client
    cartLease @client {
      ...LeaseData
    }
    cartCar @client {
      vin
      year
      make
      model
      trim
      drivetrain
      type
      mileage
      leaseFirst
      leaseMonthly
      # excessMileageDollars
      location
      images
      deliveryDays
    }
    insuranceMonthly @client
    insuranceAccuracy @client
    stateAccuracy @client
  }
  ${LEASE_DATA}
`;

export const GET_DRIVER_CARTLEASE = gql`
  query getDriver {
    customerEmail @client
    customerPhone @client
    customerLicenseUri @client
    cartLease @client {
      ...LeaseData
    }
  }
  ${LEASE_DATA}
`;

export const GET_CAR_CUSTOMER = gql`
  query GetCarByVinCustomer($vin: String!) {
    car(vin: $vin) {
      make
      model
      vin
      type
      retailPrice
    }
    customerState @client
    customerZip @client
    customerCounty @client
    customerTaxRate @client
    isZipDialog @client
    customerEmail @client
    customerPickupLocation @client
    customerPickupLocations @client
    customerDropoffLocation @client
    customerPickupDate @client
    customerLeaseMonths @client
  }
`;

export const GET_CARS_CUSTOMER = gql`
  query getCarsCustomer {
    cars {
      ...CarData
    }
    customerState @client
    customerZip @client
    customerCounty @client
    customerTaxRate @client
    isZipDialog @client
    customerEmail @client
    customerPickupLocation @client
    customerPickupLocations @client
    customerPickupDate @client
    customerLeaseMonths @client
    viewField @client
  }
  # ${CAR_DATA}
`;

export const GET_CARS_VIEW_CUSTOMER = gql`
  query getCarsCustomer {
    cars {
      ...CarData
    }
    viewCars @client {
      ...CarData
    }
    customerState @client
    customerZip @client
    customerCounty @client
    customerTaxRate @client
    isZipDialog @client
    customerEmail @client
    customerPickupLocation @client
    customerPickupLocations @client
    pickupText @client
    dropoffText @client
    customerDropoffLocation @client
    customerPickupDate @client
    customerLeaseMonths @client
    viewField @client
    isShowMore @client
    customerCoordinates @client {
      lat
      lng
    }
  }
  ${CAR_DATA}
`;

export const GET_VIEW_CUSTOMERTAX_CARTLEASEVIN = gql`
  query getCarsCustomer {
    viewCars @client {
      ...CarData
    }
    customerState @client
    customerCounty @client
    customerTaxRate @client
    taxRateAccuracy @client
    cartLease @client {
      vin
    }
  }
  ${CAR_DATA}
`;

export const GET_CARS_VIEW_CUSTOMER_FILTERS_SORT = gql`
  query getCarsCustomer {
    cars {
      ...CarData
    }
    viewCars @client {
      ...CarData
    }
    customerPickupDate @client
    customerLeaseMonths @client
    sortAttribute @client
    isSortReverse @client
    filters @client {
      make
      makeModel
      mileage
      type
      size
      drivetrain
      retailPrice
      exteriorColor
      # interiorColor
      # leaseTotal
      leaseFirst
      leaseMonthly
      isInStockDiscount
    }
  }
  ${CAR_DATA}
`;

export const GET_CARS_VIEW_CUSTOMERHIGHLIGHTS = gql`
  query getCarsCustomer {
    cars {
      ...CarData
    }
    viewCars @client {
      ...CarData
    }
    customerPickupDate @client
    customerLeaseMonths @client
    customerDropoffLocation @client
    customerPickupLocations @client
    isFiltersClear @client
  }
  ${CAR_DATA}
`;

export const GET_SEARCHTEXT = gql`
  query getSearchText {
    searchText @client
  }
`;

export const GET_SEARCH_RESULTS = gql`
  query getSearchResults {
    searchText @client
    searchMakeModels @client
  }
`;

export const LOAD_LEASEQUOTE = gql`
  query loadLeaseQuote($quoteId: String!) {
    leaseQuote(quoteId: $quoteId) {
      ...LeaseData
    }
  }
  ${LEASE_DATA}
`;

export const GET_LEASEQUOTE = gql`
  query getLeaseQuote {
    leaseQuote @client {
      ...LeaseData
    }
  }
  ${LEASE_DATA}
`;

export const LOAD_LEASEQUOTE_CARS = gql`
  query loadLeaseQuoteCars($quoteId: String!) {
    leaseQuote(quoteId: $quoteId) {
      ...LeaseData
    }
    cars {
      __typename
      year
      make
      model
      trim
      makeModel
      vin
      mileage
      type
      size
      drivetrain
      retailPrice
      leaseFirst
      leaseMonthly
      transportCost
      exteriorColor
      interiorColor
      supplier
      location
      transportTimeline
      titleBizDays
      images
      deliveryDays @client
      isInStockDiscount @client
      leaseQuote @client {
        ...LeaseData
      }
    }
  }
  ${LEASE_DATA}
`;

// export const SORT_CARS = gql`
//   mutation sortCars($isAlphabetical: Boolean!, $cars: Array!) {
//     sortCars(isAlphabetical: $isAlphabetical, cars: $cars) @client
//   }
// `;

// function IsAlphabetical() {
//   const { data } = useQuery(GET_FILTERS);
//   return data.IsAlphabetical ? <Pages /> : <Login />;
// }

export default function Cars() {
  const { data, loading, error } = useQuery(GET_CARFORM);
  if (loading) return <Loading />;
  if (error) return <p>ERROR</p>;
  return (
    <Fragment>
      <CustomerForm />
      <CarsForms />
      {/* having chipset at this level prevents CarsForms from passing the handleMakeFilterChange handler to this component */}
      {/* {!data.isFilterView && <ChipSet />} */}
      <CarList />
    </Fragment>
  );
}
