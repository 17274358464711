import React, { Fragment } from "react";
import { Link as RouterLink } from "@reach/router";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  logo: {
    fontFamily: "Bangers, Roboto",
    color: theme.skipsYellow,
    fontWeight: 700,
    letterSpacing: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: 22
    },
    [theme.breakpoints.up("md")]: {
      fontSize: desktopFontSize => desktopFontSize
    }
  },
  logoBox: {
    pointerEvents: "none",
    display: "inline"
  }
}));

export default function Logo({ desktopFontSize = 22, className, to, ...other }) {
  const classes = useStyles(desktopFontSize);
  return <Box className={classes.logoBox + " " + className} {...other}><h1 className={classes.logo}>SKIP'S</h1></Box>
}