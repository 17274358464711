import React, { Fragment } from "react";
// import { Router, Link as RouterLink } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import PageHeading from "../components/page-heading";
import { Link as RouterLink } from "@reach/router";
import { Typography, Box, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  questionParagraph: { fontWeight: 500 },
  youngButton: {
    float: "right",
  },


  narrowPageBox: theme.narrowPageBox,
  subsectionEndBox: theme.subsectionEndBox,

}));

export default function YoungDriver({ ...other }) {
  const classes = useStyles();

  return (
    <Fragment>
      <Box className={classes.narrowPageBox}>
        <Box className={classes.subsectionEndBox}>
          <PageHeading isHeadline={true}>Leasing a car as a young driver</PageHeading>
          <Typography paragraph={true}>Young drivers are welcome to lease with Skip's.</Typography>
          <Typography paragraph={true}>One question we hear often is "Can I lease a car if I'm under 25?"  The answer is "Yes, absolutely."  We don't impose any age restrictions.</Typography>
          <Typography paragraph={true}>You're welcome to lease a car as long as you have a valid driver license.  Don't forget you'll need an insurance policy as well.</Typography>
          <Typography paragraph={true}>We do not add any fees for young drivers.</Typography>
        </Box>
        <Button
          className={classes.youngButton}
          component={RouterLink}
          variant="contained"
          color="primary"
          disableElevation={true}
          to="/leases"
        >
          Find a flex lease
              </Button>
      </Box>
    </Fragment >
  );
}
