import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles((theme) => ({
  headingBox: {
    paddingBottom: isHeadline => isHeadline ? 12 : 8,
  },
  pageHeading: {
    fontWeight: 500,
    color: grey[700],
  },
}));

export default function PageHeading({ subheading, children, isHeadline = false, className }) {
  const classes = useStyles(isHeadline);
  return (
    <Box className={className ? className + " " + classes.headingBox : classes.headingBox}>
      <Typography variant="h5" className={classes.pageHeading}>
        {children}
      </Typography>
      {subheading && <Typography variant="subtitle1">{subheading}</Typography>}
    </Box>
  );
}
