import React from "react";
import {
  useApolloClient,
  useQuery,
} from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Loading, ChooseLease } from "../components";
import { makeStyles } from "@material-ui/core/styles";
import QuoteLocationForm from "../components/quote-location-form";
import { Box } from "@material-ui/core";


// creating a bug, should this be a piece of state
let cartVin = null
let cartLocation = null

export const GET_CARTVIN_CARTCARLOCATION = gql`
  query getCartvin {
    cartLease {
      vin
    }
    cartCar {
      location
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  formBox: { marginTop: 16 }
}));

export default function Locations() {

  const classes = useStyles();

  const client = useApolloClient();
  const { data, loading, error } = useQuery(GET_CARTVIN_CARTCARLOCATION);

  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;

  // not sure why data.cartLease.vin is null here and not in pages/dates
  console.log(data.cartLease.vin)
  if (!cartVin && data.cartLease.vin) {
    cartVin = data.cartLease.vin
  }
  // cartCar is null on init, unlike cartLease
  if (!cartLocation && data.cartCar && data.cartCar.location) {
    cartLocation = data.cartCar.location
  }

  console.log(cartLocation)
  // problem seems to be coming up when dates changes, data.cartLease does not include a vin

  return (!data.cartLease || !data.cartLease.vin || !data.cartCar || !data.cartCar.location ? <ChooseLease /> :
    <Box className={classes.formBox}>
      <QuoteLocationForm isUpdate={true} cartCarLocation={cartLocation} isCallback={true} onChange={(viewCars) => {
        console.log(viewCars.find(viewCar => viewCar.vin === cartVin).leaseQuote.dropoffLocation)
        client.writeData({
          data: {
            cartLease: {
              ...viewCars.find(viewCar => viewCar.vin === cartVin).leaseQuote
            }
          }
        })
      }}
      />
    </Box>
  );
}
