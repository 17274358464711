import gql from "graphql-tag";
import { GraphQLDate } from "graphql-iso-date";
import isEqual from "lodash/isEqual";
import {
  GET_FILTERS,
  GET_SORT_VIEW,
  GET_CUSTOMER,
  GET_VIEW_LEASEQUOTE,
  GET_CARS_LEASEQUOTE,
  GET_SORT_VIEW_FILTERS,
  GET_VIEW,
  GET_CARS,
  GET_CAR,
} from "./pages/cars";
import { GET_CARTLEASE_CARTCAR } from "./pages/reserve";
import { GET_CART } from "./pages/details";
import { calculateLease, whetherInStockDiscount } from "./lease-total";
import { findTaxRate } from "./sales-tax";
import { pythagoreanDistance } from "./distances";
import {
  DEFAULTVALUE,
  MAXMILEAGE,
  MAXPRICE,
  MAXTOTAL,
  filterAttributes,
  numericalAttributes,
  locations,
  filtersInitial,
  booleanAttributes,
} from "./constants";
import { GET_CUSTOMERSTATE_TAXRATE } from "./pages/tax-jurisdiction";

export const filterCars = (cars, filters) =>
  cars.filter((car) =>
    Object.keys(filters).every((key) => {
      return (
        key === "__typename" ||
        // add leasePrice and other numerical filter logic here
        // leaseTotal now in the leaseQuote object
        (key === "leaseTotal" && filters["leaseTotal"] >= car.leaseQuote.leaseTotal) ||
        (numericalAttributes.includes(key) &&
          filters[key] >= car[key]) ||
        key === "make" ||
        (key === "drivetrain" && filters["drivetrain"].includes("4WD/AWD") && (car["drivetrain"] === "4WD" || car["drivetrain"] === "AWD")) ||
        filters[key].length === 0 ||
        (filterAttributes.includes(key) &&
          filters[key].includes(car[key])) ||
        (booleanAttributes.includes(key) && (filters[key] === false || car[key]))
      );
    })
  )

const filterDaysOut = (cars, pickupDaysOut) =>
  cars.filter(car => car.deliveryDays <= pickupDaysOut)

const sortCars = (cars, sortAttribute, isSortReverse) => {
  console.log("sortCars")
  let forwardCars;
  switch (sortAttribute) {
    case "make":
    case "model":
      forwardCars = cars.sort((a, b) =>
        a[sortAttribute].localeCompare(b[sortAttribute])
      );
      break;
    case "leaseTotal":
      forwardCars = cars.sort(
        (a, b) => a.leaseQuote[sortAttribute] - b.leaseQuote[sortAttribute]
      );
      break;
    case "retailPrice":
      forwardCars = cars.sort(
        (a, b) => a[sortAttribute] - b[sortAttribute]
      );
      break;
    default:
  }

  return isSortReverse ? forwardCars.reverse() : forwardCars;
}

// add a Query isAlphabetical or getFilter?
// figure out what went wrong with the MakeAndModel type later
export const typeDefs = gql`
  scalar Date
  extend type Query {
    isLoggedIn: Boolean!
    cartItems: [ID!]!
    sortAttribute: String
    isSortReverse: Boolean
    isFilterView: Boolean
    searchText: String
    pickupText: String
    dropoffText: String
    customerState: String
    filters: Filters
    isFiltered: Boolean
    searchMakes: [String]
    searchMakeModels: [String]
    cartVin: String
    cartLease: Lease
    cartCar: Car
    isUsingDeposit: Boolean
    customerPickupLocations: [String]
    customerPickupLocation: String
    customerPickupDate: Date
    customerLeaseMonths: Float
    viewField: String
    # firstSuggestionAddress: String
    # firstSuggestionLatitude: Float
    # firstSuggestionLongitude: Float
    # closestLocationCity: String
    # closestLocationDistance: Int
    # secondClosestCity: String
    # secondClosestDistance: Int
    isShowMore: Boolean
    customerCoordinates: Coordinates
    insuranceMonthly: FLoat
    insuranceAccuracy: String
    stateAccuracy: String
  }

  extend type Launch {
    isInCart: Boolean!
  }

  extend type Car {
    isViewed: Boolean!
    isFavorited: Boolean!
    deliveryDays: Int
    leaseTotal: Int
  }

  # this can't be in use anywhere - not accurate
  type Filters {
    model: [String]
    make: [String]
    purchasePrice: Int
    leasePrice: Int
    timeline: Int
  }

  type Coordinates {
    lat: Float
    lng: Float
  }

  # put this typedef in schema, doesn't look like we need it here
  # type Lease {
  #   pickupLocation: String
  #   dropoffLocation: String
  #   transportCost: Int
  #   secondLegFee: Int
  #   dropoffLegFee: Int
  # }

  extend type Mutation {
    addOrRemoveFromCart(id: ID!): [Launch]
    addCarToCart(vin: String!): String
  }
  # Do we need more mutation declarations?  Most aren't present

  # Not sure we have the output right here
  extend type Mutation {
    addOrRemoveFilter(
      attribute: String!
      value: String!
      isChecked: Boolean!
    ): Filters
  }
`;

export const resolvers = {
  // Query: {
  //   modelCheckbox: (_, { modelName }, {client}) =>
  // }
  Car: {
    deliveryDays: (car, _, { cache, client }) => {
      // why not use client here?
      const { customerState, customerPickupLocations } = client.readQuery({
        query: GET_CUSTOMER,
      });
      const newYorkPrepDays = 0;
      const newJerseyPrepDays = 1;
      const otherPrepDays = 1;
      let titleBizDays, titleCopyBizDays, pickupBizDays, deliveryDays;
      const transportDays = customerPickupLocations.includes(car.location)
        ? 0
        : car.transportTimeline;
      // move this logic to a seperate utility file?
      switch (car.supplier) {
        case "Skip's":
          titleBizDays = !!car.titleBizDays ? car.titleBizDays : 0;
          pickupBizDays = 0;
          console.log(car.titleBizDays)
          break;
        case "Avis":
          titleBizDays = 3;
          pickupBizDays = 1;
          break;
        case "Enterprise":
          titleBizDays = 3;
          titleCopyBizDays = 1;
          pickupBizDays = 1;
          break;
        case "Hertz":
          titleBizDays = 5;
          pickupBizDays = 1;
          break;
        case "Manheim":
          titleBizDays = 1;
          pickupBizDays = 1;
          break;
        default:
          titleBizDays = 5;
          pickupBizDays = 5;
      }
      switch (customerState) {
        case "New York":
          // can issue a plate wiht just a copy of title
          deliveryDays =
            Math.max(
              titleCopyBizDays ? titleCopyBizDays : titleBizDays,
              pickupBizDays,
              transportDays
            ) + newYorkPrepDays;
          break;
        case "New Jersey":
          // need original title to issue temp plate
          deliveryDays =
            Math.max(titleBizDays, pickupBizDays, transportDays) +
            newJerseyPrepDays;
          break;
        case "Pennsylvania":
          deliveryDays =
            Math.max(titleBizDays, pickupBizDays, transportDays) +
            otherPrepDays;
          break;
        default:
          deliveryDays =
            Math.max(titleBizDays, pickupBizDays, transportDays) +
            otherPrepDays +
            4;
      }
      return deliveryDays;
    },
    leaseQuote: async (car, _, { cache, client }) => {
      let pickupLocation,
        dropoffLocation,
        transportCost,
        secondLegFee,
        dropoffLegFee;

      const {
        customerState,
        customerTaxRate,
        customerPickupDate,
        customerLeaseMonths,
        customerPickupLocations,
        customerDropoffLocation,
      } = await client.readQuery({
        query: GET_CUSTOMER,
      });

      dropoffLegFee = locations[customerDropoffLocation].transportFee;

      if (
        customerPickupLocations.length === 0 ||
        customerPickupLocations.includes(car.location)
      ) {
        // can assign transportCost and secondLegFee and pickupLocation
        transportCost = 0;

        const {
          firstMonthSalesTax,
          firstMonthRentalTax,
          monthlySalesTax,
          monthlyRentalTax,
          leaseTotal,
          proration,
          dropoffLegSalesTax,
          dropoffLegRentalTax,
          paymentsTotal,
          excessMileageDollars
        } = calculateLease(
          car.leaseFirst,
          car.leaseMonthly,
          customerLeaseMonths,
          customerState,
          customerTaxRate,
          transportCost,
          dropoffLegFee,
          car.retailPrice
        );

        return {
          leaseFirst: car.leaseFirst,
          leaseMonthly: car.leaseMonthly,
          pickupLocation: car.location,
          dropoffLocation: customerDropoffLocation,
          secondLegFee: 0,
          transportCost,
          dropoffLegFee,
          leaseTotal,
          firstMonthSalesTax,
          firstMonthRentalTax,
          monthlySalesTax,
          monthlyRentalTax,
          dropoffLegSalesTax,
          dropoffLegRentalTax,
          proration,
          paymentsTotal,
          excessMileageDollars,
          customerPickupDate,
          // had to add all this because
          customerLeaseMonths,
          vin: car.vin,
          yearMakeModel: `${car.year} ${car.make} ${car.model}`,
          insuranceMonthly: null,
          insuranceAccuracy: null,
          customerTaxRate: null,
          taxRateAccuracy: null,
          customerState: null,
          stateAccuracy: null,
          createdDate: null,
          __typename: "Lease",
        };
      } else {
        // find secondLeg details
        const carLocation = locations[car.location];
        const secondLegs = customerPickupLocations.map((location) => ({
          ...locations[location],
        }));
        const lowestSecondLegFee = Math.min(
          ...secondLegs.map(({ transportFee }) => transportFee)
        );

        // secondLegs filtered for lowestSecondLegFee then reduced on distance
        const closestPickupLocation = secondLegs
          .filter(({ transportFee }) => transportFee === lowestSecondLegFee)
          .reduce((accumulator, currentValue) => {
            return pythagoreanDistance(
              carLocation.latitude,
              carLocation.longitude,
              currentValue.latitude,
              currentValue.longitude
            ) <
              pythagoreanDistance(
                carLocation.latitude,
                carLocation.longitude,
                accumulator.latitude,
                accumulator.longitude
              )
              ? currentValue
              : accumulator;
          });

        // think about leaving transportCost independant of the cars - leaving it off the cars table
        // transportCost = car.transportCost + lowestSecondLegFee;
        // adding the car transportCost means a $50 transfer selection could net a $100 price jump for user
        transportCost = lowestSecondLegFee;

        const {
          firstMonthSalesTax,
          firstMonthRentalTax,
          monthlySalesTax,
          monthlyRentalTax,
          leaseTotal,
          proration,
          dropoffLegSalesTax,
          dropoffLegRentalTax,
          paymentsTotal,
          excessMileageDollars
        } = calculateLease(
          car.leaseFirst,
          car.leaseMonthly,
          customerLeaseMonths,
          customerState,
          customerTaxRate,
          transportCost,
          dropoffLegFee, car.retailPrice
        );

        return {
          leaseFirst: car.leaseFirst,
          leaseMonthly: car.leaseMonthly,
          pickupLocation: closestPickupLocation.description,
          dropoffLocation: customerDropoffLocation,
          secondLegFee: lowestSecondLegFee,
          transportCost,
          dropoffLegFee,
          leaseTotal,
          firstMonthSalesTax,
          firstMonthRentalTax,
          monthlySalesTax,
          monthlyRentalTax,
          dropoffLegSalesTax,
          dropoffLegRentalTax,
          proration,
          paymentsTotal,
          excessMileageDollars,
          customerPickupDate,
          customerLeaseMonths,
          vin: car.vin,
          yearMakeModel: `${car.year} ${car.make} ${car.model}`,
          insuranceMonthly: null,
          insuranceAccuracy: null,
          customerTaxRate: null,
          taxRateAccuracy: null,
          customerState: null,
          stateAccuracy: null,
          createdDate: null,
          __typename: "Lease",
        };
      }
    },
    isInStockDiscount: (car, _, { cache }) => whetherInStockDiscount(car),
    isViewed: (car, _, { cache }) => {
      return false;
    },
  },
  // Date: A date string, such as 2007-12-03 source: https://www.npmjs.com/package/graphql-iso-date
  Date: GraphQLDate,

  Mutation: {
    // tutorial mutations
    // addOrRemoveFromCart: (_, { id }, { cache }) => {
    //   const { cartItems } = cache.readQuery({ query: GET_CART_ITEMS });
    //   const data = {
    //     cartItems: cartItems.includes(id)
    //       ? cartItems.filter((i) => i !== id)
    //       : [...cartItems, id],
    //   };
    //   // write to the cache with the new change
    //   cache.writeQuery({ query: GET_CART_ITEMS, data });
    //   return data.cartItems;
    // },
    addCarToCart: (_, { vin }, { client }) => {
      // const { car } = client.readQuery({
      //   query: GET_CAR_DETAILS,
      //   variables: { vin }
      // });
      const data = { cart: vin };
      client.writeQuery({ query: GET_CART, data });
      return data.cartVin;
    },
    // sortCars in use on some of the old cars (buying) pages
    sortCars: (
      _,
      { cars, sortAttribute, isSortReverse },
      { client, cache, isFiltered }
    ) => {
      const sortedCars = sortCars(cars, sortAttribute, isSortReverse)
      const data = {
        viewCars: sortedCars,
        sortAttribute,
        isSortReverse,
        isFiltered,
      };
      // will I need a query here?
      client.writeQuery({ query: GET_SORT_VIEW, data });
      return data.viewCars;
    },
    sortCarsWriteFilters: (
      _,
      { cars, sortAttribute, isSortReverse, filters, isFiltersClear },
      { client, cache, isFiltered }
    ) => {
      // same as above but we're writing the inputted filters too
      let forwardCars;
      switch (sortAttribute) {
        case "make":
        case "model":
          forwardCars = cars.sort((a, b) =>
            a[sortAttribute].localeCompare(b[sortAttribute])
          );
          break;
        case "leaseTotal":
          forwardCars = cars.sort(
            (a, b) => a.leaseQuote[sortAttribute] - b.leaseQuote[sortAttribute]
          );
          break;
        case "retailPrice":
          forwardCars = cars.sort(
            (a, b) => a[sortAttribute] - b[sortAttribute]
          );
          break;
        default:
      }
      const sortedCars = isSortReverse ? forwardCars.reverse() : forwardCars;
      const data = {
        viewCars: sortedCars,
        sortAttribute,
        isSortReverse,
        isFiltered,
        filters,
        isFiltersClear
      };
      // will I need a query here?
      client.writeQuery({ query: GET_SORT_VIEW_FILTERS, data });
      console.log(data.viewCars);
      return data.viewCars;
    },
    addOrRemoveFiltersCars: (
      _,
      { attribute, values, isChecked, cars, makeModels, pickupDaysOut },
      { client, cache, sortAttribute, isSortReverse }
    ) => {
      console.log(attribute)
      // is it possible to rethink this whole setup so that instead of passing values we pass the value of the checked/unchecked item only - check into how this is working, are we adding another query at the component unnecesarily
      // query for the current filters object
      // could this be more efficient with filters already present on the component and passed as a parameter?
      const { filters } = client.readQuery({ query: GET_FILTERS });
      // update filters according to variables.  or can pass attribute as false to re-filter
      if (numericalAttributes.includes(attribute) && isChecked) {
        // integer attribute and checked, set attribute
        filters[attribute] = values[0];
      } else if (booleanAttributes.includes(attribute)) {
        filters[attribute] = values[0];
      } else if (attribute && isChecked) {
        // array attribute and checked, add values to filters attribute array
        filters[attribute] = filters[attribute]
          ? [...filters[attribute], ...values]
          : [...values];
      } else if (attribute) {
        // uchecked, filter filters attribute array to include only the elements in the values array
        filters[attribute] = filters[attribute].filter((v) =>
          values.every((w) => w !== v)
        );
      }
      // try just sending checked = true, no special case for retailPrice
      // if (attribute === "retailPrice") {
      //   filters["retailPrice"] = values[0]
      // }
      const make =
        attribute === "makeModel" &&
        values[0].substr(0, values[0].indexOf(":"));
      // now that we're passing an array of all makeModel values when a make is checked/unchecked we need to add the values.length check in this special case logic
      // the handleMakeFilterChange handler is going to update the ["make"] filters attribute anyway
      // there's got to be a better way to do this, why does this have to be a special case at all?
      if (attribute === "makeModel" && !isChecked) {
        // special case, if we're unchecking a makeModel, remove that make from filters["makes"]
        filters["make"] = filters["make"].filter((m) => m !== make);
      } else if (attribute === "makeModel") {
        // we're checking a makeModel, determine whether we've got all the makeModels in that make checked now
        // there's no way to know at this point whether we've checked a make and..
        if (
          makeModels &&
          makeModels
            .filter((m) => m.substr(0, m.indexOf(":")) === make)
            .every((m) => filters["makeModel"].includes(m))
        ) {
          filters["make"] = [...filters["make"], make];
        }
      }
      const isFiltersClear = isEqual(filters, filtersInitial)
      console.log(isFiltersClear)
      // if (attribute === "retailPrice") {
      //   filters["retailPrice"] = values[0];
      // }
      let filteredCars = isFiltersClear ?
        cars : filterCars(cars, filters);
      filteredCars = filterDaysOut(filteredCars, pickupDaysOut);
      console.log(filters)
      console.log(filteredCars)
      return {
        viewCars: filteredCars,
        filters,
        isFiltered: true,
        isFiltersClear,
        sortAttribute,
        isSortReverse,
      };
    },
    filterIsInStockCars: (
      _,
      { cars, pickupDaysOut },
      { client, cache, sortAttribute, isSortReverse }
    ) => {
      // based on addOrRemoveFiltersCars

      const filters = { ...filtersInitial, isInStockDiscount: true }

      let filteredCars = filterCars(cars, filters);
      filteredCars = filterDaysOut(filteredCars, pickupDaysOut);

      console.log("filteredCars length: ", filteredCars.length)

      return {
        viewCars: filteredCars,
        filters,
        isFiltered: true,
        isFiltersClear: false,
        sortAttribute,
        isSortReverse,
      };
    },
    clearFiltersCars: (
      _,
      { attribute, cars, makeModels, pickupDaysOut },
      { client, cache, sortAttribute, isSortReverse }
    ) => {
      // query for the current filters object
      const { filters } = client.readQuery({ query: GET_FILTERS });
      // update filters according to variables.  or can pass attribute as false to re-filter
      if (numericalAttributes.includes(attribute)) {
        // integer attribute and not checked, set attribute
        console.log(attribute)
        filters[attribute] = ((attribute) => {
          switch (attribute) {
            case "mileage":
              return MAXMILEAGE;
              break;
            case "leaseTotal":
            // may break these out later
            // return MAXTOTAL
            // break;
            case "leaseFirst":
            // return MAXTOTAL
            // break;
            case "leaseMonthly":
              return MAXTOTAL
              break;
            case "retailPrice":
              return MAXPRICE
              break;
            default:
              return DEFAULTVALUE;
          }
        })(attribute)

        console.log(filters[attribute])
      } else if (attribute) {
        // uchecked, set attribute to empty array
        filters[attribute] = [];
      }
      if (attribute === "makeModel") {
        // special case, if we're clearing makeModel, clear makes also
        filters["make"] = [];
      }
      const isFiltersClear = isEqual(filters, filtersInitial)
      console.log(isFiltersClear)
      let filteredCars = isFiltersClear
        ? // filter cars where every key in filter
        cars :
        filterCars(cars, filters)
      filteredCars = filterDaysOut(filteredCars, pickupDaysOut)
      console.log(pickupDaysOut)
      return {
        viewCars: filteredCars,
        filters,
        isFiltered: true,
        isFiltersClear,
        sortAttribute,
        isSortReverse,
      };
    },
    updateCustomerState: async (_, { customerState }, { client }) => {
      // this clears out the taxRateAccuracy so make sure this is only called when the customerState is changing
      const taxRateArray = findTaxRate(customerState);
      const stateAccuracy = "positive";
      let customerTaxRate, taxRateAccuracy;
      if (taxRateArray.length === 1) {
        customerTaxRate = taxRateArray[0];
        taxRateAccuracy = "positive";
      } else if (taxRateArray[0] === 0) {
        customerTaxRate = 8;
        taxRateAccuracy = "guess";
      } else {
        customerTaxRate = (taxRateArray[0] + taxRateArray[1]) / 2;
        taxRateAccuracy = "guess";
      }

      console.log("resolver ", customerState)

      client.writeQuery({
        query: GET_CUSTOMERSTATE_TAXRATE,
        data: {
          customerState,
          customerTaxRate,
          taxRateAccuracy,
          stateAccuracy,
        },
      });

      return customerState;
    },
    updateCustomerPickupLocations: (
      _,
      { customerPickupLocations, viewCars },
      { client }
    ) => {
      // to be used when the new locations are known, not querying for original customerPickupLocations like addOrRemove..
      const data = {
        customerPickupLocations,
      };
      // write to the cache with the new change
      client.writeQuery({
        query: GET_CUSTOMER,
        data,
      });
      return {
        customerPickupLocations: data.customerPickupLocations,
        viewCars,
      };
    },
    updateCustomerDropoffLocation: (
      _,
      { customerDropoffLocation, viewCars },
      { client }
    ) => {
      const data = {
        customerDropoffLocation,
      };
      // write to the cache with the new change
      client.writeQuery({
        query: GET_CUSTOMER,
        data,
      });
      return {
        customerDropoffLocation: data.customerDropoffLocation,
        viewCars,
      };
    },
    requeryLeasequote: async (_, { viewCars, cartLease }, { client, cache }) => {
      // viewCars leaseQuotes are missing the fields too.  because leaseQuote: doesn't return them and that's the source.
      const { cars } = await client.readQuery({
        // can we slim this query down?  includes pickup and dropoff
        query: GET_CARS_LEASEQUOTE,
        fetchPolicy: "network-only",
      });
      console.log(cars)
      let updatedViewCars = viewCars;
      updatedViewCars.forEach(
        (viewCar, i, updatedViewCars) => {
          // const viewCarsLeaseQuote = viewCar.leaseQuote
          // const carsLeaseQuote = cars.find(
          //   (car) => car.vin === viewCar.vin
          // ).leaseQuote
          // Object.keys(carsLeaseQuote).map(key => {
          //   // console.log(key)
          //   // console.log(!carsLeaseQuote[key])
          //   // console.log(viewCarsLeaseQuote[key])
          //   // console.log(carsLeaseQuote[key])
          //   updatedViewCars[i].leaseQuote[key] = !carsLeaseQuote[key] ? carsLeaseQuote[key] : viewCarsLeaseQuote[key]
          // })
          (updatedViewCars[i].leaseQuote = cars.find(
            (car) => car.vin === viewCar.vin
          ).leaseQuote)
        }
      );
      console.log("requieryLeasequote resolver:")
      console.log(updatedViewCars)
      const data = { viewCars: updatedViewCars };
      client.writeQuery({ query: GET_VIEW_LEASEQUOTE, data });
      return { viewCars: data.viewCars, cartLease };
    },
    requeryLeasequoteFilterDeliveryDays: async (_, { viewCars, pickupDaysOut }, { client, cache }) => {
      // out of date now that we filter on deliveryDaysOut, but not actually in use
      // viewCars leaseQuotes are missing the fields too.  because leaseQuote: doesn't return them and that's the source.
      const { cars } = await client.readQuery({
        // can we slim this query down?  includes pickup and dropoff
        query: GET_CARS_LEASEQUOTE,
        fetchPolicy: "network-only",
      });
      let updatedViewCars = viewCars;
      updatedViewCars.forEach(
        (viewCar, i, updatedViewCars) => {
          // const viewCarsLeaseQuote = viewCar.leaseQuote
          // const carsLeaseQuote = cars.find(
          //   (car) => car.vin === viewCar.vin
          // ).leaseQuote
          // Object.keys(carsLeaseQuote).map(key => {
          //   // console.log(key)
          //   // console.log(!carsLeaseQuote[key])
          //   // console.log(viewCarsLeaseQuote[key])
          //   // console.log(carsLeaseQuote[key])
          //   updatedViewCars[i].leaseQuote[key] = !carsLeaseQuote[key] ? carsLeaseQuote[key] : viewCarsLeaseQuote[key]
          // })
          (updatedViewCars[i].leaseQuote = cars.find(
            (car) => car.vin === viewCar.vin
          ).leaseQuote)
        }
      );
      updatedViewCars = filterDaysOut(updatedViewCars, pickupDaysOut)
      const data = { viewCars: updatedViewCars };
      client.writeQuery({ query: GET_VIEW_LEASEQUOTE, data });
      return data.viewCars;
    },
    filterDeliverydays: (_, { viewCars, pickupDaysOut }, { client, cache }) => {
      const updatedViewCars = filterDaysOut(viewCars, pickupDaysOut)
      const data = { viewCars: updatedViewCars };
      console.log(updatedViewCars)
      client.writeQuery({ query: GET_VIEW_LEASEQUOTE, data });
      return data.viewCars;
    },
    filterCarsDeliverydaysSort: async (_, { cars, pickupDaysOut, filters, sortAttribute, isSortReverse }, { client, cache }) => {
      // filter on attributes and on deliveryDays
      let viewCars = filterDaysOut(cars, pickupDaysOut)
      viewCars = filterCars(viewCars, filters)
      // this isn't sorted
      // would it help to have a sortedCars ref in the component?
      // would only have to update when sort changes
      viewCars = sortCars(viewCars, sortAttribute, isSortReverse)
      console.log("filterCarsDeliverydays resolver:")
      console.log(viewCars);
      client.writeQuery({ query: GET_VIEW, data: { viewCars } });
      return { viewCars };
    },
    updateCustomerPickupDate: (_, { customerPickupDate }, { client }) => {
      client.writeQuery({
        query: GET_CUSTOMER,
        data: { customerPickupDate },
      });
      console.log(customerPickupDate)
      return { customerPickupDate };
    },
    updateCustomerLeaseMonths: (
      _,
      { customerLeaseMonths, viewCars },
      { client }
    ) => {
      client.writeQuery({
        query: GET_CUSTOMER,
        data: { customerLeaseMonths },
      });
      return { customerLeaseMonths, viewCars };
    },
    updatePickupLeaseMonths: (_, { customerPickupDate, customerLeaseMonths, viewCars }, { client }) => {
      client.writeQuery({
        query: GET_CUSTOMER,
        data: { customerPickupDate, customerLeaseMonths },
      });
      return { customerPickupDate, customerLeaseMonths, viewCars };
    },
    updateCartLease: (_, { cartLeaseUpdates }, { client, cache }) => {
      // may need to query for the original cartLease before adding everything
      // this had to be cache to work!  revisit the use of cache.write.. vs client.write..
      // perhaps because the initial values are written to cache and not client in client/index
      // what about writing the cache key as suggested here: https://www.apollographql.com/docs/react/data/local-state/#managing-the-cache
      const { cartLease: previousCartLease } = client.readQuery({
        query: GET_CARTLEASE_CARTCAR,
      });
      const cartLease = {
        ...previousCartLease,
        ...cartLeaseUpdates,
        __typename: "Lease",
      };
      client.writeQuery({
        query: GET_CARTLEASE_CARTCAR,
        data: { cartLease },
      });
      return { cartLease };
    },
    loadCartCar: async (_, { vin }, { client, cache }) => {
      console.log(vin)
      // switch to a car query here
      const car = await client.readQuery({
        // can we slim this query down?  includes pickup and dropoff
        query: GET_CAR,
        variables: { vin },
        fetchPolicy: "network-only",
      });
      const cartCar = car
      // console.log(cars.length)
      // console.log(cartCar)
      client.writeQuery({
        query: GET_CUSTOMER,
        data: {
          cartCar
        }
      })
      return cartCar
    },
    loadUpdateCart: async (_, { vin, cartLease }, { client, cache }) => {
      console.log(vin)
      // switch to a car query here
      const { cars } = await client.readQuery({
        // can we slim this query down?  includes pickup and dropoff
        query: GET_CARS,
        fetchPolicy: "network-only",
      });
      const cartCar = cars.find(car => car.vin === vin)
      console.log(cars.length)
      console.log(cartCar)
      client.writeQuery({
        query: GET_CUSTOMER,
        data: {
          cartCar,
          cartLease
        }
      })
      return cartCar
    }
  },
};
