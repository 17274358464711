import React, { Fragment } from "react";
import GoogleMapReact from "google-map-react";
import {
  makeStyles,
  Box,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Chip,
  Button,
  ButtonBase,
  Card,
  CardContent,
  CardMedia,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { PageContainer, Loading, CarImage, AspectImage } from "../components";
import { useQuery, useApolloClient, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import parse from "date-fns/parse";
import format from "date-fns/format";
import isBefore from "date-fns/isBefore";
import { filterCars } from "../resolvers";
import { navigate, Link as RouterLink } from "@reach/router";
import { priceFormat } from "../number-formats";
import {
  teal,
  orange,
  purple,
  blue,
  grey,
  green,
} from "@material-ui/core/colors";
import Link from "@material-ui/core/Link";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import {
  GET_FILTERS_CARS,
  GET_CARS_LEASEQUOTE,
  GET_CARSLOCATION,
} from "./cars";
import { whetherInStockDiscount } from "../lease-total";
import { StarPlacard } from "./happy-customers";
import { happyCustomerPhotosReviews, locations } from "../constants";
import Carousel from "react-material-ui-carousel";
import {
  BasicLocationMarker,
  CardLocationFlag,
} from "../components/locations-map";

const useStyles = makeStyles((theme) => ({
  heroImageBox: theme.heroImageBox,
  containedHeroImage: {
    // move up enough to overcome two empty PageContainers (with margin)
    marginTop: -theme.spacing(1) * 1.5 * 2 - 8 * 2,
    top: 0,
  },
  leaseHero: {
    backgroundColor: grey[500],
    backgroundImage:
      "url('https://skips-assets-virginia.s3.amazonaws.com/renegade-fallback.jpg')",
    // backgroundImage: "-webkit-image-set(url('https://skips-assets-virginia.s3.amazonaws.com/renegade-1x.jpg') 1x, url('https://skips-assets-virginia.s3.amazonaws.com/renegade-2x.jpg') 2x, url('https://skips-assets-virginia.s3.amazonaws.com/renegade-3x.jpg') 3x)",
    marginBottom: 32,
  },
  heroPageContainer: {
    position: "relative",
    // don't need paddingTop for this container because the empty pageContainer above pads this one down
    width: "100%",
    maxWidth: theme.mediumPageBox.maxWidth,
    margin: "0 auto",
    // top padding is 0
    padding: theme.spacing(1) * 1.5,
  },
  heroHeadingBox: { marginBottom: 16 },
  heroSubheadingBox: { marginBottom: 32 },
  calloutImage: { width: "100%", marginTop: 16 },
  heroHeadingsBox: { paddingTop: 102 },
  heroButtonBox: {
    marginBottom: 16,
  },
  heroButton: { marginLeft: 6 },
  heroHeading: { fontWeight: 500 },
  heroSubheading: { fontWeight: 400, fontSize: "1.20rem" },
  iconCalloutContainer: {
    position: "relative",
    maxWidth: 290,
    margin: "0 auto 32px",
    // marginBottom: 32,
    // [theme.breakpoints.down("xs")]: {
    //   marginLeft: 22,
    // },
  },
  icon: {
    position: "absolute",
    width: 46,
    paddingTop: 4,
    // left: 0,
    [theme.breakpoints.only("xs")]: {
      left: 0,
    },
    [theme.breakpoints.up("sm")]: {
      left: 10,
    },
  },
  iconContent: {
    display: "inline-block",
    // marginLeft: 58,
    verticalAlign: "top",
    [theme.breakpoints.only("xs")]: {
      marginLeft: 58,
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: 68,
    },
  },
  iconContentHeading: {
    marginBottom: 2,
    fontSize: "1.1rem",
    lineHeight: "1.1",
  },
  iconContentParagraph: { fontSize: 14 },
  subheading: {
    textAlign: "center",
    fontWeight: 700,
    color: theme.headingGrey,
    marginBottom: 24,
  },
  briefSubheading: {
    textAlign: "center",
    marginBottom: 18,
  },
  staccatoHeading: {
    display: "inline-block",
    borderBottomWidth: 4,
    borderBottomStyle: "solid",
    lineHeight: "0.4",
    fontWeight: 500,
    marginBottom: 8,
    // compensate for reduced line-height
    paddingTop: 8,
    fontSize: "1.20rem",
  },
  staccatoTeal: { borderBottomColor: teal["A200"] },
  staccatoOrange: { borderBottomColor: orange["A200"] },
  staccatoPurple: { borderBottomColor: purple["A200"] },
  subsectionEndBox: theme.subsectionEndBox,
  sectionEndBox: { marginBottom: 40 },
  uncontainedLoadingContainer: { marginTop: 64 },
  mediumWidth: { maxWidth: theme.mediumPageBox.maxWidth },
  formRow: { marginBottom: 12 },
  adornmentContainer: { display: "flex" },
  chipScrollbox: {
    ...theme.tileSlideBox,
    marginTop: 5,
  },
  valueChip: {
    marginRight: 6,
    marginBottom: 3,
    color: theme.palette.text.primary,
  },
  checkedChip: {
    backgroundColor: "#90caf9 !important",
    border: "1px solid #90caf9",
    // borderColor: blue[400]
  },
  suffixAdornment: {
    fontSize: 16,
    whiteSpace: "pre",
    padding: "6px 0 7px",
  },
  stepsBox: { margin: "0 6px" },
  stepsButton: {
    float: "right",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  featuredTileBox: {
    marginBottom: 10,
    // why do we need this here when we can asign Grid attributs eg. xs={12}
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  tileTitle: { fontSize: "1.1rem", lineHeight: "1.1" },
  quickTileTitle: { fontSize: "1.05rem", lineHeight: "1.1", marginBottom: 2 },
  quickTileBody: { fontSize: "0.8rem" },
  featuredTileBody: { fontSize: "0.9rem" },
  phraseSpan: { whiteSpace: "nowrap" },
  tileImage: { marginBottom: 3 },
  quickQuoteBox: {
    marginBottom: 6,
  },
  quickQuoteButton: {
    float: "right",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  quickQuoteSlideBox: { ...theme.tileSlideBox, marginBottom: 32 },
  quickTileBox: {
    display: "inline-block",
    verticalAlign: "top",
    whiteSpace: "normal",
    width: 200,
    marginRight: 12,
    "&:last-child": { marginRight: 0 },
  },
  steps: { paddingInlineStart: "20px" },
  stepsItem: { marginBottom: 4 },
  lastButton: {
    float: "right",
  },
  lastButtonContainer: {
    overflow: "auto",
  },
  tileButton: { display: "block" },
  priceHighlight: {
    display: "inline-block",
    borderBottom: "3px solid #1bff00",
    // size to match title
    fontSize: "1.05rem",
    lineHeight: "0.45",
    fontWeight: 500,
    color: theme.headingGrey,
    // [defaultTheme.breakpoints.down("sm")]: {
    //   fontSize: "1.4rem",
    // },
  },
  monthsTextfield: { width: 120 },
  // this drops the padding where there'd normally be a label
  unlabeledFilledInput: { paddingTop: 14 },
  landerParagraph: {
    marginBottom: 4,
  },
  card: { display: "flex", marginBottom: 10 },
  cardMedia: {
    flexBasis: "30%",
  },
  cardContent: { flexGrow: 1 },
  discountTitle: {
    display: "flex",
    alignItems: "center",
    color: green[600],
    fontSize: "1.20rem",
  },
  discountIcon: {
    fontSize: "1.35rem",
    marginRight: 3,
  },
  tileSlideBox: { ...theme.tileSlideBox, marginBottom: 20 },
  scrollCard: {
    display: "inline-block",
    verticalAlign: "top",
    width: 260,
    height: 160,
    marginRight: 16,
    whiteSpace: "normal",
    "&:last-child": { marginRight: 0 },
  },
  scrollCardContent: {
    "&:last-child": { paddingBottom: 18 },
  },
  scrollCardList: {
    paddingLeft: 20,
    marginTop: 0,
    marginBottom: 0,
  },
  inlineBold: {
    fontWeight: 500,
  },
  reviewCarousel: { marginBottom: 4 },
  reviewImage: { width: "100%" },
  reviewStarsBox: {
    position: "absolute",
    top: 22,
    padding: "0 22px",
  },
  starsBox: {
    marginBottom: 8,
  },
  reviewBox: {
    marginBottom: 8,
  },
  indicatorBox: {
    position: "absolute",
    bottom: 0,
    marginTop: 0,
  },
  activeIndicator: {
    color: "#fff !important",
  },
  inactiveIndicator: {
    "&:hover": { color: "#ccc" },
  },
  pickupBox: {
    position: "relative",
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      height: 150,
    },
    [theme.breakpoints.up("sm")]: {
      height: 200,
    },
  },

  highlight: theme.highlight,
  hidden: theme.hidden,
  discountedPrice: theme.discountedPrice,
  strikeoutPrice: theme.strikeoutPrice,
}));

// a lot on front pg
// landing pg with links
// broken out more, pages - quote, about, process, faq, featured is ok on main pg at the bottom
// don't like links at the bottom
// links at the top, or hamburger menu
// use <Drawer />
// has most of the info
// would like faq etc. and maybe quick quote links earlier on
// what if they already have insurance - on the lease page with est. Insurance
// need insurance? ^
// too many decimals on driver page
// more info about the car
// condition, transmission, features
// how many people/bags
// filter for trans
// most recent service date, on the car detail page

export const GET_CARBASICS = gql`
  query getCarbasics {
    cars {
      year
      make
      model
      type
      leaseFirst
      leaseMonthly
      images
    }
  }
`;

export const GET_CARLEASEPRICING = gql`
  query getCarleasepricing {
    cars {
      leaseFirst
      leaseMonthly
    }
  }
`;

const UPDATE_LEASE_MONTHS = gql`
  mutation updateCustomerLeaseMonths(
    $customerLeaseMonths: Float!
    $viewCars: [Car]
  ) {
    updateCustomerLeaseMonths(
      customerLeaseMonths: $customerLeaseMonths
      viewCars: $viewCars
    ) @client
  }
`;

function QuickQuote({ className }) {
  const [filterTypes, setFilterTypes] = React.useState([]);
  const [isPricesLoading, setIsPricesLoading] = React.useState(false);

  const QuickTile = ({
    car,
    customerLeaseMonths,
    isPricesLoading,
    imageAspectRatio = 60,
  }) => {
    const classes = useStyles();

    const isInStockDiscount = whetherInStockDiscount(car);

    return (
      <ButtonBase
        component={Box}
        onClick={() => handleClick(car)}
        className={classes.tileButton}
      >
        <CarImage
          url={car.images[0]}
          className={classes.tileImage}
          aspectRatio={imageAspectRatio}
        />
        <Box className={classes.detailsBox}>
          <Typography variant="h6" className={classes.quickTileTitle}>
            {car.year + " " + car.make + " " + car.model}
          </Typography>
          <Box className={isPricesLoading ? classes.hidden : undefined}>
            <Typography variany="body2" className={classes.quickTileBody}>
              <span className={classes.priceHighlight}>
                {priceFormat(
                  car.leaseQuote.leaseTotal / customerLeaseMonths / 30
                )}
              </span>
              {" /day, " +
                priceFormat(car.leaseQuote.leaseTotal) +
                " lease total"}
            </Typography>
          </Box>
          {isInStockDiscount ? (
            <Typography variant="body2" className={classes.quickTileBody}>
              <span
                variant="body2"
                component="span"
                className={classes.strikeoutPrice}
              >
                {priceFormat(car.leaseMonthly * 2)}
              </span>{" "}
              <span
                variant="body2"
                component="span"
                className={classes.discountedPrice}
              >
                {priceFormat(car.leaseFirst)}
              </span>{" "}
              First Month, {priceFormat(car.leaseMonthly)} monthly
            </Typography>
          ) : (
            <Typography variant="body2" className={classes.quickTileBody}>
              {priceFormat(car.leaseFirst) +
                " First Month, " +
                priceFormat(car.leaseMonthly) +
                " monthly"}
            </Typography>
          )}
        </Box>
      </ButtonBase>
    );
  };

  const client = useApolloClient();

  const classes = useStyles();
  const theme = useTheme();

  const isSmallMedia = useMediaQuery(theme.breakpoints.down("sm"));

  const { data, loading, error } = useQuery(GET_FILTERS_CARS);

  const [
    updateCustomerLeaseMonths,
    { loading: leaseMonthsLoading, error: leaseMonthsError },
  ] = useMutation(UPDATE_LEASE_MONTHS, {
    // probably just need LEASEQUOTE here
    refetchQueries: [{ query: GET_CARS_LEASEQUOTE }],
    // viewCars is passed to this mutation function, updateCustomerPickupLocationsRequery
    // so then why do we need the refetch query at all? GET_CARS_LEASEQUOTE is called in the requeryLeasequote resolver mutation
    awaitRefetchQueries: true,
    onCompleted(updateCustomerLeaseMonths) {
      console.log("onCompleted");
      setIsPricesLoading(false);
    },
  });

  const handleClick = (car) => {
    // console.log(car);
    // console.log(data.customerPickupDate)
    client.writeData({
      data: {
        cartCar: car,
        customerPickupLocation: car.leaseQuote.pickupLocation,
        // idea here is to add the car vin and details here so it doesn't need to happen everytime there's inputs to the quote forms
        cartLease: {
          ...car.leaseQuote,
          customerPickupDate: data.customerPickupDate,
        },
        // seed viewCars because it's empty until we get to the leases page but it's the basis for the requery in update/ pages
        viewCars: data.cars,
      },
    });
    // return <Redirect to="/lease" />;
    // setToLease(true);
    navigate("/lease");
  };

  if (loading || !data) return <Loading isUncontained={true} />;
  if (error) return <p>ERROR: {error.message}</p>;

  const quickViewCars = filterCars(
    data.cars.sort((a, b) => a.leaseQuote.leaseTotal - b.leaseQuote.leaseTotal),
    { type: filterTypes }
  );

  const valueSet = data.cars.reduce((accumulator, car) => {
    const type = car.type;
    // e.g. "AWD"
    const leaseTotalDaily = Math.round(
      car.leaseQuote.leaseTotal / car.leaseQuote.customerLeaseMonths / 30
    );
    accumulator[type] = !accumulator[type]
      ? { count: 1, minDaily: leaseTotalDaily }
      : {
          count: accumulator[type]["count"] + 1,
          minDaily:
            leaseTotalDaily < accumulator[type].minDaily
              ? leaseTotalDaily
              : accumulator[type].minDaily,
        };
    return accumulator;
  }, {});

  const handleMonthsChange = (event) => {
    setIsPricesLoading(true);
    console.log(event.target.value);
    updateCustomerLeaseMonths({
      variables: { customerLeaseMonths: parseInt(event.target.value << 0) },
    });
  };

  if (!Object.keys(valueSet).length || !valueSet) return null;

  return (
    <Box className={className}>
      <Box className={classes.formRow + " " + classes.adornmentContainer}>
        <TextField
          // label="Months"
          type="number"
          variant="filled"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">months</InputAdornment>
            ),
            classes: { input: classes.unlabeledFilledInput },
          }}
          className={classes.monthsTextfield}
          name="months"
          defaultValue={data.customerLeaseMonths}
          // value has to be controlled becasue the return date field has to change it
          // these are independant of the customerLeaseMonths state because the TextFields need to be able to show "" when the customer backspaces
          // value={wholeMonths}
          // value={Math.floor(customerLeaseMonths)}
          onChange={handleMonthsChange}
          inputProps={{ min: 1, max: 53, inputMode: "numeric" }}
          // endAdornment={<InputAdornment position="end">months</InputAdornment>}
        />
        {/* ends up outside with filled variant */}
        {/* <span className={classes.suffixAdornment}>{" months"}</span> */}
        {/* <TextField
        label="Days"
        type="number"
        inputProps={{ min: 0, max: 29 }}
        name="days"
        InputLabelProps={{
          shrink: true,
        }}
        value={days}
        onChange={handleMonthDayChange}
        className={inputView === "months" ? classes.inlineInput : classes.inlineInputNone}
      /> */}
      </Box>
      {/* chips */}
      <Box className={classes.formRow}>
        <Box className={classes.chipScrollbox}>
          {Object.keys(valueSet).map((typeKey, i) => {
            const isChecked = filterTypes.includes(typeKey);
            return (
              <Chip
                label={typeKey}
                size="small"
                className={
                  isChecked
                    ? classes.valueChip + " " + classes.checkedChip
                    : classes.valueChip
                }
                onClick={() =>
                  setFilterTypes(
                    isChecked
                      ? filterTypes.filter((type) => type !== typeKey)
                      : [...filterTypes, typeKey]
                  )
                }
              />
            );
          })}
        </Box>
      </Box>
      {isSmallMedia ? (
        <Box className={classes.quickQuoteSlideBox}>
          {quickViewCars.slice(0, 4).map((car) => (
            <Box className={classes.quickTileBox}>
              <QuickTile
                car={car}
                customerLeaseMonths={data.customerLeaseMonths}
                isPricesLoading={isPricesLoading}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Grid container spacing={1}>
          {quickViewCars.slice(0, 4).map((car) => (
            <Grid item xs={12} sm={6} className={classes.featuredTileBox}>
              <QuickTile
                car={car}
                customerLeaseMonths={data.customerLeaseMonths}
                isPricesLoading={isPricesLoading}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}

function FeaturedLeases({ className }) {
  const FeaturedTile = ({ car }) => {
    const classes = useStyles();

    const isInStockDiscount = whetherInStockDiscount(car);

    return (
      <ButtonBase
        component={Box}
        onClick={() => handleClick(car)}
        className={classes.tileButton}
      >
        <CarImage
          url={car.images[0]}
          className={classes.tileImage}
          aspectRatio={60}
        />
        <Box className={classes.detailsBox}>
          <Typography variant="h6">{car.make + " " + car.model}</Typography>
          {isInStockDiscount ? (
            <Typography variany="body2" className={classes.featuredTileBody}>
              <span component="span" className={classes.strikeoutPrice}>
                {priceFormat(car.leaseMonthly * 2)}
              </span>{" "}
              <span
                variant="body2"
                component="span"
                className={classes.discountedPrice}
              >
                {priceFormat(car.leaseFirst)}
              </span>{" "}
              First Month,{" "}
              <span className={classes.phraseSpan}>
                {priceFormat(car.leaseMonthly) + " monthly"}
              </span>
            </Typography>
          ) : (
            <Typography variany="body2" className={classes.featuredTileBody}>
              {priceFormat(car.leaseFirst) + " First Month, "}
              <span className={classes.phraseSpan}>
                {priceFormat(car.leaseMonthly) + " monthly"}
              </span>
            </Typography>
          )}
        </Box>
      </ButtonBase>
    );
  };

  const client = useApolloClient();

  const classes = useStyles();
  const theme = useTheme();

  const isSmallMedia = useMediaQuery(theme.breakpoints.down("sm"));

  // change to just CARS and whatever customer details we need - customerPickupDate
  const { data, loading, error } = useQuery(GET_FILTERS_CARS);

  const handleClick = (car) => {
    client.writeData({
      data: {
        cartCar: car,
        customerPickupLocation: car.leaseQuote.pickupLocation,
        // idea here is to add the car vin and details here so it doesn't need to happen everytime there's inputs to the quote forms
        cartLease: {
          ...car.leaseQuote,
          customerPickupDate: data.customerPickupDate,
        },
        viewCars: data.cars,
      },
    });
    // return <Redirect to="/lease" />;
    // setToLease(true);
    navigate("/lease");
  };

  console.log(data);

  // maybe loading should be in a containing box with a min height
  if (loading || !data) return <Loading isUncontained={true} />;
  if (error) return <p>ERROR: {error.message}</p>;

  const featuredCars = ["Sedan", "Van", "Hatchback", "SUV", "Pickup"]
    .map((type) => data.cars.filter((car) => car.type === type))
    .filter((typeCars) => typeCars.length !== 0)
    .map((typeCars) =>
      typeCars.reduce((accumulator, currentValue) =>
        currentValue.leaseFirst < accumulator.leaseFirst
          ? currentValue
          : accumulator
      )
    );

  return isSmallMedia ? (
    <Box className={classes.quickQuoteSlideBox}>
      {featuredCars.slice(0, 4).map((car) => (
        <Box className={classes.quickTileBox}>
          <FeaturedTile car={car} />
        </Box>
      ))}
    </Box>
  ) : (
    <Grid container spacing={1} className={className}>
      {featuredCars.slice(0, 4).map((car) => (
        <Grid item sm={3} className={classes.featuredTileBox}>
          <FeaturedTile car={car} />
        </Grid>
      ))}
    </Grid>
  );
}

function DiscountLine({ className, isShorten }) {
  const { data, loading, error } = useQuery(GET_CARLEASEPRICING);
  // !data.cars is taking a long while on mobile
  if (loading || !data.cars) return <Typography></Typography>;
  if (error) return <p>ERROR: {error.message}</p>;

  const calculateDiscount = ({ leaseFirst, leaseMonthly }) =>
    whetherInStockDiscount({ leaseFirst, leaseMonthly })
      ? leaseMonthly * 2 - leaseFirst
      : null;
  const discounts = [
    ...new Set(
      data.cars
        .map((car) => calculateDiscount(car))
        .filter((discount) => discount != null)
    ),
  ];

  return (
    <Typography>{`Save ${
      (discounts.length > 1
        ? priceFormat(Math.min(...discounts)) +
          " to " +
          priceFormat(Math.max(...discounts))
        : priceFormat(Math.max(...discounts))) +
      (isShorten ? "" : " on in-stock leases")
    }`}</Typography>
  );
}

function PickupMap({}) {
  const classes = useStyles();

  const [labels, setLabels] = React.useState([]);

  const { data, loading, error } = useQuery(GET_CARSLOCATION);
  // !data.cars is taking a long while on mobile
  if (loading || !data.cars) return <Typography></Typography>;
  if (error) return <p>ERROR: {error.message}</p>;

  const carLocations = [...new Set(data.cars.map((car) => car.location))];

  console.log(carLocations);

  console.log(labels);

  // const showLocations = ..

  return (
    <Box className={classes.pickupBox}>
      {/* // Important! Always set the container height explicitly */}
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
        defaultCenter={{
          // using West Nyack as the center was preventing clicks to that marker from working
          lat: locations["West Nyack, NY"].latitude + 0.002,
          lng: locations["West Nyack, NY"].longitude + 0.002,
        }}
        defaultZoom={7}
        onChildClick={(event, marker) =>
          labels.length === 0 || marker.description !== labels[0].description
            ? setLabels([marker])
            : setLabels([])
        }
      >
        {Object.values(locations).map((locationObject, index) => {
          // const locationObject = locations[location]

          return (
            <BasicLocationMarker
              // consider using lat and lng for locations and everything else to simplify
              lat={locationObject.latitude}
              lng={locationObject.longitude}
              description={locationObject.description}
              key={index}
              netTransportFee={
                locationObject.description === "West Nyack, NY" ? 0 : null
              }
              fontSize="default"
            />
          );
        })}
        {labels.map((location, index) => {
          return (
            <CardLocationFlag
              lat={location.lat}
              lng={location.lng}
              description={location.description}
              checked={false}
              visible={true}
              key={`f${index}`}
            />
          );
        })}
      </GoogleMapReact>
    </Box>
  );
}

export default function LeaseLander() {
  const classes = useStyles();
  const theme = useTheme();

  const isSmallMedia = useMediaQuery(theme.breakpoints.down("sm"));

  // const client = useApolloClient();

  const offerEndDate = parse("09/30/2022", "MM/dd/yyyy", new Date());

  // try removing to speed up load, or move down
  // const { data, loading, error } = useQuery(GET_CARLEASEPRICING);
  // // !data.cars is taking a long while on mobile
  // if (loading) return <Loading isUncontained={true} />;
  // if (error) return <p>ERROR: {error.message}</p>;

  // const calculateDiscount = ({ leaseFirst, leaseMonthly }) => whetherInStockDiscount({ leaseFirst, leaseMonthly }) ? leaseMonthly * 2 - leaseFirst : null
  // const discounts = data.cars.map(car => calculateDiscount(car)).filter(discount => discount != null)
  // const minDiscount = data.cars.reduce((accumulator, currentValue) => calculateDiscount(currentValue) < accumulator ? calculateDiscount(currentValue) : accumulator, 1000)

  return (
    <Fragment>
      <Box
        className={`${classes.heroImageBox} ${classes.leaseHero} ${classes.containedHeroImage}`}
      >
        <Box className={classes.heroPageContainer}>
          <Box className={classes.heroHeadingsBox}>
            <Box className={classes.heroHeadingBox}>
              <Typography
                variant="h4"
                className={classes.highlight + " " + classes.heroHeading}
              >
                Get a car month-to-month
              </Typography>
            </Box>
            <Box className={classes.heroSubheadingBox}>
              <Typography
                variant="h6"
                className={classes.highlight + " " + classes.heroSubheading}
              >
                Drive as long as you want, turn in anytime
              </Typography>
            </Box>
          </Box>
          <Box className={classes.heroButtonBox}>
            <Button
              className={classes.heroButton}
              component={RouterLink}
              variant="contained"
              color="primary"
              disableElevation={true}
              to="/leases"
            >
              Find a flex lease
            </Button>
          </Box>
        </Box>
      </Box>

      <PageContainer className={classes.mediumWidth}>
        {/* Callouts */}
        {isSmallMedia ? (
          <Box className={classes.tileSlideBox}>
            <Card variant="outlined" className={classes.scrollCard}>
              <CardContent className={classes.scrollCardContent}>
                <Typography
                  variant="h6"
                  className={
                    classes.staccatoHeading + " " + classes.staccatoTeal
                  }
                >
                  Flexible
                </Typography>
                <ul className={classes.scrollCardList}>
                  <li>
                    <Typography variant="body1">
                      Month-to-month with no set return date
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      Not a commitment to years of future payments
                    </Typography>
                  </li>
                </ul>
              </CardContent>
            </Card>
            <Card variant="outlined" className={classes.scrollCard}>
              <CardContent className={classes.scrollCardContent}>
                <Typography
                  variant="h6"
                  className={
                    classes.staccatoHeading + " " + classes.staccatoOrange
                  }
                >
                  Easy
                </Typography>
                <ul className={classes.scrollCardList}>
                  <li>
                    <Typography variant="body1">
                      No drawn-out application process
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      We handle maintenance and registration
                    </Typography>
                  </li>
                </ul>
              </CardContent>
            </Card>
            <Card variant="outlined" className={classes.scrollCard}>
              <CardContent className={classes.scrollCardContent}>
                <Typography
                  variant="h6"
                  className={
                    classes.staccatoHeading + " " + classes.staccatoPurple
                  }
                >
                  Great Value
                </Typography>
                <ul className={classes.scrollCardList}>
                  <li>
                    <Typography variant="body1">
                      Competitive pricing with no add-on fees
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">Save on sales tax</Typography>
                  </li>
                </ul>
              </CardContent>
            </Card>
          </Box>
        ) : (
          <Grid container spacing={2} className={classes.subsectionEndBox}>
            <Grid item sm={4}>
              <Typography
                variant="h6"
                className={classes.staccatoHeading + " " + classes.staccatoTeal}
              >
                Flexible
              </Typography>
              <Typography variant="body2" className={classes.landerParagraph}>
                Drive month-to-month. Return anytime for a prorated refund.
              </Typography>
            </Grid>
            {/* <CarImage className={classes.subsectionEndBox} aspectRatio={30} url="https://skips-assets-virginia.s3.amazonaws.com/expedition-3x2.jpg" /> */}
            <Grid item sm={4}>
              <Typography
                variant="h6"
                className={
                  classes.staccatoHeading + " " + classes.staccatoOrange
                }
              >
                Easy
              </Typography>
              <Typography variant="body2" className={classes.landerParagraph}>
                Reserve online. Get your lease transferred to a pickup location
                nearby.
              </Typography>
            </Grid>
            {/* <CarImage className={classes.subsectionEndBox} aspectRatio={30} url="https://skips-assets-virginia.s3.amazonaws.com/Chris_suburban-enhanced-cropped.jpg" /> */}
            <Grid item sm={4}>
              <Typography
                variant="h6"
                className={
                  classes.staccatoHeading + " " + classes.staccatoPurple
                }
              >
                Great value
              </Typography>
              <Typography variant="body2">
                One price includes registration, maintenance, and a full
                warranty.
              </Typography>
            </Grid>
          </Grid>
        )}

        {/* Featured */}
        <Typography variant="h5" className={classes.subheading}>
          Featured leases
        </Typography>
        <FeaturedLeases className={classes.subsectionEndBox} />

        <Grid
          className={classes.subsectionEndBox}
          spacing={3}
          direction={isSmallMedia ? "column-reverse" : "row"}
          container
        >
          <Grid item xs={12} lg={7}>
            {/* Quick quote */}

            <Typography variant="h5" className={classes.subheading}>
              Quick quote
            </Typography>
            <QuickQuote className={classes.quickQuoteBox} />
            <Button
              className={classes.quickQuoteButton}
              component={RouterLink}
              variant="contained"
              color="primary"
              disableElevation={true}
              to="/leases"
            >
              Find a flex lease
            </Button>
          </Grid>
          <Grid item lg={5}>
            <Typography variant="h5" className={classes.subheading}>
              How it works
            </Typography>
            <Box>
              <ol className={classes.steps}>
                <li className={classes.stepsItem}>
                  <Typography variant="body1">
                    <Link
                      component={RouterLink}
                      className={classes.inlineBold}
                      to="/leases"
                    >
                      Choose a lease
                    </Link>
                    . Select a pickup date and location.
                  </Typography>
                </li>
                <li className={classes.stepsItem}>
                  <Typography variant="body1">
                    <span className={classes.inlineBold}>Reserve</span> with a
                    deposit or Proof of Insurance document.
                  </Typography>
                </li>
                <li className={classes.stepsItem}>
                  <Typography variant="body1">
                    We'll prep your car and have it{" "}
                    <span className={classes.inlineBold}>
                      ready at the pickup location
                    </span>
                    .
                  </Typography>
                </li>
                <li className={classes.stepsItem}>
                  <Typography variant="body1">
                    Once you're there,{" "}
                    <span className={classes.inlineBold}>complete payment</span>{" "}
                    for the First Month over the phone and e-sign the Lease
                    Agreement. We'll unlock your car. You're ready to go.
                  </Typography>
                </li>
                <li className={classes.stepsItem}>
                  <Typography variant="body1">
                    Pay as you go and{" "}
                    <span className={classes.inlineBold}>
                      keep your lease for as long as you'd like
                    </span>
                    .
                  </Typography>
                </li>
                <li className={classes.stepsItem}>
                  <Typography variant="body1">
                    When you're finished{" "}
                    <span className={classes.inlineBold}>
                      return your lease
                    </span>
                    . We'll issue a refund for the unused portion of your last
                    monthly payment.
                  </Typography>
                </li>
              </ol>
              <Typography variant="body1">
                <Link component={RouterLink} to="/how-it-works">
                  Learn more about how it works
                </Link>
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={12}>
            <Button
              className={classes.stepsButton}
              component={RouterLink}
              variant="contained"
              color="primary"
              disableElevation={true}
              to="/leases"
            >
              Find a flex lease
            </Button>
          </Grid>
        </Grid>

        {/* Included */}
        <Typography variant="h5" className={classes.subheading}>
          What's included?
        </Typography>
        <Grid container spacing={1} className={classes.subsectionEndBox}>
          <Grid item xs={12} sm={4}>
            <Box className={classes.iconCalloutContainer}>
              <img
                src="https://skips-assets-virginia.s3.amazonaws.com/shield_check.svg"
                className={classes.icon}
              />
              <Box className={classes.iconContent}>
                <Typography variant="h6" className={classes.iconContentHeading}>
                  Warranty & Maintenance
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.iconContentParagraph}
                >
                  Complimentary warranty and routine maintenance
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className={classes.iconCalloutContainer}>
              <img
                src="https://skips-assets-virginia.s3.amazonaws.com/clean_car_light.svg"
                className={classes.icon}
              />
              <Box className={classes.iconContent}>
                <Typography variant="h6" className={classes.iconContentHeading}>
                  Wash & Detail
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.iconContentParagraph}
                >
                  Interior deep clean
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.iconContentParagraph}
                >
                  Exterior prep and polish
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className={classes.iconCalloutContainer}>
              <img
                src="https://skips-assets-virginia.s3.amazonaws.com/price_tag.svg"
                className={classes.icon}
              />
              <Box className={classes.iconContent}>
                <Typography variant="h6" className={classes.iconContentHeading}>
                  Real Pricing
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.iconContentParagraph}
                >
                  No add-on fees
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Discount */}
        {/* {(data.cars && discounts.length != 0 && isBefore(new Date(), offerEndDate)) && <Box className={classes.sectionEndBox}> */}
        {isBefore(new Date(), offerEndDate) && (
          <Box className={classes.sectionEndBox}>
            <Card className={classes.card} variant="outlined">
              <CardMedia
                className={classes.cardMedia}
                image="https://skips-assets-virginia.s3.amazonaws.com/michelle_mustang.jpg"
              />
              <CardContent className={classes.cardContent}>
                <Typography className={classes.discountTitle} variant="h6">
                  <MonetizationOnIcon className={classes.discountIcon} />
                  In-Stock Discount
                </Typography>
                {/* {data.cars && <Typography>{`Save ${(discounts.length > 1 ? priceFormat(Math.min(...discounts)) + " to " + priceFormat(Math.max(...discounts)) : "") + (isSmallMedia ? "" : " on in-stock leases")}`}</Typography>} */}
                <DiscountLine isShorten={isSmallMedia} />
                <Typography>
                  <Link
                    component={RouterLink}
                    to="/leases/?inStockDiscount=true"
                  >
                    See discounted leases
                  </Link>
                </Typography>
                <Typography variant="body2">
                  Ends {format(offerEndDate, "MMM dd")}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        )}

        {/* Covid.. */}

        {/* Insurance */}
        <Box className={classes.sectionEndBox}>
          <Typography variant="h6" className={classes.briefSubheading}>
            What about insurance?
          </Typography>
          <Typography variant="body1" className={classes.landerParagraph}>
            You'll need to carry auto insurance that meets our{" "}
            <Link component={RouterLink} to="/insurance-requirements">
              policy requirements
            </Link>
            . We can help you set up a policy or you can set one up on your own.
          </Typography>
          <Typography variant="body1" className={classes.landerParagraph}>
            Have more questions?
          </Typography>
          <Typography variant="body1">
            <Link component={RouterLink} to="/faq">
              See frequently asked questions
            </Link>
          </Typography>
        </Box>

        {/* Reviews */}

        <Box className={classes.sectionEndBox}>
          <Carousel
            autoPlay={false}
            navButtonsAlwaysInvisible={true}
            activeIndicatorProps={{ className: classes.activeIndicator }}
            indicatorProps={{ className: classes.inactiveIndicator }}
            classes={{ indicators: classes.indicatorBox }}
            className={classes.reviewCarousel}
          >
            {/* vary this order, easier than trying to set an index prop that has to change when the customer makes an input */}
            {happyCustomerPhotosReviews.map((reviewObject, i) => (
              <Box>
                <AspectImage
                  url={reviewObject.imageUri}
                  className={classes.reviewImage}
                  aspectRatio={40}
                  alt="customer"
                />
                {/* <img
              src={reviewObject.imageUri}
              className={classes.reviewImage}
              alt={"customer"}
            /> */}
                <Box className={classes.reviewStarsBox}>
                  <Box className={classes.starsBox}>
                    <StarPlacard
                      starSize="default"
                      starCount={reviewObject.stars}
                    />
                  </Box>
                  <Box className={classes.reviewBox}>
                    <Typography
                      variant="h6"
                      className={classes.review + " " + classes.highlight}
                    >{`"${reviewObject.reviewHighlight}"`}</Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Carousel>
          <Typography variant="body1">
            <Link component={RouterLink} to="/happy-customers">
              See customer reviews
            </Link>
          </Typography>
        </Box>
        <Box className={classes.sectionEndBox}>
          <Typography variant="h6" className={classes.briefSubheading}>
            Pickup Locations
          </Typography>
          <Typography variant="body1" className={classes.landerParagraph}>
            Pickup at our West Nyack office or have your lease transferred to a
            convenient location.
          </Typography>
          <PickupMap />
        </Box>
        <Box className={classes.lastButtonContainer}>
          <Button
            className={classes.lastButton}
            component={RouterLink}
            variant="contained"
            color="primary"
            disableElevation={true}
            to="/leases"
          >
            Find a flex lease
          </Button>
        </Box>
      </PageContainer>
    </Fragment>
  );
}
