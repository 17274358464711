import React from "react";
import { Link as RouterLink, Match } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import format from "date-fns/format";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import { menuPages, backPages, copyrightString } from "../constants"
import { Typography } from "@material-ui/core";
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

const useStyles = makeStyles((theme) => ({
  // maybe apply mobile styles here..
  footerContainer: {
    // position: "absolute",
    zIndex: 0,
    // bottom: 0,
    width: "100%",
    backgroundColor: theme.backgroundGrey,
    // marginTop: theme.spacing(1) * 1.5,
    marginTop: 28
  },
  linkList: {
    listStyle: "none outside",
    paddingLeft: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  // linkListMain: { marginBottom: 12 },
  footerLinkItem: {
    display: "inline-block",
    margin: "2px 7px"
  },
  iconnedLinkItem: {
    display: "flex",
    alignItems: "center",
    margin: "2px 7px"
  },
  footerIcon: { fontSize: "0.9rem", marginRight: 3 },
  footerLink: { color: "#000" },
  iconnedFooterLink: {
    display: "flex",
    alignItems: "center",
    color: "#000"
  },
  footerCopyrightItem: {
    margin: "2px 7px",
  },
  copyright: { textAlign: "right" },
  // mimic PageContainer
  footerPageContainer: {
    width: "100%",
    maxWidth: theme.siteWidth,
    margin: "0 auto",
    padding: theme.spacing(1) * 1.5,
  },
  backlinksCopyrightContainer: {
    display: "flex",
  },
  backlinksBox: {
    flexGrow: 2
  },
  linksBox: {
    flexGrow: 2,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 12
    }
  },
  copyrightBox: {
    [theme.breakpoints.up("md")]: {
      // make full height of parent and flex-end the list element to the bottom
      flexGrow: 2,
      flexBasis: 73,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end"
    }
  },
  secondaryList: {
    listStyle: "none outside",
    paddingLeft: 0,
    marginTop: 0,
    marginBottom: 0
  },
  listsContainer: {
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
      // combined height of the three linkLists
      maxHeight: 73,
    }
  }

}));

export default function LinkFooter({ ...other }) {
  const classes = useStyles();


  return (
    <Match path="/reserve/*">
      {props => props.match ? null :
        <Box className={classes.footerContainer}>
          <Box className={classes.footerPageContainer}>
            <Box className={classes.listsContainer}>
              <Box className={classes.linksBox}>
                <ul className={classes.linkList}>
                  {menuPages.map(pageObject =>
                    <li className={classes.footerLinkItem}>
                      <Link component={RouterLink} to={pageObject.page} className={classes.footerLink}>{pageObject.name}</Link>
                    </li>
                  )}
                </ul>
              </Box>
              {/* <Box className={classes.backlinksCopyrightContainer}> */}
              <Box className={classes.linksBox}>
                <ul className={classes.secondaryList}>
                  <li className={classes.footerLinkItem}>
                    <Link className={classes.iconnedFooterLink} href="sms:347-754-7794"><QuestionAnswerIcon className={classes.footerIcon} /> (347) 754 7794</Link>
                  </li>
                  <li className={classes.footerLinkItem}>
                    <Link className={classes.iconnedFooterLink} href="tel:347-754-7794"><PhoneIcon className={classes.footerIcon} /> (347) 754 7794</Link>
                  </li>
                  <li className={classes.footerLinkItem}>
                    <Link className={classes.iconnedFooterLink} href="mailto:hi@driveskips.com"><EmailIcon className={classes.footerIcon} /> hi@driveskips.com</Link>
                  </li>
                </ul>
              </Box>
              <Box className={classes.linksBox}>
                <ul className={classes.secondaryList}>
                  {backPages.map(pageObject =>
                    <li className={classes.footerLinkItem}>
                      <Link component={RouterLink} to={pageObject.page} className={classes.footerLink}>{pageObject.name}</Link>
                    </li>
                  )}
                </ul>
              </Box>
              <Box className={classes.copyrightBox}>
                <ul className={classes.secondaryList}>
                  <li className={classes.footerCopyrightItem}><Typography variant="body2" className={classes.copyright}>{`© ${format(new Date(), "y")} SKIP'S LLC`}</Typography></li>
                  <li className={classes.footerCopyrightItem}><Typography variant="body2" className={classes.copyright}>NYS DMV Facility No 7121020</Typography></li>
                </ul>
              </Box>
              {/* </Box> */}
            </Box>
          </Box>
        </Box>
      }
    </Match >
  );
}
