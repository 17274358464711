import React, { Fragment } from "react";
import { Link as RouterLink } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  caption: theme.caption
}))

export default function RegistrationDetail({
  customerState,
  stateAccuracy,
  taxRateAccuracy,
}) {
  const classes = useStyles();
  // const client = useApolloClient();
  // const { data, loading, error } = useQuery(GET_CAR_DETAILS, {
  //   variables: { vin },
  // });

  // if (loading) return <Loading />;
  // if (error) return <p>ERROR: {error.message}</p>;

  if (stateAccuracy === "positive" || taxRateAccuracy === "positive") {
    return (
      <Typography>
        registered in <Link to="/update/tax-jurisdiction" component={RouterLink} >{customerState}</Link>
      </Typography>
    );
  } else {
    return (
      <Fragment>
        <Typography variant="caption" className={classes.caption}>registered in {customerState}</Typography>
        <Typography variant="caption" className={classes.caption}><Link to="/update/state" component={RouterLink}>
          Choose state
        </Link></Typography>
      </Fragment>
    );
  }
}
