import React from "react";
import { navigate } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Logo } from ".";
import { ButtonBase } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  // maybe apply mobile styles here
  tooltip: {
    fontSize: 14,
  },
  updateAppBar: {
    ...theme.appBar,
    marginBottom: 0,
    backgroundColor: theme.backgroundGrey,
    // marginBottom: isMargined => isMargined ? theme.appBar.marginBottom : 0
  },
  title: {
    flexGrow: 1,
  },
  toolBar: { width: "100%", maxWidth: theme.mediumPageBox.maxWidth },
  containedIconButton: { ...theme.containedIconButton, marginRight: 12 }
}));

export default function UpdateBar({
  pageName,
  // how would url search parameters work here?
  onBackClick = ["dates", "locations"].includes(pageName) ? () => navigate("/lease") : () => navigate(-1),
  ...other
}) {
  const classes = useStyles();

  return (
    <AppBar position="static" elevation={0} className={classes.updateAppBar} {...other}>
      <Toolbar className={classes.toolBar}>
        <Button variant="contained" color="primary" disableElevation={true} className={classes.containedIconButton} onClick={onBackClick}>
          <ArrowBackIcon />
        </Button>
        {/* <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={onBackClick}
        >
          <ArrowBackIcon />
        </IconButton> */}
        {/* <ButtonBase onClick={() => navigate("/")} disableRipple={true} disableTouchRipple={true}>
          <Logo className={classes.title} />
        </ButtonBase> */}
        {/* <Typography variant="h6" className={classes.title}>
          {pageName}
        </Typography> */}
      </Toolbar>
    </AppBar>
  );
}
