import React from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  GET_CARS_LEASEQUOTE,
  GET_CARS_VIEW_CUSTOMER_FILTERS_SORT,
} from "../pages/cars";
import Loading from "./loading";
import DateRangeForm from "./date-range-form";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import { REQUERY_LEASEQUOTE } from "./quote-location-form";
import { dateOnly } from "../dates";
import addMonths from "date-fns/addMonths";


// clean up all the extranious functions

// viewCars is included here for the updateCustomerLeaseMonthsRequery function, we pass it to requeryLeasequote in onCompleted
const UPDATE_LEASE_MONTHS = gql`
  mutation updateCustomerLeaseMonths(
    $customerLeaseMonths: Float!
    $viewCars: [Car]
  ) {
    updateCustomerLeaseMonths(
      customerLeaseMonths: $customerLeaseMonths
      viewCars: $viewCars
    ) @client
  }
`;

const UPDATE_PICKUP_DATE = gql`
  mutation updateCustomerPickupDate($customerPickupDate: Date!) {
    updateCustomerPickupDate(customerPickupDate: $customerPickupDate) @client
  }
`;

const UPDATE_PICKUP_LEASE_MONTHS = gql`
mutation updatePickupLeaseMonths(
  $customerPickupDate: Date!
  $customerLeaseMonths: Float!
  $viewCars: [Car]
  ) {
    updatePickupLeaseMonths(
      customerPickupDate: $customerPickupDate
      customerLeaseMonths: $customerLeaseMonths
      viewCars: $viewCars
    ) @client
  }
`

export const REQUERY_LEASEQUOTE_FILTER_DELIVERYDAYS = gql`
mutation requeryLeasequoteFilterDeliverydays($viewCars: [Car]!, $pickupDaysOut: Int!) {
  requeryLeasequoteFilterDeliverydays(viewCars: $viewCars, pickupDaysOut: $pickupDaysOut) @client
}
`;

export const FILTER_DELIVERYDAYS = gql`
mutation filterDeliverydays($viewCars: [Car]!, $pickupDaysOut: Int!) {
  filterDeliverydays(viewCars: $viewCars, pickupDaysOut: $pickupDaysOut) @client
}
`;

export const FILTER_CARS_DELIVERYDAYS = gql`
mutation filterCarsDeliverydays($cars: [Car]!, $pickupDaysOut: Int!, $filters: Object!) {
  filterCarsDeliverydays(cars: $cars, pickupDaysOut: $pickupDaysOut, filters: $filters) @client
}
`;

export const FILTER_CARS_DELIVERYDAYS_SORT = gql`
mutation filterCarsDeliverydaysSort($cars: [Car]!, $pickupDaysOut: Int!, $filters: Object!, $sortAttribute: String!, $isSortReverse: boolean!) {
  filterCarsDeliverydaysSort(cars: $cars, pickupDaysOut: $pickupDaysOut, filters: $filters, sortAttribute: $sortAttribute, isSortReverse: $isSortReverse) @client
}
`;



export default function QuoteDateForm({
  setIsPricesLoading = () => { },
  onPickupChange = () => { },
  onLeaseMonthsChange = () => { },
  ...other

}) {
  const { data, loading, error } = useQuery(GET_CARS_VIEW_CUSTOMER_FILTERS_SORT);
  // problem is cars isn't client data - CLIENT.readqu..

  const [filterCarsDeliverydaysSort, { loading: carsDeliveryDaysLoading, error: carsDeliveryDaysError },] = useMutation(FILTER_CARS_DELIVERYDAYS_SORT, {
    onCompleted(filterCarsDeliverydaysSort) {
      console.log(filterCarsDeliverydaysSort)
      onPickupChange(filterCarsDeliverydaysSort.viewCars)
    }
  })

  const [
    updatePickupDateFilterSort,
    { loading: pickupDateLoading, error: pickupDateError },
  ] = useMutation(UPDATE_PICKUP_DATE, {
    // probably just need LEASEQUOTE here
    // only refetch when leaseMonths is changing, shouldn't need it here
    onCompleted({ updateCustomerPickupDate }) {
      console.log(dateOnly(new Date()))
      console.log(updateCustomerPickupDate.customerPickupDate)
      // why pass data.cars here?
      filterCarsDeliverydaysSort({
        variables: { cars: data.cars, pickupDaysOut: differenceInCalendarDays(new Date(updateCustomerPickupDate.customerPickupDate), new Date()), filters: data.filters, sortAttribute: data.sortAttribute, isSortReverse: data.isSortReverse },
      });
    },
  });

  // const [
  //   updatePickupDateRequeryFilter,
  //   { loading: pickupDateRequeryLoading, error: pickupDateRequeryError },
  // ] = useMutation(UPDATE_PICKUP_DATE, {
  //   // probably just need LEASEQUOTE here
  //   // only refetch when leaseMonths is changing, shouldn't need it here
  //   refetchQueries: [{ query: GET_CARS_LEASEQUOTE }],
  //   awaitRefetchQueries: true,
  //   onCompleted({ updateCustomerPickupDate }) {
  //     console.log(dateOnly(new Date()))
  //     console.log(updateCustomerPickupDate.customerPickupDate)
  //     // why pass data.cars here?
  //     filterCarsDeliverydays({
  //       variables: { cars: data.cars, pickupDaysOut: differenceInCalendarDays(new Date(updateCustomerPickupDate.customerPickupDate), new Date()), filters: data.filters },
  //     });
  //   },
  // });

  const [
    requeryLeasequote,
    { loading: requeryLeasequoteLoading, error: requeryLeasequoteError },
  ] = useMutation(REQUERY_LEASEQUOTE, {
    onCompleted({ requeryLeasequote }) {
      setIsPricesLoading(false);
      // how do we get viewCars here?  otherwise need to requery for it
      console.log(requeryLeasequote.viewCars)
      onLeaseMonthsChange(requeryLeasequote.viewCars);
    },
  });

  // change to leaseMonths
  const [
    updateCustomerLeaseMonthsRequery,
    { loading: leaseMonthsRequeryLoading, error: leaseMonthsRequeryError },
  ] = useMutation(UPDATE_LEASE_MONTHS, {
    // probably just need LEASEQUOTE here
    refetchQueries: [{ query: GET_CARS_LEASEQUOTE }],
    // viewCars is passed to this mutation function, updateCustomerPickupLocationsRequery
    // so then why do we need the refetch query at all? GET_CARS_LEASEQUOTE is called in the requeryLeasequote resolver mutation
    awaitRefetchQueries: true,
    onCompleted({ updateCustomerLeaseMonths }) {
      console.log(updateCustomerLeaseMonths)
      requeryLeasequote({
        // does this work without more variables?
        variables: { viewCars: updateCustomerLeaseMonths.viewCars },
      });
    },
  });

  const [updatePickupLeaseMonthsRequery, { loading: pickupLeaseMonthsRequryLoading, error: pickupLeaseMonthsRequryError },
  ] = useMutation(UPDATE_PICKUP_LEASE_MONTHS, {
    // probably just need LEASEQUOTE here
    refetchQueries: [{ query: GET_CARS_LEASEQUOTE }],
    // viewCars is passed to this mutation function, updateCustomerPickupLocationsRequery
    // so then why do we need the refetch query at all? GET_CARS_LEASEQUOTE is called in the requeryLeasequote resolver mutation
    awaitRefetchQueries: true,
    onCompleted({ updatePickupLeaseMonths }) {
      console.log(updatePickupLeaseMonths)
      requeryLeasequote({
        // does this work without more variables?
        variables: { viewCars: updatePickupLeaseMonths.viewCars },
      });
    },
  })


  if (loading || !data) return <Loading />;
  if (error) return <p>ERROR</p>;

  const handlePickupChange = (date) => {
    const customerPickupDate = date.toISOString();
    updatePickupDateFilterSort({ variables: { viewCars: data.viewCars, customerPickupDate } });
    // use the updated mutation here
  };

  const handleLeaseMonthsChange = (customerLeaseMonths) => {
    setIsPricesLoading(true);
    console.log(data.viewCars)
    updateCustomerLeaseMonthsRequery({
      variables: {
        viewCars: data.viewCars,
        customerLeaseMonths,
      },
    });
  };

  const handlePickupLeaseMonthsChange = async (date, customerLeaseMonths) => {
    setIsPricesLoading(true);
    const customerPickupDate = date.toISOString();
    console.log(data.viewCars)

    const response = await updatePickupLeaseMonthsRequery({
      variables: {
        customerPickupDate,
        customerLeaseMonths,
        viewCars: data.viewCars,
      },
    });
    console.log(response)
  };

  console.log(data.customerPickupDate)

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateRangeForm
        onPickupChange={handlePickupChange}
        onLeaseMonthsChange={handleLeaseMonthsChange}
        onPickupLeaseMonthsChange={handlePickupLeaseMonthsChange}
        initialLeaseMonths={data.customerLeaseMonths}
        initialPickup={data.customerPickupDate}
        maxPickupDate={dateOnly(addMonths(new Date(), 1))}
        {...other}
      />
    </MuiPickersUtilsProvider>
  );
}
