import React, { Fragment } from "react";
import { Router, Link as RouterLink, navigate, Match } from "@reach/router";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Box from "@material-ui/core/Box";
import { Logo } from ".";
import { ButtonBase, Drawer, ListItemText, ListItem, List, Link, ListItemIcon, useMediaQuery } from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { menuPages, backPages } from "../constants"
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import HomeIcon from '@material-ui/icons/Home';


const useStyles = makeStyles((theme) => ({
  // maybe apply mobile styles here..
  title: {
    flexGrow: 1
  },
  heroAppBar: {
    // show above absolutely positioned heroImageBox
    position: "absolute",
    background: "rgba(0,0,0,0.16)",
    "& .MuiToolbar-root": { color: "#fff" },
    "& .MuiButtonBase-root": { color: "#fff" }
  },
  toolBar: theme.toolBar,
  menuIcon: { fontSize: "1.6rem" },
  // too big, unsetting class
  navButton: { marginLeft: 8 },
  activeNavButton: { color: theme.placeholderGrey },
  // underline's not so great
  menuPaper: { width: 250 },
  // match MuiToolbar-root styling
  drawerHeader: { minHeight: 56, padding: "0 16px", display: "flex", justifyContent: "flex-end" },
  drawerIconButton: { float: "right", marginRight: -12 },
  drawerToolbar: { justifyContent: "flex-end" },
  backList: {
    flexGrow: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end"
  },
  listItemLink: { color: theme.palette.text.primary },
  listItemIcon: {
    paddingRight: 6,
    minWidth: "unset",
    "& svg": { fontSize: "1.1rem" }
  },
  appBar: {
    ...theme.appBar,
    // behind the curtainBox on mobile filters
    zIndex: 2
  },
}));

export default function NavBar({ path, "*": page, isHero = false, ...other }) {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)
  const theme = useTheme();

  const classes = useStyles();

  const isSmallMedia = useMediaQuery(theme.breakpoints.down("sm"));


  const handleClick = clickedPage => {
    navigate("/" + clickedPage);
    setIsDrawerOpen(false);
  }

  console.log(page)


  return (
    <Fragment>
      <AppBar position="static" color="transparent" elevation={0} className={isHero ? classes.appBar + " " + classes.heroAppBar : classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          >
            <MenuIcon />
          </IconButton>
          <Box className={classes.title}>
            <ButtonBase onClick={() => navigate("/")} disableRipple={true} disableTouchRipple={true}>
              <Match path="/">
                {props => <Logo desktopFontSize={props.match ? 28 : undefined} />}
              </Match>

            </ButtonBase>
          </Box>
          {/* <Typography variant="h6" className={classes.title}>
          S..
        </Typography> */}
          {/* <Button component={RouterLink} className={classes.navButton} to="/flex-lease">
          FLEX LEASING
        </Button> */}
          <Button component={RouterLink} className={classes.navButton} to="/leases">
            Find a flex lease
          </Button>
          {!isSmallMedia && <Fragment><Button component={RouterLink} className={classes.navButton} to="/how-it-works">
            How it works
          </Button>
            <Button component={RouterLink} className={classes.navButton} to="/faq">
              Faq
            </Button></Fragment>}
        </Toolbar>
      </AppBar>
      <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} classes={{ paper: classes.menuPaper }}>
        <Toolbar className={classes.drawerToolbar}>
          <IconButton onClick={() => setIsDrawerOpen(false)} className={classes.drawerIconButton}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <List component="nav">
          {menuPages.map((pageObject, index) =>
            index == 0 ?
              <ListItem className={classes.listItemLink} selected={pageObject.page === page || `/${pageObject.page}` === path} button onClick={() => handleClick(pageObject.page)}><ListItemIcon className={classes.listItemIcon}><HomeIcon className={classes.navItemIcon} /></ListItemIcon><ListItemText primary={pageObject.name} /></ListItem>
              :
              <ListItem selected={pageObject.page === page || `/${pageObject.page}` === path} button onClick={() => handleClick(pageObject.page)}><ListItemText primary={pageObject.name} /></ListItem>
          )}
          {isSmallMedia && <ListItem component={Link} className={classes.listItemLink} href="sms:347-754-7794"><ListItemIcon className={classes.listItemIcon}><QuestionAnswerIcon className={classes.navItemIcon} /></ListItemIcon><ListItemText>(347) 754 7794</ListItemText></ListItem>}
          <ListItem component={Link} className={classes.listItemLink} href="tel:347-754-7794"><ListItemIcon className={classes.listItemIcon}><PhoneIcon className={classes.navItemIcon} /></ListItemIcon><ListItemText>(347) 754 7794</ListItemText></ListItem>
          <ListItem component={Link} className={classes.listItemLink} href="mailto:hi@driveskips.com"><ListItemIcon className={classes.listItemIcon}><EmailIcon className={classes.navItemIcon} /></ListItemIcon><ListItemText>hi@driveskips.com</ListItemText></ListItem>
        </List>
        <List className={classes.backList}>
          {backPages.map(pageObject => <ListItem selected={pageObject.page === page} button onClick={() => handleClick(pageObject.page)}><ListItemText primary={pageObject.name} /></ListItem>
          )
          }
        </List>

      </Drawer>
    </Fragment>
  );
}
