import React, { Fragment } from "react";
// import { Router, Link as RouterLink } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import PageHeading from "../components/page-heading";
import { Typography, Box, CardContent, Card, CardMedia } from "@material-ui/core";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  whoContainer: {
    [theme.breakpoints.up("md")]: {
      display: "flex"
    },
  },
  portraitBox: {
    [theme.breakpoints.up("md")]: {
      flexBasis: 300,
      flexGrow: 0,
      paddingRight: 34
    },
  },
  whoBox: {
    flexBasis: theme.readingWidth
  },
  aboutContainer: {
    [theme.breakpoints.up("md")]: {
      display: "flex"
    },
  },
  aboutBox: {
    [theme.breakpoints.up("md")]: {
      paddingRight: 34
    },
  },
  basicsBox: {
    flexBasis: 180,
    flexShrink: 0,
    marginBottom: 11
  },
  portrait: {
    width: "100%"
  },
  italic: { fontStyle: "italic" },
  lastCaption: { marginBottom: 6 },
  paragraph: { marginBottom: 5 },
  lastParagraph: { marginBottom: 11 },
  subheading: { fontWeight: 500, marginBottom: 3 },
  separateLinkBox: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    },
    padding: 10
  },
  linkList: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
  },
  card: { display: "flex" },
  cardContent: { flexBasis: "auto", flexShrink: 0 },
  cardMedia: {
    flexBasis: 180,
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  narrowPageBox: theme.narrowPageBox
}));

export default function AboutUs({ ...other }) {
  const classes = useStyles();

  return (
    <Fragment>
      <Box className={classes.narrowPageBox}>
        <PageHeading>About us</PageHeading>
        <Box className={classes.aboutContainer}>
          <Box className={classes.aboutBox}>
            <Typography variant="body1" className={classes.subheading} >Engineering a better way</Typography>
            <Typography variant="body1" className={classes.paragraph}>Our thinking is this; car shoppers just want to buy a car. They don’t want to have to pay for the whole showroom experience along with it.  Our website is designed to offer direct access to corporate cars at great prices.</Typography>
            <Typography variant="body1" className={classes.lastParagraph}>We’re rethinking leasing too.  Having both operations under one roof means we’re able to offer our customers better service and better pricing.</Typography>
            <Typography variant="body1" className={classes.subheading} >Putting customers first</Typography>
            <Typography variant="body1" className={classes.lastParagraph}>Our customers are the main focus.  We start with what the customer wants and figure out how to make that happen.  Everyday we pull up to work ready to rethink and to reinvent.</Typography>
          </Box>
          <Box className={classes.basicsBox}>
            <Card variant="outlined" className={classes.card}>
              <CardMedia className={classes.cardMedia} image="https://skips-assets-virginia.s3.amazonaws.com/IMG_7245.jpg" />
              <CardContent className={classes.cardContent}>
                <Typography variant="subtitle1">SKIP'S LLC</Typography>
                <Typography variant="body2">West Nyack, NY</Typography>
                <Typography variant="body2" className={classes.lastCaption}>established 2015</Typography>
                <Box className={classes.linkList}>
                  <Typography variant="body2"><Link href="https://www.yelp.com/biz/skips-west-nyack">Yelp page</Link></Typography>
                  <Typography variant="body2"><Link href="https://www.facebook.com/driveSKIPS/">Facebook page</Link></Typography>
                  <Typography variant="body2"><Link href="https://www.cargurus.com/Cars/m-Skips-sp390284">CarGurus page</Link></Typography>
                  <Typography variant="body2"><Link href="https://www.instagram.com/driveskips">Instagram page</Link></Typography>
                </Box>
              </CardContent>
            </Card>
            <Box className={classes.separateLinkBox}>
              <Typography variant="body2"><Link href="https://www.yelp.com/biz/skips-west-nyack">Yelp page</Link></Typography>
              <Typography variant="body2"><Link href="https://www.facebook.com/driveSKIPS/">Facebook page</Link></Typography>
              <Typography variant="body2"><Link href="https://www.cargurus.com/Cars/m-Skips-sp390284">CarGurus page</Link></Typography>
              <Typography variant="body2"><Link href="https://www.instagram.com/driveskips">Instagram page</Link></Typography>
            </Box>
          </Box>
        </Box>
        <Typography variant="body1" className={classes.subheading}>Who we are</Typography>
        <Box className={classes.whoContainer}>
          <Box className={classes.portraitBox}><img src="https://skips-assets-virginia.s3.amazonaws.com/portrait-car-background.JPG" className={classes.portrait} /></Box>
          <Box className={classes.whoBox}>
            <Typography variant="subtitle1">Max von Stein</Typography>
            <Typography variant="body2" className={classes.italic}>founder</Typography>
            <Typography variant="body2">sales experience: 0</Typography>
            <Typography variant="body2" className={classes.lastCaption}>car guy: for life</Typography>
            <Typography variant="body1" className={classes.paragraph}>Skip's was founded by Max von Stein, an engineer and a car nut who wasn’t happy buying a car the old way.  Max holds a Mechanical Engineering degree from The Cooper Union.</Typography>
          </Box>
        </Box>
      </Box>
    </Fragment >
  );
}
