import React, { Fragment } from "react";
import { Link as RouterLink } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import { priceFormat, numberFormat } from "../number-formats";
import { INCLUDED_MILES } from "../constants";

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    color: theme.green,
    minWidth: 32,
    marginTop: 2
  },
  subheadingBox: { paddingTop: 4 },
  tightItem: {
    paddingTop: 1,
    paddingBottom: 1,
    alignItems: "self-start",

  }
}))

export default function LeaseIncluded({
  customerState,
  cartLease,
  carLocation,
  model
}) {
  const classes = useStyles();

  const standardItems = [
    // { primaryText: `1,500 miles per month (${priceFormat(cartLease.excessMileageDollars, true)} per mile excess)` },
    { primaryText: `${numberFormat(INCLUDED_MILES)} miles per month` },
    { primaryText: "Flexible return: keep your lease for as long as you want, turn it in anytime" },
    { primaryText: `Complete service: this ${model ? model : "car"} comes fully serviced` },
    { primaryText: "Maintenance: maintenance is on us" },
    { primaryText: "Pitcrew Warranty: our warranty covers any breakdowns just in case" },
    {
      primaryChildren: <span>Registration in <Link component={RouterLink} to="/update/tax-jurisdiction">{customerState}</Link></span>
    },
  ]
  // Flexible dates...
  // Full wash and detail...

  let includedItems
  if (cartLease.transportCost + cartLease.secondLegFee + cartLease.dropoffLegFee !== 0) {
    includedItems = standardItems
    includedItems.splice(1, 0, {
      primaryText: ((cartLease.transportCost + cartLease.secondLegFee) !== 0 ? `Pickup transfer from ${carLocation} ${(cartLease.dropoffLegFee !== 0 ? " and return" : "")} ` : "Return") + (cartLease.dropoffLegFee ? ` transfer from ${cartLease.dropoffLocation} ` : "")
    })
  } else {
    includedItems = standardItems
  }
  // const client = useApolloClient();
  // const { data, loading, error } = useQuery(GET_CAR_DETAILS, {
  //   variables: { vin },
  // });

  // if (loading) return <Loading />;
  // if (error) return <p>ERROR: {error.message}</p>;

  return (
    <Box>
      <Box className={classes.subheadingBox}>
        <Typography variant="h6">What's included?</Typography>
      </Box>
      <List dense={true}>
        {includedItems.map(({ primaryText, primaryChildren }) =>
          <ListItem className={classes.tightItem}>
            <ListItemIcon className={classes.listItemIcon} >
              <CheckIcon fontSize="small" />
            </ListItemIcon>
            {primaryText ?
              <ListItemText
                primary={primaryText} /> : <ListItemText>{primaryChildren}</ListItemText>}
          </ListItem>)}
      </List>
    </Box >
  );
}
