import React, { Fragment } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useLocation } from "@reach/router"
import { parse } from "query-string"
// import QuoteForm from "../components/quote-form";
import QuoteFilterForm from "../components/quote-filter-form";
// import PickupLocationInput from "../components/pickup-location-input";
// import SortForm from "../components/sort-form";
import { GET_CARFORM } from "./cars";

import {
  Loading,
  CustomerForm,
  SortForm,
  PageHeading,
  QuoteListInfinite,
} from "../components";
import QuoteDateForm from "../components/quote-date-form";
import QuoteLocationForm from "../components/quote-location-form";
import { useMediaQuery, useTheme, Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  filterListContainer: {
    display: "flex"
  },
  filterFormBox: {
    flexBasis: 210,
    flexShrink: 0,
    marginRight: 22
  },
  quoteListBox: {
    flexShrink: 1,
    flexBasis: "75%"
  },
  lastSwitchbankRow: {
    marginBottom: 28
  },
  quoteListHeading: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 210,
      textAlign: "center"
    },
  }
}))

export default function Leases({ }) {
  const [isPricesLoading, setIsPricesLoading] = React.useState(false);
  const [sortAttribute, setSortAttribute] = React.useState("leaseTotal");
  const [isSortReverse, setIsSortReverse] = React.useState(false);
  const [, setIsFiltered] = React.useState(false);
  // somehow having this hook here loads viewCars initially eventhough it's not used, appears to be something going on in quote-filter-form
  // not having loading here would change the order that the children components load in and so that makes sense

  // source: https://stackoverflow.com/questions/58492797/how-to-get-parameters-on-reach-router
  const location = useLocation()
  const searchParameters = parse(location.search)

  console.log(location)
  console.log(searchParameters)
  console.log((!!searchParameters.inStockDiscount))

  const isInStockDiscount = !!searchParameters.inStockDiscount

  const classes = useStyles();

  const theme = useTheme();
  const isSmallMedia = useMediaQuery(theme.breakpoints.up("sm"))

  // figure out how to get rid of this extranious call later, maybe this issue will go away with apollo v3
  const { loading, error } = useQuery(GET_CARFORM);
  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;

  // if (parameterString = "inStockDiscount")
  // need another check on the filters?

  return (
    // does this have to be so high up?  needs to contain
    <Fragment>
      <PageHeading className={classes.quoteListHeading}>Find a flex lease</PageHeading>
      {isSmallMedia ?
        <Box className={classes.filterListContainer}>
          <Box className={classes.filterFormBox}>
            <QuoteFilterForm sortAttribute={sortAttribute} isSortReverse={isSortReverse} onFilteredChange={isFiltered => setIsFiltered(isFiltered)} isInStockDiscount={isInStockDiscount} />
          </Box>
          <Box className={classes.quoteListBox}>
            <Box>
              <CustomerForm setIsPricesLoading={(isLoading) => setIsPricesLoading(isLoading)} />
              <QuoteDateForm
                setIsPricesLoading={(isLoading) => setIsPricesLoading(isLoading)}
              />
              {/* need isCallback here because otherwise the yearMakeModel doesn't make it to the cartLease as viewCars is requeried */}
              <QuoteLocationForm setIsPricesLoading={(isLoading) => setIsPricesLoading(isLoading)} isCallback={true} className={isSmallMedia ? classes.lastSwitchbankRow : undefined} />
            </Box>
            <SortForm isLease={true} onChange={(sortAttribute, isSortReverse) => { setSortAttribute(sortAttribute); setIsSortReverse(isSortReverse); }} />
            <QuoteListInfinite isPricesLoading={isPricesLoading} isInfinite={true} sortAttribute={sortAttribute} isSortReverse={isSortReverse} />
          </Box>
        </Box>
        :
        <Fragment>
          <CustomerForm setIsPricesLoading={(isLoading) => setIsPricesLoading(isLoading)} />
          <QuoteDateForm
            setIsPricesLoading={(isLoading) => setIsPricesLoading(isLoading)}
          />
          {/* need isCallback here because otherwise the yearMakeModel doesn't make it to the cartLease as viewCars is requeried */}
          <QuoteLocationForm setIsPricesLoading={(isLoading) => setIsPricesLoading(isLoading)} isCallback={true} className={isSmallMedia ? classes.lastSwitchbankRow : undefined} />
          <QuoteFilterForm sortAttribute={sortAttribute} isSortReverse={isSortReverse} onFilteredChange={isFiltered => setIsFiltered(isFiltered)} isInStockDiscount={isInStockDiscount} />
          <SortForm isLease={true} onChange={(sortAttribute, isSortReverse) => { setSortAttribute(sortAttribute); setIsSortReverse(isSortReverse); }} />
          <QuoteListInfinite isPricesLoading={isPricesLoading} isInfinite={true} sortAttribute={sortAttribute} isSortReverse={isSortReverse} />
        </Fragment>
      }
    </Fragment>
  );
}
