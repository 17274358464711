import React, { Fragment } from "react";
import { Link as RouterLink } from "@reach/router";
// import { Router, Link as RouterLink } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import PageHeading from "../components/page-heading";
import { Typography, Box, Button } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  subheading: { textAlign: "center", fontWeight: 700, color: theme.headingGrey, marginBottom: 24 },
  button: { float: "right" },
  stepParagraph: { marginBottom: 4 },
  stepsItem: { marginBottom: 8 },
  lastStepParagraph: { marginBottom: 8 },
  stepHeading: { fontWeight: 500, fontSize: "1.20rem" },
  narrowPageBox: theme.narrowPageBox,
  detailIconBox: theme.detailIconBox,
  paragrpahDetailIcon: { ...theme.detailIcon, fontSize: "1rem" },
  detailBox: theme.detailBox,
  paragraphDetailContainer: { ...theme.detailContainer, paddingLeft: 12, marginBottom: 8 }
}));

export default function HowItWorks({ ...other }) {
  const classes = useStyles();

  return (
    <Fragment>
      <Box className={classes.narrowPageBox}>
        <PageHeading>How it works</PageHeading>
        <Box>
          <Typography className={classes.stepHeading} variant="h6">1. Choose a lease</Typography>
          <Typography variant="body1" className={classes.stepParagraph}><Link component={RouterLink} to="/leases">Choose a lease</Link>.  Select a pickup date and location.  Our leases are month-to-month with no set term.</Typography>
          <Box className={classes.paragraphDetailContainer}>
            <Box className={classes.detailIconBox}>
              <InfoIcon className={classes.paragrpahDetailIcon} />
            </Box>
            <Box className={classes.detailBox}>
              <Typography variant="body2">You're not beholden to the return date you choose.  It's there to help you estimate the cost of your lease.</Typography>
            </Box>
          </Box>
          <Typography className={classes.stepHeading} variant="h6">2. Reserve online</Typography>
          <Typography variant="body1" className={classes.stepParagraph}>Follow the steps online to set up your reservation.  Click Start Reservation to begin.  You can reserve with a deposit or Proof of Insurance document.</Typography>
          <Typography variant="body1" className={classes.lastStepParagraph}>
            <Link component={RouterLink} to="/faq/reserve">See why this is a requirement</Link>
          </Typography>
          <Typography className={classes.stepHeading} variant="h6">3. Head to the pickup location</Typography>
          <Typography variant="body1" className={classes.lastStepParagraph}>We'll detail and service your car and have it ready at the pickup location.  A member of our team will keep you updated and coordinate your pickup.</Typography>
          <Typography className={classes.stepHeading} variant="h6">4. Complete First Month payment and go</Typography>
          <Typography variant="body1" className={classes.lastStepParagraph}>Complete payment and e-sign the Lease Agreement.  Once you're outside the car, call us with a credit or debit card and we'll collect payment for the First Month.  You may e-sign your Lease Agreement at this time or beforehand.  We'll unlock your car and you're ready to go.</Typography>
          <Typography className={classes.stepHeading} variant="h6">5. Drive as long as you'd like</Typography>
          <Typography variant="body1" className={classes.lastStepParagraph}>Pay as you go and keep your lease for as long as you'd like.  Your monthly payments start on month two.</Typography>
          <Typography className={classes.stepHeading} variant="h6">6. Return when you're done</Typography>
          <Typography variant="body1" className={classes.lastStepParagraph}>Return your lease whenever you're finished.  You may return to our West Nyack office or set up another return location for a small charge.  You'll get a refund for the unused portion of your last monthly payment.</Typography>
        </Box>
        <Button
          className={classes.button}
          component={RouterLink}
          variant="contained"
          color="primary"
          disableElevation={true}
          to="/leases"
        >
          Find a flex lease
        </Button>
      </Box>
    </Fragment >
  );
}
