import React from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles({
  // maybe apply mobile styles here
  tooltip: {
    marginleft: 8
  },
  icon: { color: grey[600] }
});

export default function InfoTooltip({ message, ...other }) {
  const classes = useStyles();
  // const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClick = event => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // const handleOptionClickCurry = value => event => {
  //   // call the onChange item here
  //   onChange(event, value);
  //   handleClose();
  // };

  return (
    <Tooltip
      title={message}
      className={classes.tooltip}
      placement="top"
      {...other}
    >
      <InfoOutlinedIcon className={classes.icon} fontSize="small" />
    </Tooltip>
  );
}
