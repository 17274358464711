import React, { Fragment, useEffect } from 'react';
import Scroll from "react-scroll";
import { Link as RouterLink } from "@reach/router";
import { Table, TableRow, TableHead, TableCell, TableBody, useMediaQuery, useTheme } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles, Typography, Box } from '@material-ui/core';
import { purple } from "@material-ui/core/colors"
import SearchIcon from '@material-ui/icons/Search';
import Link from '@material-ui/core/Link';
import { PageHeading } from '../components';
import { INCLUDED_MILES } from "../constants"
import { numberFormat } from '../number-formats';

const useStyles = makeStyles((theme) => ({
  narrowPageBox: theme.narrowPageBox,
  questionParagraph: { fontWeight: 500, marginBottom: 4 },
  answerParagraph: {
    fontWeight: 400,
    "&:last-child": { marginBottom: 12 }
  },
  lastAnswerParagraph: {
    marginBottom: 12
  },
  tableTitle: { fontWeight: 500, marginBottom: 4 },
  searchInputBox: { marginBottom: 18 },
  selectedBox: {
    borderLeftWidth: 4,
    borderLeftStyle: "solid",
    borderLeftColor: purple[100],
    [theme.breakpoints.only("xs")]: {
      paddingLeft: 2,
      marginLeft: -6
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 6,
      marginLeft: -10
    },
  },
  labelContainer: {
    display: "flex"
  },
  label: { lineHeight: 1.2 },
  labelIcon: { marginRight: 4 },
  answerTable: { tableLayout: "fixed", marginBottom: 14, },
  monthCell: {
    [theme.breakpoints.up("sm")]: {
      width: "12%"
    }
  },
  totalCell: { fontWeight: 500 },
  totalRow: {
    "& .MuiTableCell-root": {
      borderBottom: "unset"
    }
  },
  cellNote: { color: theme.noteGrey }
}))

const Element = Scroll.Element
const scroller = Scroll.scroller

const MileageTable = () => {

  const classes = useStyles();

  const theme = useTheme();

  const isSmallMedia = useMediaQuery(theme.breakpoints.up("sm"));

  return <Table size="small" className={classes.answerTable}>
    <TableHead>
      <TableRow>
        <TableCell className={classes.monthCell}>Month</TableCell>
        {isSmallMedia && <TableCell>{isSmallMedia ? "Included miles - cumulative" : "Included miles"}</TableCell>}
        <TableCell>Miles driven</TableCell>
        {isSmallMedia && <TableCell>Miles driven - cumulative</TableCell>}
        <TableCell>Excess Mileage Charge</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {isSmallMedia && <TableRow>
        <TableCell>1</TableCell>
        {isSmallMedia && <TableCell>1,500</TableCell>}
        <TableCell>1,500</TableCell>
        {isSmallMedia && <TableCell>1,500</TableCell>}
        <TableCell>-</TableCell>
      </TableRow>}
      {isSmallMedia && <TableRow>
        <TableCell>2</TableCell>
        {isSmallMedia && <TableCell>3,000</TableCell>}
        <TableCell>1,000</TableCell>
        {isSmallMedia && <TableCell>2,500</TableCell>}
        <TableCell>-</TableCell>
      </TableRow>}
      {isSmallMedia && <TableRow>
        <TableCell>3</TableCell>
        {isSmallMedia && <TableCell>4,500</TableCell>}
        <TableCell>2,000</TableCell>
        {isSmallMedia && <TableCell>4,500</TableCell>}
        <TableCell>-</TableCell>
      </TableRow>}
      <TableRow>
        <TableCell>{isSmallMedia ? 4 : 1}</TableCell>
        {isSmallMedia && <TableCell>6,000</TableCell>}
        <TableCell>2,000</TableCell>
        {isSmallMedia && <TableCell>6,500</TableCell>}
        <TableCell>$75 {isSmallMedia && <span className={classes.cellNote}>(500 miles x $0.15 per mile)</span>}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>{isSmallMedia ? 5 : 2}</TableCell>
        {isSmallMedia && <TableCell>7,500</TableCell>}
        <TableCell>1,000</TableCell>
        {isSmallMedia && <TableCell>7,500</TableCell>}
        <TableCell>$75 refund</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>{isSmallMedia ? 6 : 3}</TableCell>
        {isSmallMedia && <TableCell>9,000</TableCell>}
        <TableCell>1,500</TableCell>
        {isSmallMedia && <TableCell>9,000</TableCell>}
        <TableCell>-</TableCell>
      </TableRow>
      <TableRow className={classes.totalRow}>
        <TableCell />
        {isSmallMedia && <TableCell />}
        <TableCell />
        {isSmallMedia && <TableCell />}
        <TableCell className={classes.totalCell}>$0</TableCell>
      </TableRow>
    </TableBody>
  </Table >
}

export default function Faq({ questionLink }) {
  const classes = useStyles();

  const options = [
    {
      question: "How does this work?",
      answer: <Fragment><Typography className={classes.answerParagraph}>Skip's Flex works like a month-to-month lease. You pay a simple monthly payment for the use of a car. You can keep it for as long as you want or close the lease at any time. The car is registered to you, we're the owner.</Typography><Typography className={classes.answerParagraph}><Link to="/how-it-works" component={RouterLink}>Learn more about how it works</Link></Typography></Fragment>,
      link: "how"
    },
    {
      question: "What does the First Month cover?",
      answer: <Typography className={classes.answerParagraph}>The First Month covers all initiation costs plus your first month of use in the car. Monthly payments start on month two.</Typography>,
      link: "first-month"
    },
    {
      question: "What are the eligibility requirements?",
      answer: <Typography className={classes.answerParagraph}>You must have a credit score of 600 or above to be eligible.  Once you submit your reservation, we’ll run a “soft” credit pull.</Typography>,
      link: "eligibility"
    },
    {
      question: "What do you need in order to start the process?",
      answer: <Typography className={classes.answerParagraph}>Complete a reservation by clicking Start Reservation and following the steps.  We’ll need either a deposit or Proof of Insurance for your lease in order to set up a reservation.  Payment for the First Month is due when you pick up your lease.</Typography>,
      link: "start"
    },
    {
      question: "What if I don't have an American driver license?",
      answer: <Typography className={classes.answerParagraph}>You're welcome to lease even without an American driver license.  We'll need to assemble other forms of identification in order to register your lease.</Typography>,
      link: "license"
    },
    {
      question: "What about insurance?",
      answer: <Fragment><Typography className={classes.answerParagraph}>You are responsible for maintaining insurance for the car.  We can quote an insurance policy or you can set up a policy on your own.</Typography><Typography className={classes.answerParagraph}><Link to="/insurance-requirements" component={RouterLink}>See insurance requirements</Link></Typography></Fragment>,
      link: "insurance"
    },
    {
      question: "Can I use the rental car insurance that comes with my credit card?",
      answer: <Typography className={classes.answerParagraph}>The rental car coverage benefit that's included with credit cards doesn't apply to our leases.  We'll need an insurance policy written explicitly for the car in order to get it registered.  Additionally these credit card benefits typically only cover rentals under 30 days.</Typography>,
      link: "card"
    },
    {
      question: "What about mileage?",
      answer: <Fragment>
        <Typography className={classes.answerParagraph + " " + classes.lastAnswerParagraph}>{"Your lease includes " + numberFormat(INCLUDED_MILES) + " miles per month.  If you exceed this rate during your lease, you'll be charged an excess mileage charge (usually $0.13 to $0.18 per mile).  We'll refund these charges if your usage falls below this rate later."}</Typography>
        <Typography className={classes.tableTitle}>Example lease with average usage of 1,500 miles per month and $0.15 per mile excess mileage charge)</Typography>
        <MileageTable />
      </Fragment>,
      link: "mileage"
    },
    {
      question: "Are there any limits on how I can use the car?",
      answer: <Typography className={classes.answerParagraph}>You may not use your lease for rideshare or livery.  The cars are for on-road use only, no racing or off-roading. You cannot take the car out of the country without our permission.</Typography>,
      link: "limits"
    },
    {
      question: "Can family members use my lease too?",
      answer: <Typography className={classes.answerParagraph}>Yes, absolutely.  Family members regularly using the car should be listed on your insurance policy.</Typography>,
      link: "family"
    },
    {
      question: "Can I lease a car if I'm under 25?",
      answer: <Fragment><Typography className={classes.answerParagraph}>Yes, absolutely. We do not have any age restrictions.</Typography><Typography className={classes.answerParagraph}><Link to="/young-driver" component={RouterLink}>Learn more about leasing as a young driver</Link></Typography></Fragment>,
      link: "age"
    },
    {
      question: "Why can't I select a pickup date that's more than a month away?",
      answer: <Typography className={classes.answerParagraph}>Our lease selection is likely to change by the time your pickup date comes along.  But we can still help you with a lease.  Please give us a call at <Link href="tel:347-754-7794">(347) 754 7794</Link> so we can work on making a lease available on your pickup date.</Typography>,
      link: "month"
    },
    {
      question: "Can I pause my lease?",
      answer: <Typography className={classes.answerParagraph}>We do not have any way for you to pause your lease unfortunately.  We allow you to reduce your insurance coverage temporarily while you're not using your lease.</Typography>,
      link: "pause"
    },
    {
      question: "Why do you require an insurance policy or deposit to reserve a lease?",
      answer: <Typography className={classes.answerParagraph}>Unlike a rental, your lease reservation is for a specific car.  We need to set aside a particular car for you and we may even need to transfer it to your pickup location.</Typography>,
      link: "reserve"
    },
    {
      question: "What happens when I'm ready to return the car?",
      answer: <Fragment><Typography className={classes.answerParagraph}>{"Bring the car back whenever you're finished. You can return it to our office in West Nyack or set up another return location for a small transfer charge.  Your last monthly payment will be prorated, we don't charge you for time you're not using the car. We'll assess an excess wear charge if you return the car with damage and an excess mileage charge if you have driven more than " + numberFormat(INCLUDED_MILES) + " miles per month."}</Typography></Fragment>,
      link: "done"
    },
    {
      question: "Can I purchase my lease if I decide I'd like to keep it long-term?",
      answer: <Fragment><Typography className={classes.answerParagraph}>{"Yes, you have the option of purchasing your lease at any time.  Each monthly payment you make reduces the buyout price."}</Typography></Fragment>,
      link: "purchase"
    },
    {
      question: "What if I already have an active registration, can I transfer it to my lease?",
      answer: <Fragment><Typography className={classes.answerParagraph}>{"Yes, we'll discount the First Month by $100 if you have a valid New York registration that we can transfer to your lease."}</Typography></Fragment>,
      link: "transfer"
    }
  ]

  const [value, setValue] = React.useState(options[0]);
  const [inputValue, setInputValue] = React.useState('');
  const [selectedIndex, setSelectedIndex] = React.useState(-1)

  // console.log(options[6].answer)

  // get the question too
  const getInnerText = option => {
    const question = option.question
    if (typeof (option.answer.props.children) === "string") { return option.answer.props.children + question }
    // reduce if there's more than one paragraph and pick out the child text if there's a link
    if (option.answer.props.children.length > 1) { return option.answer.props.children.reduce((accumulator, currentChild) => accumulator += (currentChild.props?.children ?? ""), "") + question }
    // default
    return ""
  }

  const filterOptions = createFilterOptions({
    // matchFrom: 'start',
    stringify: getInnerText,
  });

  // geuss we don't need this?
  useEffect(() => {
    if (!!questionLink) {
      console.log(questionLink)
      const link = questionLink.replace(/^#/, "")
      console.log(link)
      const linkedIndex = options.map(option => option.link).indexOf(link)
      setSelectedIndex(linkedIndex)
      scroller.scrollTo(options[linkedIndex].link, { smooth: true, duration: 500, offset: -50 })
    }
  }, [])

  return (
    <Box className={classes.narrowPageBox}>
      <PageHeading>FAQ</PageHeading>
      <Box className={classes.searchInputBox}>
        <Autocomplete
          // value={value}
          onChange={(event, value) => {
            const newSelectedIndex = options.indexOf(value)
            setSelectedIndex(newSelectedIndex)
            // props.history.push(`#${value.link}`)
            // navigate(`/faq#${value.link}`)
            if (newSelectedIndex === -1) { return }
            console.log(newSelectedIndex)
            scroller.scrollTo(options[newSelectedIndex].link, { smooth: true, duration: 500, offset: -50 })
          }}
          // inputValue={inputValue}
          // onInputChange={(event, newInputValue) => {
          //   setInputValue(newInputValue);
          // }}
          options={options}
          filterOptions={filterOptions}
          getOptionLabel={option => option.question}
          // renderOption={option => <span><a href={"#" + option.link}>{option.question}</a></span>}
          renderInput={(params) => <TextField placeholder="Question about.." {...params}
            label={<Box className={classes.labelContainer}><SearchIcon className={classes.labelIcon} /><Typography component="span" className={classes.label}>Search</Typography></Box>}
            variant="outlined"
          // InputProps={{
          //   startAdornment:
          //     <InputAdornment position="start">
          //       <SearchIcon />
          //     </InputAdornment>,
          // }}
          />}
        />
      </Box>
      <Box>
        {options.map((option, index) => {
          return <Box id={option.link} className={index === selectedIndex ? classes.selectedBox : null}><Element name={option.link}><Typography className={classes.questionParagraph}>{option.question}</Typography></Element>{option.answer}</Box>
        })}
      </Box>
    </Box>
  );
}