import React, { Fragment } from "react";
import {
  useMutation,
  useLazyQuery,
} from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Loading, PageHeading, CarImage, ImagesUploadForm } from "../components";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid, TextField, Dialog, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import Login from "./login";
import { GET_CAR_ADMIN } from "../pages/cars";
import { filterAttributes, numericalAttributes } from "../constants";


// loadStripe.setLoadParameters({advancedFraudSignals: false})

const useStyles = makeStyles(() => ({
  textField: { margin: "0 5px 4px" },
  queryButton: { marginRight: 8 },
  pushButton: { marginRight: 8 },
  deleteButton: { float: "right" },
  imagesGrid: { marginBottom: 12 },
  reserveHeading: { marginBottom: 6 },
  updateFieldsBox: { marginBottom: 12 },
  updateButtonsBox: { marginBottom: 22 },

}))

const mutableFields = [...filterAttributes, ...numericalAttributes, "trim", "year", "transportTimeline", "supplier", "make", "model", "location", "interiorColor"]

const numericalFields = [...numericalAttributes, "year", "transportTimeline", "deliveryDays"]

const UPDATE_CAR = gql`
  mutation updateCar($vin: String!, $updates: CarInput!) {
    updateCar(vin: $vin, updates: $updates) {
      exteriorColor
    }
  }
`;

const PUSH_CAR_IMAGES = gql`
  mutation pushCarImages($imageUris: [String!], $vin: String!) {
    pushCarImages(imageUris: $imageUris, vin: $vin) {
      images
    }
  }
`;

const DELETE_CAR = gql`
  mutation deleteCar($vin: String!) {
    deleteCar(vin: $vin) {
      exteriorColor
      images
    }
  }
`;

export default function CarAdmin({ "*": vin }) {
  const classes = useStyles();
  // const { data, loading, error } = useQuery(GET_LEASEACCOUNT_CONDITION, {
  //   variables: { accountId },
  // });

  const [updates, setUpdates] = React.useState({});
  const [isDeleteDialog, setIsDeleteDialog] = React.useState(false)

  console.log(vin)

  const [
    getCar,
    { loading, data }
  ] = useLazyQuery(GET_CAR_ADMIN, {
    variables: { vin }
  });

  const [update] = useMutation(
    UPDATE_CAR
  );

  const [pushCarImages] = useMutation(
    PUSH_CAR_IMAGES
  );

  const [deleteCar] = useMutation(
    DELETE_CAR
  );

  const handleSubmit = async (imageUris) => {
    // create resolver to push images
    const data = await pushCarImages({ variables: { imageUris, vin } })
    console.log(data)
  }

  if (loading) return <Loading />;
  if (!data) return <Button onClick={() => getCar()} disableElevation variant="contained" color="primary" >QUERY</Button>
  // if (error) return <p>ERROR: {error.message}</p>;
  // temporary
  if (!data.isLoggedIn) return <Login />

  console.log("data");
  console.log(data);

  return (
    <Fragment>
      <PageHeading className={classes.reserveHeading}>Car admin</PageHeading>
      <Typography>{vin} {data.car.year} {data.car.model}</Typography>
      <Box className={classes.updateFieldsBox}>
        {Object.keys(data.car).map(key =>
          <TextField
            className={classes.textField}
            defaultValue={data.car[key]}
            disabled={!mutableFields.includes(key)}
            label={key}
            onChange={event => setUpdates({ ...updates, [key]: numericalFields.includes(key) ? parseInt(event.target.value) : event.target.value })}
          />
        )}
      </Box>
      <Box className={classes.updateButtonsBox}>
        <Button onClick={() => getCar()} className={classes.queryButton} disableElevation variant="contained" color="primary" >QUERY</Button>
        <Button onClick={() => {
          console.log(updates);
          update({ variables: { vin, updates } });
        }} className={classes.pushButton} disableElevation variant="contained" color="primary">Push Updates</Button>
        <Button onClick={() => setIsDeleteDialog(true)} className={classes.deleteButton} disableElevation color="red">Delete</Button>
      </Box>
      <Dialog open={isDeleteDialog}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this car and all s3 images?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => {
            deleteCar({ variables: { vin } });
          }} color="primary">
            Delete
          </Button>
        </DialogActions>

      </Dialog>
      {data.car.images && <Grid container spacing={1} className={classes.imagesGrid}>
        {data.car.images.map(imageUri => <Grid md={4} item><CarImage url={imageUri} /></Grid>)}
      </Grid>}
      <ImagesUploadForm
        onSubmit={handleSubmit}
        label="image"
      />
    </Fragment>
  );
}
