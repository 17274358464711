import React from "react";
import { navigate } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import { priceFormat, numberFormat } from "../number-formats";
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { NextBarButton, Loading, PageHeading, ChooseLease } from "../components";
import { INCLUDED_MILES } from "../constants";

const useStyles = makeStyles((theme) => ({
  list: {
    [theme.breakpoints.only("xs")]: {
      paddingLeft: 22,
    },
  },
  listItemIcon: { minWidth: 32, marginTop: 5 },
  subheadingBox: { paddingTop: 4 },
  tightItem: { paddingTop: 1, paddingBottom: 1 },
  bulletItem: { alignItems: "flex-start" },
  listItem: { marginBottom: 4 },

  narrowPageBox: theme.narrowPageBox
}))

export const GET_CARTLEASEBASICS = gql`
  query getCartLeaseCartCar {
    cartLease @client {
      customerLeaseMonths
      excessMileageDollars
      insuranceAccuracy
      insuranceMonthly
      customerState
    }
  }
`;

export default function ReserveReview({ "*": quoteId }) {
  const client = useApolloClient()
  const classes = useStyles();
  const { data, loading, error } = useQuery(GET_CARTLEASEBASICS, {
    // fetchPolicy: "network-only",
  });

  if (loading || !data) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;

  if (!data.cartLease.insuranceMonthly) return <ChooseLease />;

  // add dropcap bold words?
  const standardItems = [
    // { primaryText: `1,500 miles per month (${priceFormat(cartLease.excessMileageDollars, true)} per mile excess)` },
    { primaryText: "You're required to maintain insurance coverage for your lease.  If your policy cancels, you'll be charged for a policy we obtain separately." },
    { primaryText: `Your lease includes ${numberFormat(INCLUDED_MILES)} miles per month.  You'll be charged ${priceFormat(data.cartLease.excessMileageDollars, true)} per mile excess if you exceed this rate during your lease.  We'll refund this excess mileage charge if your usage falls below this rate later.` },
  ]

  const secondaryItems = [
    { primaryText: "If you keep your lease for more than 6 months, you'll need to bring it to a repair shop for an oil change.  We'll cover the expense." },
    { primaryText: "You will be responsible for an Excess Wear and Tear Charge if your car is returned with damage that wasn't present when you picked it up." },
    { primaryText: "In the event that your car breaks down, we'll cover the expense of a repair but you'll need to drive the car to a repair shop.  This isn't common, our cars are fully serviced before delivery." },
    { primaryText: "If you're involved in an accident, you'll need to work with us to have the vehicle repaired with proceeds from your insurance policy.  If we determine that the vehicle should not be repaired, you agree to release all insurance proceeds to us.  If you are found to be at-fault for an accident, you are responsible for paying the insurance deductible out of pocket." }
  ]


  // insurance accuracy
  // do it yourself - would add another bullet and change the breakdown line
  // sales/rental tax accuracy
  // damage

  let primaryItems

  // cconditionally add insurance accuruacy item
  if (data.cartLease.insuranceAccuracy !== "quote") {
    primaryItems = standardItems
    primaryItems.splice(1, 0, {
      primaryText: `The ${priceFormat(data.cartLease.insuranceMonthly)} per month estimated insurance premium is only a guess until we obtain an insurance quote. We can help you request a quote now or you can wait until after you've reserved your lease.`
    })
  } else {
    primaryItems = standardItems
  }
  // const client = useApolloClient();
  // const { data, loading, error } = useQuery(GET_CAR_DETAILS, {
  //   variables: { vin },
  // });

  // if (loading) return <Loading />;
  // if (error) return <p>ERROR: {error.message}</p>;

  // console.log(data)

  return (
    <Box className={classes.narrowPageBox}>
      <PageHeading>Review lease terms</PageHeading>
      {/* <Box className={classes.subheadingBox}>
        <Typography variant="h6">Please keep in mind</Typography>
      </Box> */}
      <ul className={classes.list}>
        {primaryItems.map(({ primaryText }) =>
          <li className={classes.listItem}>
            <Typography variant="body1">{primaryText}</Typography>
          </li>
        )}
      </ul>
      <Typography variant="h6">Less common</Typography>
      <ul className={classes.list}>
        {secondaryItems.map(({ primaryText }) =>
          <li className={classes.listItem}><Typography variant="body1">{primaryText}</Typography></li>
        )}
      </ul>
      <NextBarButton onClick={() => navigate("/reserve/driver")} buttonText="Agree and Continue" />
      {/* will we need to switch to a timestamp of when the user confirmed? */}
    </Box>
  );

}
