import React, { Fragment } from "react";
// import { Router, Link as RouterLink } from "@reach/router";
import { useApolloClient, useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { navigate } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Loading } from "../components";
import { GET_LEASEQUOTE, LOAD_LEASEQUOTE_CARS } from "./cars";

const useStyles = makeStyles((theme) => ({
  narrowPageBox: theme.narrowPageBox
}));

export const LOAD_CARTCAR_UPDATE_CARTLEASE = gql`
  mutation loadUpdateCart(
    $vin: String!,
    $cartLease: Lease
  ) {
    loadUpdateCart(
      vin: $vin, cartLease: $cartLease
    ) @client
  }
`;

export const LOAD_CARTCAR = gql`
  mutation loadCartCar(
    $vin: String!
  ) {
    loadCartCar(
      vin: $vin
    ) @client
  }
`;

export default function ResumeLease({ quoteId }) {
  const [isUnavailable, setIsUnavailable] = React.useState(false)
  // const classes = useStyles();
  const client = useApolloClient();

  // const [loadLeaseQuote, { called, loading, data }] = useLazyQuery(GET_LEASEQUOTE, { variables: { quoteId } })

  const [
    loadUpdateCart,
    { loading: loadCartCarLoading, error: loadCartCarError },
  ] = useMutation(LOAD_CARTCAR_UPDATE_CARTLEASE, {
    onCompleted({ loadUpdateCart }) {
      console.log(loadUpdateCart)
      if (!loadUpdateCart) {
        // quoted car's no longer available
        // set a state boolean isUnavailable to true
        // don't navigate
        setIsUnavailable(true)
        // need to add more fields to leaseQuote so we can link to more sedans?
        // just put the other Elantras right here, maybe offer a price match?
      } else {
        navigate("/lease")
      }
      // client.writeData({
      //   data: { filters: addOrRemoveFiltersCars.filters, isFiltered: true },
      // });
      // // instead of writing to the client, call the sortCars mutate function and let that mutation take care of writing to the client
      // sortCars({
      //   variables: {
      //     cars: addOrRemoveFiltersCars.viewCars,
      //     sortAttribute: addOrRemoveFiltersCars.sortAttribute,
      //     isSortReverse: addOrRemoveFiltersCars.isSortReverse,
      //   },
      // });
    },
  });

  console.log(quoteId)
  const { data, loading, error } = useQuery(LOAD_LEASEQUOTE_CARS, {
    variables: { quoteId }
  });

  React.useEffect(() => {
    if (data) {
      // loadCartCarWrite()
      console.log(data)
      client.writeQuery({ query: GET_LEASEQUOTE, data: { cartLease: data.leaseQuote } })
      loadUpdateCart({ variables: { vin: data.leaseQuote.vin, cartLease: data.leaseQuote } })
    }
  }, [data])

  // if (loading) return <Loading />;
  // if (error) return <p>ERROR: {error.message}</p>;

  // combine this opp into one async mutation
  // one it returns we rerout

  // if (data) {
  //   // client.writeQuery({
  //   //   query: GET_CUSTOMER, data: {
  //   //     cartLease: {
  //   //       ...data.leaseQuote
  //   //     },
  //   //     cartCar: { vin: data.leaseQuote.vin }
  //   //   }
  //   // })
  //   client.writeData({
  //     data: {
  //       cartLease: {
  //         ...data.leaseQuote
  //       }
  //     }
  //   })
  // }

  // if (data && data.leaseQuote.vin) {
  //   loadCartCar({ variables: { vin: data.leaseQuote.vin } })
  //   // navigate("/lease")
  // }

  // will need to query for the car data based on vin, maybe this whole operation should be a mutation we call with lazy or useMutation
  // write cartCar in resolver and the other stuff too, like customerState based on leaseQuote

  return (
    isUnavailable ?
      // suggestions, whatever
      <Fragment>
        <Typography>..</Typography>
        <Typography>{data.leaseQuote.yearMakeModel}</Typography>
      </Fragment> : <Loading />
  );
}
