import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import { size } from 'polished';

import Button from "@material-ui/core/Button";
import PageHeading from './page-heading';
import { TextField, Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formSubmit: theme.formSubmit
}))

export default function LoginForm({ login }) {
  const classes = useStyles();
  const [password, setPassword] = React.useState("")
  const handleChange = event => {
    setPassword(event.target.value)
  };

  const handleSubmit = event => {
    event.preventDefault();
    login({ variables: { password } });
  };

  return (
    <Box>
      <PageHeading>Log in</PageHeading>
      <form>
        <TextField
          required
          type="password"
          name="password"
          placeholder="password"
          data-testid="login-input"
          onChange={handleChange}
        />
        <Box className={classes.formSubmit}>

          <Button variant="contained" color="primary" disableElevation={true} type="submit" onClick={handleSubmit}>Log in</Button>
        </Box>
      </form>
    </Box>
  );

}