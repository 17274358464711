const interestRates = { ok: 17.9, good: 12.49, great: 5.48, excellent: 3.94 };

export const calculatePayment = (
  amountFinanced,
  credit = "excellent",
  months
) => {
  const rateMonthly = interestRates[credit] / 100 / 12;
  return (
    (amountFinanced *
      rateMonthly *
      Math.pow(1 + rateMonthly, parseInt(months))) /
    (Math.pow(1 + rateMonthly, parseInt(months)) - 1)
  );
};
