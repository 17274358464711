import React, { Fragment } from "react";
import { Link as RouterLink } from "@reach/router";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Collapse from "@material-ui/core/Collapse";
// import Link from "@material-ui/core/Link";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { priceFormat, decimalFormat } from "../number-formats";
import { Typography, IconButton, Link, Divider, Tooltip } from "@material-ui/core";
import format from "date-fns/format";
import add from "date-fns/add";
import { dateFormat } from "../dates";
import InfoTooltip from "./info-tooltip";

const useStyles = makeStyles((theme) => ({
  subItem: { paddingLeft: 24 },
  expandedItem: { paddingBottom: 0 },
  expandedSubItem: {
    paddingTop: 0,
    paddingBottom: 0
  },
  expandedSubItemBox: { paddingBottom: 8 },
  caption: theme.caption,
  totalItem: { fontWeight: 500 },
  right: { float: "right" },
  // 3 is good when the button's small, otherwise 8
  itemIconButton: { marginLeft: 3 },
  listItemPriceText: { textAlign: "right" },
  tooltippedItemText: { display: "flex", alignItems: "center" },
  tooltip: {
    top: 5,
    position: "relative",
    marginLeft: 3
  },
}));

function TaxExplainer({
  taxAmount,
  customerTaxRate,
  taxRateAccuracy,
  customerState,
  isRentalCarTax,
}) {
  const classes = useStyles();
  return taxRateAccuracy === "positive" ? (
    <Typography variant="caption" className={classes.caption}>
      Includes {priceFormat(taxAmount, true)}{" "}
      <Link component={RouterLink} to="/update/tax-jurisdiction">
        {customerState}
      </Link>
      {isRentalCarTax ? " rental car and sales tax " : " sales tax "}
    </Typography>
  ) : (
      <Typography variant="caption" className={classes.caption}>
        Based on {customerTaxRate.toFixed(2)}% sales tax
      </Typography>
    );
}

export default function LeaseDetailsBox({
  leaseTotal,
  customerLeaseMonths,
  insuranceMonthly,
  insuranceAccuracy,
  leaseFirst,
  firstMonthSalesTax,
  firstMonthRentalTax,
  leaseMonthly,
  monthlyCharges,
  monthlySalesTax,
  monthlyRentalTax,
  customerPickupDate,
  dropoffLegFee,
  dropoffLegSalesTax,
  dropoffLegRentalTax,
  proration,
  transportCost,
  paymentsTotal,
  customerState,
  stateAccuracy,
  taxRateAccuracy,
  customerTaxRate,
  carLocation
}) {
  const classes = useStyles();
  // maybe this should be a ListItem?  not a box
  // first month totla is
  const firstMonthTotal = leaseFirst + firstMonthSalesTax + firstMonthRentalTax + transportCost + (customerLeaseMonths <= 1 ? dropoffLegFee + dropoffLegRentalTax + dropoffLegSalesTax : 0);
  const grandTotal = insuranceMonthly * customerLeaseMonths + leaseTotal;
  const [isExpanded, setIsExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };
  console.log("proration")
  console.log(proration)
  return (
    <Box>
      <List>
        {/* <ListItem>
          <ListItemText>
            <span>Details</span>{" "}
          </ListItemText>
        </ListItem> */}
        <ListItem className={isExpanded ? classes.expandedItem : null}>
          <ListItemText
            // should this only show up when accuracy is guess
            secondary={
              !isExpanded && taxRateAccuracy === "guess" ? (
                <Link component={RouterLink} to="/update/tax-jurisdiction">
                  Choose sales tax jurisdiction
                </Link>
              ) : null
            }
          >
            <span>
              {taxRateAccuracy === "positive"
                ? "Lease total"
                : "Est. lease total"}
            </span>
            <IconButton
              onClick={handleExpandClick}
              size="small"
              classes={{ root: classes.itemIconButton }}
            >
              {isExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </ListItemText>
          {!isExpanded && (
            <ListItemSecondaryAction>
              <ListItemText
                primary={priceFormat(
                  leaseTotal,
                  taxRateAccuracy === "positive"
                )}
                secondary={
                  priceFormat(leaseTotal / customerLeaseMonths / 30) + " /day"
                }
                className={classes.listItemPriceText}
              />
            </ListItemSecondaryAction>
          )}
        </ListItem>
        <Collapse in={isExpanded} classes={{ wrapperInner: classes.expandedSubItemBox }}>
          <ListItem className={isExpanded ? classes.expandedSubItem : null}>
            <ListItemText className={classes.subItem}>
              <Typography variant="body2">
                {taxRateAccuracy === "positive"
                  ? "First Month payment"
                  : "Est. First Month payment"}
              </Typography>
              <Typography variant="caption" className={classes.caption}>
                Due on pickup
                </Typography>
              {TaxExplainer({
                taxAmount: firstMonthSalesTax + firstMonthRentalTax,
                customerTaxRate,
                taxRateAccuracy,
                customerState,
                isRentalCarTax: firstMonthRentalTax !== 0,
              })}
              {/* {taxRateAccuracy === "positive" ? (
                  <p>
                    Includes{" "}
                    {priceFormat(
                      firstMonthSalesTax + firstMonthRentalTax,
                      true
                    )}{" "}
                    <Link component={RouterLink} to="/tax-jurisdiction">{customerState}</Link>
                    {firstMonthRentalTax !== 0
                      ? " rental car and sales tax "
                      : " sales tax "}
                  </p>
                ) : (
                  <p>Based on {customerTaxRate.toFixed(2)}% sales tax</p>
                )} */}

              {/* check for the short-term leases that are tax jurisdiction-independant, where we charge Rockland rates no matter where the customer is */}
              {taxRateAccuracy === "guess" && (
                // should Choose state be here or up near the car by registered in New Jersey?
                // we're only dealing with sales tax jurisdictions in New York for now
                <Typography variant="caption" className={classes.caption}>
                  <Link component={RouterLink} to="/update/tax-jurisdiction">
                    Choose sales tax jurisdiction
                </Link>
                </Typography>
              )}
              {transportCost !== 0 &&
                <Typography variant="caption" className={classes.caption}>
                  Includes {priceFormat(transportCost)} pickup transfer from {carLocation}
                </Typography>
              }
            </ListItemText>
            <ListItemSecondaryAction>
              <ListItemText>
                <p>
                  {taxRateAccuracy === "positive"
                    ? priceFormat(firstMonthTotal, true)
                    : priceFormat(firstMonthTotal)}
                </p>
              </ListItemText>
            </ListItemSecondaryAction>
          </ListItem>
          {customerLeaseMonths > 1 && <ListItem className={isExpanded ? classes.expandedSubItem : null}>
            <ListItemText className={classes.subItem}>
              <Typography variant="body2">
                Monthly payments (
                  {priceFormat(
                leaseMonthly + monthlySalesTax + monthlyRentalTax,
                true
              ) +
                  " per month x " +
                  Math.ceil(customerLeaseMonths - 1) +
                  " months"}
                  )
                </Typography>
              {!!(monthlySalesTax + monthlyRentalTax) &&
                TaxExplainer({
                  taxAmount:
                    (monthlySalesTax + monthlyRentalTax) *
                    Math.ceil(customerLeaseMonths - 1),
                  customerTaxRate,
                  taxRateAccuracy,
                  customerState,
                  isRentalCarTax: firstMonthRentalTax !== 0,
                })}
              <Typography variant="caption" className={classes.caption}>
                Charged on the {format(new Date(customerPickupDate), "do")} of
                  each month starting{" "}
                {format(
                  add(new Date(customerPickupDate), { months: 1 }),
                  "MMMM do"
                )}
              </Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              <ListItemText>
                {priceFormat(
                  Math.ceil(customerLeaseMonths - 1) *
                  (leaseMonthly + monthlySalesTax + monthlyRentalTax),
                  true
                )}
              </ListItemText>
            </ListItemSecondaryAction>
          </ListItem>}
          {!!proration && (
            proration > 0 ?
              // proration
              <ListItem className={isExpanded ? classes.expandedSubItem : null}>
                <ListItemText className={classes.subItem}>
                  <Typography variant="body2">Prorated refund</Typography>
                  <Typography variant="caption" className={classes.caption}>
                    {"Refund for the unused portion of the " +
                      format(
                        add(new Date(customerPickupDate), {
                          months: Math.floor(customerLeaseMonths),
                        }),
                        "MMM do"
                      ) +
                      " monthly payment"}
                  </Typography>

                </ListItemText>
                <ListItemSecondaryAction>
                  <ListItemText>({priceFormat(proration, true)})</ListItemText>
                </ListItemSecondaryAction>
              </ListItem> :
              // dropoffLeg charge
              <ListItem className={isExpanded ? classes.expandedSubItem : null}>
                <ListItemText className={classes.subItem}>
                  <Typography variant="body2">Return transfer</Typography>
                  {TaxExplainer({
                    taxAmount: dropoffLegRentalTax + dropoffLegSalesTax,
                    customerTaxRate,
                    taxRateAccuracy,
                    customerState,
                    isRentalCarTax: !!dropoffLegRentalTax,
                  })}
                </ListItemText>
                <ListItemSecondaryAction>
                  <ListItemText>{priceFormat(-proration, true)}</ListItemText>
                </ListItemSecondaryAction>
              </ListItem>
          )}
        </Collapse>
        <ListItem>
          <ListItemText
            // primary={
            //   "Est. insurance total (" +
            //   (insuranceAccuracy !== "guess" ? (
            //     <Link component={RouterLink} to="/estimate-insurance">
            //       {priceFormat(insuranceMonthly)}
            //     </Link>
            //   ) : (
            //     priceFormat(insuranceMonthly) +
            //     " per month x " +
            //     +customerLeaseMonths.toFixed(2) +
            //     " months)"
            //   ))
            // }
            secondary={
              insuranceAccuracy === "guess" ? (
                <Link component={RouterLink} to="/update/estimate-insurance">
                  Estimate insurance
                </Link>
              ) : null
            }
          >
            {insuranceAccuracy !== "guess" ? (
              <span>
                Est. insurance (
                <Link component={RouterLink} to="/update/estimate-insurance">
                  {priceFormat(insuranceMonthly)} per month
                </Link>{" "}
                x {decimalFormat(customerLeaseMonths)} months)
              </span>
            ) : (
                <span>
                  {insuranceAccuracy === "quote"
                    ? "Insurance "
                    : "Est. insurance "}
                ({priceFormat(insuranceMonthly)} per month x {decimalFormat(customerLeaseMonths)} months)
                </span>
              )}
            <InfoTooltip className={classes.tooltip} message="You'll need to carry an insurance policy for your lease.  This line helps estimate the cost.  We can prepare an insurance quote for you or you can set up your own policy independantly." />
          </ListItemText>
          <ListItemSecondaryAction>
            <ListItemText
              primary={priceFormat(
                insuranceMonthly * customerLeaseMonths,
                insuranceAccuracy === "quote"
              )}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider component="li" variant="middle" />
        <ListItem>
          <ListItemText
            classes={{ primary: classes.totalItem }}
            primary={
              taxRateAccuracy === "positive" && insuranceAccuracy === "quote"
                ? "Total"
                : "Est. total"
            }
          ></ListItemText>
          <ListItemSecondaryAction>
            <ListItemText
              classes={{ primary: classes.totalItem + " " + classes.right }}
              primary={
                taxRateAccuracy === "positive" && insuranceAccuracy === "quote"
                  ? priceFormat(grandTotal, true)
                  : priceFormat(grandTotal)
              }
              secondary={
                priceFormat(grandTotal / customerLeaseMonths / 30) + " /day"
              }
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      <Collapse in={isExpanded}></Collapse>
    </Box>
  );
}
