import React, { Fragment } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Loading, PageHeading, CarImage } from "../components";
import { dateFormat, differenceInPeriods, periodEndDate } from "../dates";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import differenceInMonths from "date-fns/differenceInMonths";
import { priceFormat, numberFormat } from "../number-formats";
import { calculatePayment } from "../finance-payments";
import { orange } from "@material-ui/core/colors";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  sectionHeading: { paddingBottom: 3 },
  return: { paddingBottom: 3 },
  subeading: { fontWeight: 500, color: theme.headingGrey },
  pageSubeading: { marginBottom: 4 },
  tableBox: { maxWidth: 360, marginBottom: 6 },
  lastParagraph: { marginBottom: 10 },
  sectionLast: { marginBottom: 10 },
  openStatus: { color: orange[600] },
}));

export const GET_LEASEACCOUNT = gql`
  query leaseAccount($skipsLeaseId: ID!) {
    account(skipsLeaseId: $skipsLeaseId) {
      yearMakeModel
      startDate
      startOdometer
      monthlyMileageAllowance
      leaseCreditFactor
      startValue
      isForSale
      conditionImages
      salesTaxRate
      otherTaxRate
      payments {
        date
        status
        amount
      }
    }
  }
`;

export default function LeaseStatus({ skipsLeaseId }) {
  const classes = useStyles();
  // not sure what to do about new data uploads in mongo - these would create a new id, maybe we should go off of the stripe subscription id
  const { data, loading, error } = useQuery(GET_LEASEACCOUNT, {
    variables: { skipsLeaseId },
    // fetchPolicy: "network-only",
  });

  //   const [
  //     loadLeaseQuote,
  //     { loading: leaseQuoteLoading, error: leaseQuoteError },
  //   ] = useMutation(GET_LEASEACCOUNT, {
  //     variables: { accountId },
  //     onCompleted({ leaseAccount }) {
  //       debugger;
  //       console.log("loadLeaseQuote onCompleted");
  //     },
  //   });

  console.log("skipsLeaseId");
  console.log(skipsLeaseId);

  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;

  console.log("data");
  console.log(data);

  // if the car's available

  //   if (!data.cartLease.vin && !quoteId) return <ChooseLease />;
  //   if (!data.cartLease.vin && !isQuoteLoaded) {
  //     // check into using .called on useLazyQuery here instead of isQuoteLoaded
  //     console.log("loadLeaseQuote");
  //     getLeaseQuoteLoad({ variables: { quoteId } });
  //     setIsQuoteLoaded(true);
  //   }

  //   if (!data.cartLease.vin) return <ChooseLease />;

  console.log(data.account.startDate);
  console.log(new Date());

  const today = new Date();
  const startDate = new Date(data.account.startDate);
  const wholeMonths = Math.floor(differenceInPeriods(today, startDate));
  const days = differenceInCalendarDays(
    today,
    periodEndDate(startDate, wholeMonths)
  );
  const includedMiles =
    (wholeMonths + days / 30) * data.account.monthlyMileageAllowance;
  // payments
  const paymentsRecentFirst = data.account.payments.slice().reverse();
  const lastPaymentIndex = paymentsRecentFirst.findIndex(
    (payment) => new Date(payment.date) < today
  );
  const lastPayment = paymentsRecentFirst[lastPaymentIndex];
  const nextPayment = paymentsRecentFirst[lastPaymentIndex - 1];
  // purchase option
  // fix leaseCreditFactor, showing as null - check into types
  const currentPrice =
    data.account.payments.length > 1
      ? data.account.startValue -
        data.account.payments.reduce(
          (accumulator, payment) =>
            accumulator +
            (payment.status === "succeeded"
              ? (payment.amount /
                  (1 + data.account.salesTaxRate + data.account.otherTaxRate)) *
                0.5
              : 0),
          0
        )
      : null;
  const amountFinanced = currentPrice * 1.08 - 1500;
  const payments = {
    excellent: calculatePayment(amountFinanced, "excellent", 72),
    great: calculatePayment(amountFinanced, "great", 72),
    good: calculatePayment(amountFinanced, "good", 72),
    ok: calculatePayment(amountFinanced, "ok", 72),
  };

  console.log(nextPayment);
  console.log(data.account);

  return (
    <Fragment>
      <PageHeading>Lease status</PageHeading>
      <Typography variant="h5" className={classes.pageSubeading}>
        {data.account.yearMakeModel}
      </Typography>
      {/* if active */}
      <Typography variant="body1">
        Active since {dateFormat(data.account.startDate)}
      </Typography>
      <Typography variant="body1" className={classes.lastParagraph}>
        {wholeMonths} months, {days} days
      </Typography>
      <Typography className={classes.sectionHeading} variant="h6">
        Mileage
      </Typography>
      <Typography variant="body1">
        Starting odometer {numberFormat(data.account.startOdometer)}
      </Typography>
      <Typography variant="body1" className={classes.lastParagraph}>
        Included miles {numberFormat(includedMiles)} / odometer{" "}
        {numberFormat(data.account.startOdometer + includedMiles)}
      </Typography>
      {(lastPayment || nextPayment) && (
        <Typography className={classes.sectionHeading} variant="h6">
          Payments
        </Typography>
      )}
      {lastPayment && (
        <Fragment>
          <Typography
            className={classes.subeading + " " + classes.return}
            variant="body1"
          >
            Previous payment
          </Typography>
          <Typography variant="body2">
            {priceFormat(lastPayment.amount)}
          </Typography>
          <Typography variant="body2">
            {dateFormat(lastPayment.date)}
          </Typography>
          <Typography
            variant="body2"
            className={
              lastPayment.status === "open"
                ? classes.openStatus + " " + classes.lastParagraph
                : classes.lastParagraph
            }
          >
            {lastPayment.status}
          </Typography>
        </Fragment>
      )}
      {nextPayment && (
        <Fragment>
          <Typography
            className={classes.subeading + " " + classes.return}
            variant="body1"
          >
            Upcoming payment
          </Typography>
          <Typography variant="body2">
            {priceFormat(nextPayment.amount)}
          </Typography>
          <Typography variant="body2">
            {dateFormat(nextPayment.date)}
          </Typography>
          <Typography variant="body2" className={classes.sectionLast}>
            {nextPayment.status}
          </Typography>
        </Fragment>
      )}
      {data.account.isForSale && (
        <Fragment>
          <Typography className={classes.sectionHeading} variant="h6">
            Purchase option
          </Typography>
          {(lastPayment || nextPayment) && !!currentPrice ? (
            <Fragment>
              {/* don't show this section if payment data isn't up to date */}
              <Typography variant="body1">
                Starting value {priceFormat(data.account.startValue)}
              </Typography>
              <Typography variant="body1" className={classes.lastParagraph}>
                Current purchase price {priceFormat(currentPrice)}
              </Typography>
              <Typography
                className={classes.subeading + " " + classes.return}
                variant="body1"
              >
                Estimated monthly payments
              </Typography>
              <Typography variant="body1">$1,500 down, 72 months</Typography>
              <Box className={classes.tableBox}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Credit</TableCell>
                      <TableCell>Payment</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>excellent</TableCell>
                      <TableCell>{priceFormat(payments.excellent)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>great</TableCell>
                      <TableCell>{priceFormat(payments.great)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>good</TableCell>
                      <TableCell>{priceFormat(payments.good)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>ok</TableCell>
                      <TableCell>{priceFormat(payments.ok)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              <Typography variant="body2" className={classes.sectionLast}>
                Call or text us at (347) 754 7794 to discuss purchasing your
                lease.
              </Typography>
            </Fragment>
          ) : (
            <Typography variant="body2" className={classes.sectionLast}>
              Purchase information not available. Please call or text us at
              (347) 754 7794 to discuss purchasing your lease.
            </Typography>
          )}
        </Fragment>
      )}
      {data.account.conditionImages && (
        <Fragment>
          <Typography className={classes.sectionHeading} variant="h6">
            Condition at start
          </Typography>
          <Grid container spacing={1}>
            {data.account.conditionImages.map((imageUri) => (
              <Grid item xs={12} md={4}>
                <CarImage url={imageUri} />
              </Grid>
            ))}
          </Grid>
        </Fragment>
      )}
      {/* add a note about how easy it is to do this in the mail, call for details */}
    </Fragment>
  );
}
