import React, { Component, Fragment } from "react";
import styled, { css } from "react-emotion";
import { size } from "polished";
import { useApolloClient, useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Loading } from "../components";
import {
  GET_CARS_VIEW_CUSTOMER,
  GET_SORT,
  GET_CARS_LEASEQUOTE,
  GET_CUSTOMER,
} from "../pages/cars";
import { SORT_CARS } from "./cars-forms";
// import { typeDefs } from "../resolvers";
import Box from "@material-ui/core/Box";
import MapIcon from "@material-ui/icons/Map";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Popper from "@material-ui/core/Popper";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { locations } from "../constants";
import PlacesAutocompleteClosest from "./places-autocomplete-closest";
import LocationsMap from "./locations-map";
import IconMenu from "./icon-menu";
import UpdateBar from "./update-bar";

const useStyles = makeStyles((theme) => ({
  formRow: {
    marginTop: 14,
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  pickupAutocomplete: { width: "55%", display: "inline-block" },
  dropoffAutocomplete: {
    marginLeft: 12,
    width: "calc(45% - 12px)",
    display: "inline-block",
  },
  overlappingAutocomplete: {
    "& .MuiAutocomplete-popupIndicator:not(:hover)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  locationsMap: {
    height: "100%",
    width: "100%",
  },
  locationsMapBox: {
    height: "calc(100% - 64px)",
    width: "100%",
  },
  mapUpdateBar: {
    position: "relative"
  },
  locationsMapPage: {
    height: "100%",
    width: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 10,
  },
  locationAutocompletesBox: {
    // 488 width of fields above
    maxWidth: 488,
  },
  switchbankRow: theme.switchbankRow,
  switchbankIconMenu: theme.switchbankIconMenu,
  switchbankBox: theme.switchbankBox,
  none: theme.none
}));

export const GET_CARS_LEASEQUOTEHIGHLIGHTS = gql`
  query getCarsLeasetotal {
    cars {
      vin
      leaseFirst
      leaseMonthly
      location
      transportCost
      leaseQuote @client {
        leaseTotal
        secondLegFee
        transportCost
        pickupLocation
        dropoffLocation
        dropoffLegFee
        # may need dropoff location here if we decide to display that on quote-list
      }
    }
    customerPickupLocations @client
    customerDropoffLocation @client
  }
`;

export const UPDATE_PICKUP_LOCATIONS = gql`
  mutation updateCustomerPickupLocations(
    $customerPickupLocations: [String]!
    $viewCars: [Car]!
  ) {
    updateCustomerPickupLocations(
      customerPickupLocations: $customerPickupLocations
      viewCars: $viewCars
    ) @client
  }
`;

export const UPDATE_DROPOFF_LOCATION = gql`
  mutation updateCustomerDropoffLocation(
    $customerDropoffLocation: [String]!
    $viewCars: [Car]!
  ) {
    updateCustomerDropoffLocation(
      customerDropoffLocation: $customerDropoffLocation
      viewCars: $viewCars
    ) @client
  }
`;

export const REQUERY_LEASEQUOTE = gql`
  mutation requeryLeasequote($viewCars: [Car]!, $cartLease: Lease) {
    requeryLeasequote(viewCars: $viewCars, cartLease: $cartLease) @client
  }
`;

// source: https://github.com/mui-org/material-ui/issues/19376
// forget the props just write the Popper directly to the autocomplete prop below
const WidePopper = function (props) {
  return <Popper {...props} style={{ minWidth: 250 }} />;
};
// just apply styles to the class instead, without using child selected

const WidePopperMultiple = function (props) {
  // setting a data attribute so we can apply to the muliple popper only
  return <Popper {...props} data-multiple="true" style={{ minWidth: 250 }} />;
};

export default function QuoteLocationForm({ onChange = () => { }, isUpdate = false, cartCarLocation, setIsPricesLoading = () => { }, isCallback = false, className }) {
  const classes = useStyles();
  const theme = useTheme();

  const isSmallMedia = useMediaQuery(theme.breakpoints.down("sm"));

  const client = useApolloClient();

  // cars, customerPickupLocation, customerPickupDate, customerLeaseMonths;

  const { data, loading, error } = useQuery(GET_CARS_VIEW_CUSTOMER);
  // problem is cars isn't client data - CLIENT.readqu..

  // react hooks

  // value of the dropoff input text, not sure how to get an initial value here
  const [pickupInputValue, setPickupInputValue] = React.useState("");
  const [dropoffInputValue, setDropoffInputValue] = React.useState(
    data ? data.customerDropoffLocation : "West Nyack, NY"
  );

  const [
    requeryLeasequote,
    { loading: requeryLeasequoteLoading, error: requeryLeasequoteError },
  ] = useMutation(REQUERY_LEASEQUOTE, {
    onCompleted({ requeryLeasequote }) {
      onChange(requeryLeasequote.viewCars);
    }
  });

  const [
    updateCustomerPickupLocationsRequery,
    { loading: pickupLocationLoading, error: pickupLocationError },
  ] = useMutation(UPDATE_PICKUP_LOCATIONS, {
    refetchQueries: [{ query: isCallback ? GET_CARS_LEASEQUOTE : GET_CARS_LEASEQUOTEHIGHLIGHTS }],
    // viewCars is passed to this mutation function, updateCustomerPickupLocationsRequery
    // so then why do we need the refetch query at all? GET_CARS_LEASEQUOTE is called in the requeryLeasequote resolver mutation
    // doesn't work without the refetch query
    // maybe we need the refetch to actually perform the refetch otherwise requery gets stale data?
    awaitRefetchQueries: true,
    onCompleted({ updateCustomerPickupLocations }) {
      // requeryLeasequpte queries for cars and then assigns the leaseQuote value to each of the viewCars
      // function doesn't update viewCars without both the refetchQueries and the onCompleted
      requeryLeasequote({
        variables: { viewCars: updateCustomerPickupLocations.viewCars },
      });
    },
  });

  const [
    updateCustomerDropoffLocationRequery,
    {
      loading: addRemoveDropoffLocationLoading,
      error: addRemoveDropoffLocationError,
    },
  ] = useMutation(UPDATE_DROPOFF_LOCATION, {
    refetchQueries: [{ query: isCallback ? GET_CARS_LEASEQUOTE : GET_CARS_LEASEQUOTEHIGHLIGHTS }],
    awaitRefetchQueries: true,
    onCompleted({ updateCustomerDropoffLocation }) {
      console.log("isCallback")
      console.log(isCallback)
      // this is supposed to be the complete viewCars as queried in data (GET_CARS_VIEW_CUSTOMER) and passed to this function in the handler
      requeryLeasequote({
        variables: { viewCars: updateCustomerDropoffLocation.viewCars },
      });
    },
  });

  // coppied from customer-form
  const [sortCars, { loading: sortLoading, error: sortError }] = useMutation(
    SORT_CARS, { onCompleted() { setIsPricesLoading(false) } }
  );

  if (loading) return <Loading />;
  if (error) return <p>ERROR</p>;

  // seed variables
  // is this getting redefined everytime we requery leaseQuote on cars
  const carLocations = [...new Set(data.cars.map((car) => car.location))];

  const updatePickupLocationsSort = async (customerPickupLocations) => {
    // function to handle updating location data and possibly resorting viewCars, repeated in two handlers
    const locationRequeryResponse = await updateCustomerPickupLocationsRequery({
      variables: {
        customerPickupLocations,
        viewCars: data.viewCars,
      },
    });

    // run sortCars here with response.  first query for sortAttribute
    const { sortAttribute, isSortReverse } = await client.readQuery({
      query: GET_SORT,
    });

    // will firstMonth become an option?  list of attributes that necescitate sort in constants?
    if (sortAttribute === "leaseTotal") {
      sortCars({
        variables: {
          cars:
            locationRequeryResponse.data.updateCustomerPickupLocations.viewCars,
          sortAttribute,
          isSortReverse,
        },
      });
    } else {
      setIsPricesLoading(false)
    }
  };

  // handlers

  const handlePickupLocationsChange = (locations) => {
    setIsPricesLoading(true)
    updatePickupLocationsSort(locations);
  };

  // how about the question of retaining the customerPickupLocations as the user clicks to the map view or to a car, we'll need to make use of the initial.. props

  const handleDropoffLocationChange = async (location) => {

    setIsPricesLoading(true)
    console.log(location)
    setDropoffInputValue(location);
    // removing, now in component
    // if (reason === "clear" || value === null) {
    //   return;
    // }
    const locationRequeryResponse = await updateCustomerDropoffLocationRequery({
      variables: {
        // customerDropoffLocation: value.description,
        customerDropoffLocation: location,
        viewCars: data.viewCars,
      },
    });

    // I don't think we need to actually retain the dropoffText stuff render-to-render
    // client.writeData({ data: { dropoffText: value.description } });

    // run sortCars here with response.  first query for sortAttribute
    const { sortAttribute, isSortReverse } = await client.readQuery({
      query: GET_SORT,
    });

    // will firstMonth become an option?
    if (sortAttribute === "leaseTotal") {
      sortCars({
        variables: {
          cars:
            locationRequeryResponse.data.updateCustomerDropoffLocation.viewCars,
          sortAttribute,
          isSortReverse,
        },
      });
    } else {
      setIsPricesLoading(false)
    }
  };

  const handlePickupInputChange = (event, value, reason) => {
    // fired when textinput changes
    if (reason === "clear") {
      // clear inputValue if the "x" is clicked
      setPickupInputValue("");
      return;
    }
    if (reason === "reset") return;

    setPickupInputValue(event.target.value);
  };

  const handleDropoffInputChange = (event, value, reason) => {
    // fired when textinput changes
    if (reason === "clear") {
      // clear inputValue if the "x" is clicked
      setDropoffInputValue("");
      // clear button does not trigger an onChange event, set the location back to the "default"
      // consider invalidating the field here or moving the cursor to the close to.. placeholder
      handleDropoffLocationChange("West Nyack, NY");
      return;
    }
    if (reason === "reset") return;

    setDropoffInputValue(event.target.value);
  };

  const handlePickupMarkerClick = async (event, marker) => {
    // should this logic be in a mutation?
    // leaving the isPricesLoading call off for now
    const { customerPickupLocations } = await client.readQuery({
      query: GET_CUSTOMER,
    });

    const location = marker.description;

    // filter or push in place
    updatePickupLocationsSort(
      customerPickupLocations.includes(location)
        ? customerPickupLocations.filter((l) => l !== location)
        : [...customerPickupLocations, location]
    );
  };

  const handleDropoffMarkerClick = (event, marker) => {
    const location = marker.description;

    handleDropoffLocationChange(location);
    setDropoffInputValue(location);
  };

  console.log(data.viewField)

  return (
    <Fragment>
      <Box className={className ? classes.switchbankRow + " " + className : classes.switchbankRow}>
        <IconMenu
          className={classes.switchbankIconMenu}
          options={[
            {
              name: "Choose pickup locations on map",
              value: "pickupLocationsMap",
              icon: <MapIcon />,
            },
            {
              name: "Choose return location on map",
              value: "dropoffLocationsMap",
              icon: <MapIcon />,
            },
          ]}
          onChange={(event, value) =>
            client.writeData({ data: { viewField: value } })
          }
          icon={<LocationOnIcon />}
        />
        <Box className={classes.switchbankBox}>
          <Box className={classes.locationAutocompletesBox}>
            {/* box to contain width-defined Autocompletes */}
            <PlacesAutocompleteClosest
              autocompleteClassName={classes.pickupAutocomplete}
              locationValue={data.customerPickupLocations}
              inputValue={pickupInputValue}
              onChange={handlePickupLocationsChange}
              onInputChange={handlePickupInputChange}
              // open={true}
              // for css/testing only
              onUseMapClick={() => {
                client.writeData({ data: { viewField: "pickupLocationsMap" } });
              }}
              filterOptions={(option) => option}
              multiple={true}
              inputLabel="Pickup location(s)"
              inputPlaceholder="Close to..."
              // initialLocationValue={data.customerPickupLocations}
              // initialLocationValue={["Newark, NJ"]}
              carLocations={isUpdate ? [cartCarLocation] : carLocations}
              inputType={"customerPickupLocations"}
              writeCustomerCoordinates={(lat, lng) =>
                client.writeData({
                  data: {
                    customerCoordinates: {
                      lat,
                      lng,
                      __typename: "Coordinates",
                    },
                  },
                })
              }
              // add a breakpoint
              PopperComponent={isSmallMedia ? WidePopperMultiple : undefined}
            />
            {/* dropoff autocomplete */}
            {/* check source code and figure out what makes the display update */}
            <PlacesAutocompleteClosest
              autocompleteClassName={
                classes.dropoffAutocomplete +
                " " +
                classes.overlappingAutocomplete
              }
              inputValue={dropoffInputValue}
              initialInputValue={data.customerDropoffLocation}
              locationValue={data.customerDropoffLocation}
              onChange={handleDropoffLocationChange}
              onInputChange={handleDropoffInputChange}
              // open={true}
              onUseMapClick={() => {
                client.writeData({
                  data: { viewField: "dropoffLocationsMap" },
                });
              }}
              filterOptions={(option) => option}
              multiple={false}
              inputLabel="Return location"
              inputPlaceholder="Close to..."
              inputType={"customerDropoffLocation"}
              // why isn't this updated to data.cars with set
              // shouldn't need this, it's not use for dropoffLocations
              // carLocations={carLocations}
              // introduce logic to check on whether this has been set already, in a way that's a little more "for sure"
              PopperComponent={isSmallMedia ? WidePopper : undefined}
            />
          </Box>
        </Box>
      </Box>
      {/* pickup and dropoff locations map */}
      {/* don't render this ahead of time - trade off some responsiveness to calling up the map for some overall speed */}
      {(data.viewField === "pickupLocationsMap" ||
        data.viewField === "dropoffLocationsMap") && <Box
          className={classes.locationsMapPage}
        >
          <UpdateBar
            // elevation to mimic the zoom control, think this is a little "higher" but close
            onBackClick={() => client.writeData({ data: { viewField: null } })} elevation={2} classes={{ root: classes.mapUpdateBar }}
          />
          <Box className={classes.locationsMapBox}>
            {/* performance */}
            <LocationsMap
              locations={locations}
              value={
                data.viewField === "pickupLocationsMap"
                  ? data.customerPickupLocations
                  : data.customerDropoffLocation
              }
              onMarkerClick={
                data.viewField === "pickupLocationsMap"
                  ? handlePickupMarkerClick
                  : handleDropoffMarkerClick
              }
              mapType={
                data.viewField === "pickupLocationsMap" ||
                  data.viewField === "dropooffLocationsMap"
                  ? data.viewField
                  : "pickupLocationsMap"
              }
              multiple={data.viewField === "pickupLocationsMap"}
              carLocations={
                data.viewField === "pickupLocationsMap" ? carLocations : undefined
              }
              customerCoordinates={data.customerCoordinates}
              boxClasses={classes.locationsMap}
            />
          </Box>
        </Box>}
    </Fragment>
  );
}