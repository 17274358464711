import React from "react";
import { CircularProgress, Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

  // loading: { width: "50%", margin: "0 auto" },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: 40,
    marginBottom: 40,
    // position: "absolute",
    // top: 80,
    // minHeight: "100vh"
  },
  // base 40 + 64 appBar height - 12 offset
  uncontainedContainer: { marginTop: 92 }
}));

export default function Loading({ isUncontained = false }) {
  const classes = useStyles();
  return <Box className={isUncontained ? classes.uncontainedContainer + " " + classes.loadingContainer : classes.loadingContainer}> <CircularProgress size={30} /></Box >
};
