import React, { Fragment } from "react";
import { Match } from "@reach/router"
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    flexGrow: 2,
    width: "100%",
    // maxWidth: isWide => isWide ? theme.wideSiteWidth : theme.siteWidth,
    margin: "0 auto",
    // top padding is 0
    padding: `0 ${theme.spacing(1) * 1.5}px`,
    background: "#fff"
  },
  normalPageContainer: { maxWidth: theme.siteWidth },
  widePageContainer: { maxWidth: theme.wideSiteWidth },
  updatePageContainer: {
    maxWidth: theme.mediumPageBox.maxWidth,
  },
  // this works with a transparent AppBar only really
  // headedPageContainer: {
  //   paddingTop: 0,
  // }
}));

export default function PageContainer({ children, className, isWide = false, ...other }) {
  const classes = useStyles(isWide);

  return (
    <Match path="/update/*">
      {props => !props.match ?
        // don't know what's going on here with props.match reversed
        <Box className={`${classes.pageContainer} ${classes.normalPageContainer} ${className}`} {...other}>{children}</Box>
        :
        <Box className={`${classes.pageContainer} ${classes.updatePageContainer} ${className}`} {...other}>{children}</Box>
      }
    </Match >
  );
}

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

// const Bar = styled("div")({
//   flexShrink: 0,
//   height: 12,
//   backgroundColor: colors.primary,
// });

// const Container = styled("div")({
//   // display: "flex",
//   // flexDirection: "column",
//   // flexGrow: 1,
//   width: "100%",
//   maxWidth: 960,
//   margin: "0 auto",
//   padding: theme.spacing(2),
//   paddingBottom: unit * 5,
// });
