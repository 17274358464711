import React, { Fragment } from "react";
import { Match } from "@reach/router"
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  narrowContainer: {
    maxWidth: theme.narrowContainer,
  },
}));

export default function NarrowContainer(containerProps) {
  const classes = useStyles();
  return <Box className={classes.narrowContainer}>{containerProps.children}</Box>
}