import React from "react";
// import { Router, Link as RouterLink } from "@reach/router";
import { Link as RouterLink } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import PageHeading from "../components/page-heading";
import { Typography, Box, CardContent, Card, Grid } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Carousel from 'react-material-ui-carousel'
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import { happyCustomerPhotosReviews, customerReviews } from "../constants";


const useStyles = makeStyles((theme) => ({
  carousel: { marginBottom: 5 },
  image: { width: "100%" },
  indicatorBox: {
    position: "absolute",
    bottom: 0,
    marginTop: 0,
    marginBottom: 8
  },
  activeIndicator: { color: "#fff !important" },
  inactiveIndicator: {
    "&:hover": { color: "#ccc" },
  },
  reviewBox: {
    margin: "0 16px",
    marginBottom: 8
  },
  reviewLetterBox: {
    marginBottom: 11
  },
  review: { textAlign: "center" },
  signoff: { textAlign: "center", marginBottom: 6 },
  placardBox: { textAlign: isCentered => isCentered ? "center" : "left" },
  filledStar: { color: theme.skipsYellow },
  reviewCard: { backgroundColor: theme.backgroundGrey },
  reviewTitle: { fontSize: "1.1rem" },
  letterBoxReview: { fontSize: "0.95rem", marginBottom: 6 },

  narrowPageBox: theme.narrowPageBox,
  subsectionEndBox: theme.subsectionEndBox
}));

export function StarPlacard({ starCount, starSize = "large", isCentered = false }) {
  const classes = useStyles(isCentered);

  return (
    <Box className={classes.placardBox}>
      {Array.from(Array(starCount)).map(element =>
        <StarRoundedIcon classes={{ root: classes.filledStar }} fontSize={starSize} />
      )}
    </Box>
  )
}

export default function HappyCustomers({ ...other }) {
  const classes = useStyles();

  const [carouselIndex, setCarouselIndex] = React.useState(0);
  // some time-based metric here or just switch it once the customer changes

  const orderedHappyCustomerPhotosReviews = Math.random() < 0.5 ? happyCustomerPhotosReviews : happyCustomerPhotosReviews.reverse()

  return (
    <Box className={classes.narrowPageBox}>
      <PageHeading isHeadline={true} subheading='"100 friends made"'>Happy customers</PageHeading>
      <Grid container spacing={3}>
        <Grid item sm={8}>
          <Carousel autoPlay={false} navButtonsAlwaysInvisible={true} className={classes.carousel} onChange={(index) => setCarouselIndex(index)} activeIndicatorProps={{ className: classes.activeIndicator }} indicatorProps={{ className: classes.inactiveIndicator }} classes={{ indicators: classes.indicatorBox }} >
            {/* vary this order, easier than trying to set an index prop that has to change when the customer makes an input */}
            {orderedHappyCustomerPhotosReviews.map((reviewObject, i) => <Box><img
              src={reviewObject.imageUri}
              className={classes.image}
              alt={"customer"}
            /></Box>)}
          </Carousel>
          <Box className={classes.subsectionEndBox}>
            <Box className={classes.reviewBox}>
              <Typography className={classes.review}>{`"${happyCustomerPhotosReviews[carouselIndex].review}"`}</Typography>
            </Box>
            <Typography className={classes.signoff}>{"- " + happyCustomerPhotosReviews[carouselIndex].signoff}</Typography>
            <StarPlacard starCount={happyCustomerPhotosReviews[carouselIndex].stars} isCentered={true} />
          </Box>
          <Box className={classes.subsectionEndBox}>
            {customerReviews.map(reviewObject =>
              <Box className={classes.reviewLetterBox}>
                <Typography variant="h6" className={classes.reviewTitle}>{reviewObject.title}</Typography>
                <Typography className={classes.letterBoxReview}>{reviewObject.review}</Typography>
                <Typography className={classes.letterBoxReview}>{"- " + reviewObject.name}</Typography>
                <StarPlacard starCount={reviewObject.stars} starSize="default" isCentered={false} />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Card className={classes.reviewCard}>
            <CardContent>
              <Typography variant="h6">More reviews</Typography>
              <Typography>
                <Link to="https://www.yelp.com/biz/skips-west-nyack" component={RouterLink}>See our Yelp page</Link>
              </Typography>
              <Typography>
                <Link to="https://www.facebook.com/driveSKIPS/" component={RouterLink}>See our Facebook page</Link>
              </Typography>
              <Typography>
                <Link to="https://www.cargurus.com/Cars/m-Skips-sp390284" component={RouterLink}>See our CarGurus page</Link>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
