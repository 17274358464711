import React, { Fragment } from "react";
import { Link } from "@reach/router"
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  logo: {
    fontFamily: "Bangers, Roboto",
    color: theme.skipsYellow,
    fontWeight: 700,
    letterSpacing: 0,
    fontSize: fontSize => fontSize
  },
  logoBox: {
    pointerEvents: "none",
    display: "inline"
  }
}));

export default function ChooseLease({ fontSize = 22, className }) {
  return <Typography>
    <Link to="/leases">Choose a lease</Link>
  </Typography>
}