import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  aspectBox: {
    width: "100%",
    height: 0,
    overflow: "hidden",
    paddingTop: aspectRatio => `${aspectRatio}%`,
    position: "relative",
  },
  imageContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // grey background until the image is loaded
    backgroundColor: theme.backgroundPlaceholderGrey,
  },
  imageContainerGradient: {
    '&::after': {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: `linear-gradient(rgba(0,0,0,0) 94%, ${theme.pavement})`,
      zIndex: 1,
    }
  },
  image: {
    width: "100%",
    top: "50%",
    transform: "translateY(-50%)",
    position: "absolute",
    left: 0,
  },
  carImageContainer: { position: "relative" },
}));

export default function AspectImage({ url, aspectRatio = 75, isGradient = false, imageNumber, className, alt = "car" }) {
  const classes = useStyles(aspectRatio);
  // no longer need all this - url comes over with car
  // // const addressString =
  // //   "../assets/images/cars/" +
  // //   car.make.replace(" ", "-").toLowerCase() +
  // //   "-" +
  // //   car.model.replace(" ", "-").toLowerCase() +
  // //   "-" +
  // //   car.vin.slice(8, 16).toLowerCase() +
  // //   "/image-0.jpg";
  // const carHandle = car ? `${car.make
  //   .replace(" ", "-")
  //   .toLowerCase()}-${car.model
  //     .replace(" ", "-")
  //     .toLowerCase()}-${car.vin.slice(9, 17).toLowerCase()}` : null;
  // const carFileName = car ? carHandle + "-lead" : null
  // // const fileName = carDirectory;
  // let leadImage = "";
  // const carUrl = "https://skips-virginia.s3.amazonaws.com/" + carHandle + "-image-" + imageNumber + ".jpg"

  // this require approach doesn't seem to work when the file's not on the server
  // try {
  //   leadImage = car ? require(carUrl) : require(url);
  // } catch (error) {
  //   leadImage = defaultImage;
  // }  

  // const addDefaultSrc = (e) => {
  //   e.target.src = { defaultImage };
  //   e.target.onerror = null;
  // };

  return (
    <Box className={className ? classes.carImageContainer + " " + className : classes.carImageContainer}>
      <Box className={classes.aspectBoxContainer}>
        <Box className={classes.aspectBox}>
          <Box className={isGradient ? classes.imageContainer + " " + classes.imageContainerGradient : classes.imageContainer}>
            <img
              src={url}
              className={classes.image}
              alt={alt}
            />
          </Box>
        </Box>
      </Box>
    </Box >
  );
}
