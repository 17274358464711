import format from "date-fns/format";

export const MAXTOTAL = 15000;
export const MAXPRICE = 75000;
export const MAXMILEAGE = 100000;
export const DEFAULTVALUE = 999999;

export const LEASE_DEPOSIT = 50;

export const INCLUDED_MILES = 1500;

// not using this just yet, maybe we offer a discount for DIY pickup, this should probably happen in the leaseQuote resolver
export const FREE_OFFSITE_TRANSPORT_THRESHOLD = 200;

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const menuPages = [
  { name: "Flexible leasing", page: "" },
  { name: "Find a flex lease", page: "leases" },
  // { name: "Leases", page: "leases" },
  // { name: "Happy customers", page: "happy-customers" },
  { name: "How it works", page: "how-it-works" },
  { name: "Faq", page: "faq" },
  { name: "Insurance requirements", page: "insurance-requirements" },
  { name: "Happy customers", page: "happy-customers" },
  { name: "About us", page: "about-us" },
  { name: "Contact us", page: "contact-us" },
];

export const backPages = [
  { name: "Privacy", page: "privacy" },
  { name: "Terms", page: "terms" },
];

// these objects need to match all fields in filtersInitial below`
export const filterAttributesNames = [
  { attribute: "type", name: "Vehicle type" },
  { attribute: "size", name: "Size" },
  { attribute: "makeModel", name: "Make and model" },
  { attribute: "exteriorColor", name: "Color" },
  { attribute: "drivetrain", name: "Drivetrain" },
];

export const numericalAttributesNames = [
  {
    attribute: "mileage",
    name: "Mileage",
    defaultValue: MAXMILEAGE,
    isPrice: false,
    labelSuffix: "miles",
  },
  // we're using stateCars in quote-filter-form and only setting it once
  // { attribute: "leaseTotal", name: "Lease total", defaultValue: MAXTOTAL, isPrice: true, labelSuffix: },
  {
    attribute: "leaseFirst",
    name: "First Month payment",
    defaultValue: MAXTOTAL,
    isPrice: true,
    labelSuffix: "First Month",
  },
  {
    attribute: "leaseMonthly",
    name: "Monthly payment",
    defaultValue: MAXTOTAL,
    isPrice: true,
    labelSuffix: "monthly",
  },
  {
    attribute: "retailPrice",
    name: "Price",
    defaultValue: MAXPRICE,
    isPrice: true,
    labelSuffix: "",
  },
];

export const booleanAttributesNames = [
  {
    attribute: "isInStockDiscount",
    name: "Show Only In-Stock Discount Leases",
    heading: "In-Stock Discount",
    defaultValue: false,
  },
];

export const filterAttributes = filterAttributesNames.map(
  (attributeObject) => attributeObject.attribute
);
export const numericalAttributes = numericalAttributesNames.map(
  (attributeObject) => attributeObject.attribute
);
export const booleanAttributes = booleanAttributesNames.map(
  (attributeObject) => attributeObject.attribute
);

export const sizes = ["Subcompact", "Small", "Medium", "Large"];

export const suggestedModels = ["Mitsubishi:Outlander", "Toyota:Tacoma"];

export const filtersInitial = {
  makeModel: [],
  make: [],
  mileage: MAXMILEAGE,
  type: [],
  size: [],
  exteriorColor: [],
  // interiorColor: [],
  drivetrain: [],
  // size: [],
  retailPrice: MAXPRICE,
  // leaseTotal: MAXTOTAL,
  leaseFirst: MAXTOTAL,
  leaseMonthly: MAXTOTAL,
  isInStockDiscount: false,
  __typename: "Filters",
};

export const cartLeaseInitial = {
  pickupLocation: null,
  dropoffLocation: null,
  transportCost: null,
  secondLegFee: null,
  dropoffLegFee: null,
  leaseTotal: null,
  firstMonthSalesTax: null,
  firstMonthRentalTax: null,
  monthlySalesTax: null,
  monthlyRentalTax: null,
  proration: null,
  dropoffLegSalesTax: null,
  dropoffLegRentalTax: null,
  paymentsTotal: null,
  excessMileageDollars: null,
  leaseFirst: null,
  leaseMonthly: null,
  customerPickupDate: null,
  customerLeaseMonths: null,
  vin: null,
  yearMakeModel: null,
  insuranceMonthly: null,
  insuranceAccuracy: null,
  customerTaxRate: null,
  taxRateAccuracy: null,
  customerState: null,
  stateAccuracy: null,
  createdDate: null,
  __typename: "Lease",
};

export const customerDropoffLocationInitial = "West Nyack, NY";

// need to translate all these to state abreviations

export const dropoffLocations = [
  "West Nyack, New York",
  "River Edge, New Jersey",
  "Secaucus, New Jersey",
  "Woodbridge, New Jersey",
  "New Brunswick, New Jersey",
  "Trenton, New Jersey",
  "Philadelphia, Pennsylvania",
  "Holmesburg, New Jersey",
  "Wilmington, Delaware",
];

export const newYorkTaxJurisdictions = [
  "Albany",
  "Allegany",
  "Broome",
  "Cattaraugus",
  "Cattaraugus - Olean",
  "Cattaraugus - Salamanca",
  "Cayuga",
  "Cayuga - Auburn",
  "Chautauqua",
  "Chemung",
  "Chenango",
  "Chenango - Norwich",
  "Clinton",
  "Columbia",
  "Cortland",
  "Delaware",
  "Dutchess",
  "Erie",
  "Essex",
  "Franklin",
  "Fulton",
  "Fulton - Gloversville",
  "Fulton - Johnstown",
  "Genesee",
  "Greene",
  "Hamilton",
  "Herkimer",
  "Jefferson",
  "Lewis",
  "Livingston",
  "Madison",
  "Madison - Oneida",
  "Monroe",
  "Montgomery",
  "Nassau",
  "New York City",
  "Niagara",
  "Oneida",
  "Oneida - Rome",
  "Oneida - Utica",
  "Onondaga",
  "Ontario",
  "Orange",
  "Orleans",
  "Oswego",
  "Oswego - Oswego",
  "Otsego",
  "Putnam",
  "Rensselaer",
  "Rockland",
  "Saratoga",
  "Saratoga - Saratoga Springs",
  "Schenectady",
  "Schoharie",
  "Schuyler",
  "Seneca",
  "St. Lawrence",
  "Steuben",
  "Suffolk",
  "Sullivan",
  "Tioga",
  "Tompkins",
  "Tompkins - Ithaca",
  "Ulster",
  "Warren",
  "Warren - Glens Falls",
  "Washington",
  "Wayne",
  "Westchester",
  "Westchester - Mount Vernon",
  "Westchester - New Rochelle",
  "Westchester - White Plains",
  "Westchester - Yonkers",
  "Wyoming",
  "Yates",
];

export const happyCustomerPhotosReviews = [
  {
    imageUri: "https://skips-assets-virginia.s3.amazonaws.com/ed-wide.jpg",
    review:
      "Very impressive service. My car was delivered right to my door just in time for a family getaway! The price was unbeatable too, about $2,000 less than what I would have paid elsewhere.",
    reviewHighlight: "Very impressive service...",
    signoff: "Ed from Philadelphia",
    stars: 5,
  },
  {
    imageUri: "https://skips-assets-virginia.s3.amazonaws.com/tamir-wide.jpg",
    review:
      "Max was able to find me an even better vehicle for a great price. They certified it, delivered it, and I am one happy customer.",
    reviewHighlight: "...I am one happy customer",
    signoff: "Tamir from Danbury",
    stars: 5,
  },
];

export const customerReviews = [
  {
    review:
      "It was such a pleasure to work with Max (Skip’s). I got a great car at an affordable price and the car was delivered right to my driveway. I would highly recommend Skip’s to anyone looking for superior customer service, a high quality vehicle, and affordability! My family is in love with our new Mitsubishi Outlander!! Thanks Max!!!",
    name: "LaDonna",
    title: "Couldn’t have asked for a better experience!!",
    stars: 5,
  },
  {
    review:
      "I reached out to Skip's hoping they would find me a Nissan NV with a high roof and less than 50,000 miles. After dragging my feet and missing out on his first option, Max was able to find me an even better vehicle for a great price. They certified it, delivered it, and I am one happy customer. Max answered all of my questions promptly and is truly unlike any other dealer I have worked with. I would absolutely recommend Skip's to my friends and family.",
    name: "Tamir Nir",
    title: "Highly recommended",
    stars: 5,
  },
  {
    review:
      "Very impressive service. My car was delivered right to my door just in time for a family getaway! The price was unbeatable too, about $2,000 less than what I would have paid elsewhere.",
    name: "Ed",
    title: "I'd do it again; my wife's car is next!",
    stars: 5,
  },
  {
    review:
      "Skip's is a terrific service. The (very clean) car I rented was great and the price was fair. But the customer care that I received from Max is the defining feature of my experience. I will definitely rent from Skip's again and feel very fortunate to have learned about this company.",
    name: "Gregg",
    title: "Skip's gets an A+",
    stars: 5,
  },
];

// should this be another table accessible by api
export const transportCosts = {
  "Boston, Massachusetts": 100,
  "Ft Lauderdale, Florida": 300,
  "Ft Myers, Florida": 300,
  "Hartford, Connecticut": 50,
  "Jacksonville, Florida": 300,
  "Louisville, Kentucky": 400,
  "Miami, Florida": 300,
  "Newark, New Jersey": 0,
  "Orlando, Florida": 300,
  "Pensacola, Florida": 300,
  "Philadelphia, Pennsylvania": 50,
  "Pittsburgh, Pennsylvania": 150,
  "Providence, Rhode Island": 150,
  "Tallahassee, Florida": 300,
  "Tampa, Florida": 300,
  "West Palm, Florida": 300,
  "Buffalo, New York": 200,
  "Chicago Ohare, Illinois": 350,
  "Chicago Midway, Illinois": 350,
  "Washington Dc, District of Columbia": 50,
  "Milwaukee, Wisconsin": 350,
  "Las Vegas, Nevada": 500,
  "Raleigh, NC": 300,
  "Reno, Nevada": 600,
  "Baltimore, Maryland": 100,
  "Cleveland, Ohio": 200,
  "Detroit, Michigan": 400,
  "Grand Rapids , Michigan": 400,
  "Atlanta, Georgia": 450,
  "Dallas, Texas": 600,
  "Seattle, Washington": 700,
  "San Diego, California": 900,
  "Minneapolis, Minnesota": 400,
  "Santa Ana, California": 900,
  "Columbus, Ohio": 300,
  "Denver, Colorado": 900,
  "Houston Intercontinental, Texas": 800,
  "San Francisco, California": 1100,
  "Indianapolis, Indiana": 300,
  "Parsippany, New Jersey": 0,
  "Sacramento, California": 1100,
  "Austin, Texas": 500,
  "Richmond, Virginia": 150,
  "Savannah, Georgia": 400,
  "Dayton, Ohio": 350,
  "Charlotte, North Carolina": 350,
  "Memphis, Tennessee": 450,
  "Nashville, Tennessee": 400,
  "Norfolk, Virginia": 250,
  "Charleston, South Carolina": 400,
  "Syracuse, New York": 300,
  "Greensboro, North Carolina": 350,
  "Kansas City, Missouri": 700,
  "San Jose, California": 1100,
  "Birmingham, Alabama": 700,
  "West Nyack, New York": 0,
  "River Edge, New Jersey": 50,
  "Secaucus, New Jersey": 50,
  "Woodbridge, New Jersey": 50,
  "New Brunswick, New Jersey": 50,
  "Trenton, New Jersey": 50,
  "Holmesburg, New Jersey": 50,
  "Wilmington, Delaware": 100,
};

export const coordinates = {
  "Boston, Massachusetts": { latitude: 42.370567, longitude: -71.026964 },
  "Ft Lauderdale, Florida": { latitude: 26.121323, longitude: -80.127909 },
  "Ft Myers, Florida": { latitude: 26.621074, longitude: -81.878139 },
  "Hartford, Connecticut": { latitude: 41.78007, longitude: -72.677099 },
  "Jacksonville, Florida": { latitude: 30.337538, longitude: -81.768622 },
  "Louisville, Kentucky": { latitude: 38.188962, longitude: -85.676819 },
  "Miami, Florida": { latitude: 25.558428, longitude: -80.458168 },
  "Newark, New Jersey": { latitude: 40.736101, longitude: -74.22509 },
  "Orlando, Florida": { latitude: 28.545179, longitude: -81.373291 },
  "Pensacola, Florida": { latitude: 30.428781, longitude: -87.222516 },
  "Philadelphia, Pennsylvania": { latitude: 40.001811, longitude: -75.11787 },
  "Pittsburgh, Pennsylvania": { latitude: 40.47441, longitude: -79.950968 },
  "Providence, Rhode Island": { latitude: 41.82275, longitude: -71.414451 },
  "Tallahassee, Florida": { latitude: 30.427753, longitude: -84.258472 },
  "Tampa, Florida": { latitude: 27.996097, longitude: -82.582035 },
  "West Palm, Florida": { latitude: 26.715342, longitude: -80.053375 },
  "Buffalo, New York": { latitude: 42.89606, longitude: -78.886424 },
  "Chicago Ohare, Illinois": { latitude: 41.88531, longitude: -87.62213 },
  "Chicago Midway, Illinois": { latitude: 39.911288, longitude: -87.935007 },
  "Washington Dc, District of Columbia": {
    latitude: 38.911936,
    longitude: -77.016719,
  },
  "Milwaukee, Wisconsin": { latitude: 43.011264, longitude: -87.958409 },
  "Las Vegas, Nevada": { latitude: 36.17372, longitude: -115.10647 },
  "Raleigh, North Carolina": { latitude: 35.773632, longitude: -78.634457 },
  "Reno, Nevada": { latitude: 39.525749, longitude: -119.813051 },
  "Baltimore, Maryland": { latitude: 39.296536, longitude: -76.623489 },
  "Cleveland, Ohio": { latitude: 41.489381, longitude: -81.667486 },
  "Detroit, Michigan": { latitude: 42.347021, longitude: -83.060184 },
  "Grand Rapids , Michigan": { latitude: 42.984226, longitude: -85.629101 },
  "Atlanta, Georgia": { latitude: 33.844371, longitude: -84.47405 },
  "Dallas, Texas": { latitude: 32.781179, longitude: -96.790329 },
  "Seattle, Washington": { latitude: 47.432251, longitude: -121.803388 },
  "San Diego, California": { latitude: 32.724103, longitude: -117.170912 },
  "Minneapolis, Minnesota": { latitude: 44.984577, longitude: -93.269097 },
  "Santa Ana, California": { latitude: 33.74822, longitude: -117.858473 },
  "Columbus, Ohio": { latitude: 40.100924, longitude: -83.013402 },
  "Denver, Colorado": { latitude: 39.760189, longitude: -104.996553 },
  "Houston Intercontinental, Texas": {
    latitude: 29.813142,
    longitude: -95.309789,
  },
  "San Francisco, California": { latitude: 37.776646, longitude: -122.417481 },
  "Indianapolis, Indiana": { latitude: 39.775092, longitude: -86.13216 },
  "Parsippany, New Jersey": { latitude: 40.852118, longitude: -74.407706 },
  "Sacramento, California": { latitude: 38.380456, longitude: -121.555406 },
  "Austin, Texas": { latitude: 30.326374, longitude: -97.771258 },
  "Richmond, Virginia": { latitude: 37.524246, longitude: -77.493157 },
  "Savannah, Georgia": { latitude: 32.074679, longitude: -81.088261 },
  "Dayton, Ohio": { latitude: 39.750471, longitude: -84.268593 },
  "Charlotte, North Carolina": { latitude: 35.26002, longitude: -80.804151 },
  "Memphis, Tennessee": { latitude: 35.169255, longitude: -89.990415 },
  "Nashville, Tennessee": { latitude: 36.165688, longitude: -86.778098 },
  "Norfolk, Virginia": { latitude: 36.895911, longitude: -76.208521 },
  "Charleston, South Carolina": { latitude: 32.780326, longitude: -79.940844 },
  "Syracuse, New York": { latitude: 43.02143, longitude: -76.197701 },
  "Greensboro, North Carolina": { latitude: 36.069144, longitude: -79.765156 },
  "Kansas City, Missouri": { latitude: 39.102404, longitude: -94.598583 },
  "San Jose, California": { latitude: 37.189396, longitude: -121.705327 },
  "Birmingham, Alabama": { latitude: 33.456412, longitude: -86.801904 },
};

export const locations = {
  "West Nyack, NY": {
    description: "West Nyack, NY",
    latitude: 41.0965,
    longitude: -73.9729,
    transportFee: 0,
    transportTimeline: 0,
  },
  "Highland Falls, NY": {
    description: "Highland Falls, NY",
    latitude: 41.368092,
    longitude: -73.965874,
    transportFee: 50,
    transportTimeline: 0,
  },
  "Boston, MA": {
    description: "Boston, MA",
    latitude: 42.370567,
    longitude: -71.026964,
    transportFee: 350,
    transportTimeline: 2,
  },
  "Ft Lauderdale, FL": {
    description: "Ft Lauderdale, FL",
    latitude: 26.121323,
    longitude: -80.127909,
    transportFee: 1150,
    transportTimeline: 11,
  },
  "Ft Myers, FL": {
    description: "Ft Myers, FL",
    latitude: 26.621074,
    longitude: -81.878139,
    transportFee: 1250,
    transportTimeline: 11,
  },
  "Hartford, CT": {
    description: "Hartford, CT",
    latitude: 41.78007,
    longitude: -72.677099,
    transportFee: 200,
    transportTimeline: 1,
  },
  "Jacksonville, FL": {
    description: "Jacksonville, FL",
    latitude: 30.337538,
    longitude: -81.768622,
    transportFee: 950,
    transportTimeline: 9,
  },
  "Louisville, KY": {
    description: "Louisville, KY",
    latitude: 38.188962,
    longitude: -85.676819,
    transportFee: 950,
    transportTimeline: 8,
  },
  "Miami, FL": {
    description: "Miami, FL",
    latitude: 25.558428,
    longitude: -80.458168,
    transportFee: 1250,
    transportTimeline: 12,
  },
  "Newark, NJ": {
    description: "Newark, NJ",
    latitude: 40.736101,
    longitude: -74.22509,
    transportFee: 200,
    transportTimeline: 0,
  },
  "Orlando, FL": {
    description: "Orlando, FL",
    latitude: 28.545179,
    longitude: -81.373291,
    transportFee: 1050,
    transportTimeline: 10,
  },
  "Pensacola, FL": {
    description: "Pensacola, FL",
    latitude: 30.428781,
    longitude: -87.222516,
    transportFee: 1250,
    transportTimeline: 12,
  },
  "Pittsburgh, PA": {
    description: "Pittsburgh, PA",
    latitude: 40.47441,
    longitude: -79.950968,
    transportFee: 450,
    transportTimeline: 4,
  },
  "Providence, RI": {
    description: "Providence, RI",
    latitude: 41.82275,
    longitude: -71.414451,
    transportFee: 350,
    transportTimeline: 2,
  },
  "Tallahassee, FL": {
    description: "Tallahassee, FL",
    latitude: 30.427753,
    longitude: -84.258472,
    transportFee: 1050,
    transportTimeline: 10,
  },
  "Tampa, FL": {
    description: "Tampa, FL",
    latitude: 27.996097,
    longitude: -82.582035,
    transportFee: 1150,
    transportTimeline: 11,
  },
  "West Palm, FL": {
    description: "West Palm, FL",
    latitude: 26.715342,
    longitude: -80.053375,
    transportFee: 1150,
    transportTimeline: 11,
  },
  "Buffalo, NY": {
    description: "Buffalo, NY",
    latitude: 42.89606,
    longitude: -78.886424,
    transportFee: 450,
    transportTimeline: 4,
  },
  "Chicago Ohare, IL": {
    description: "Chicago Ohare, IL",
    latitude: 41.88531,
    longitude: -87.62213,
    transportFee: 1050,
    transportTimeline: 9,
  },
  "Chicago Midway, IL": {
    description: "Chicago Midway, IL",
    latitude: 39.911288,
    longitude: -87.935007,
    transportFee: 1050,
    transportTimeline: 10,
  },
  "Washington Dc, DC": {
    description: "Washington Dc, DC",
    latitude: 38.911936,
    longitude: -77.016719,
    transportFee: 350,
    transportTimeline: 3,
  },
  "Milwaukee, WI": {
    description: "Milwaukee, WI",
    latitude: 43.011264,
    longitude: -87.958409,
    transportFee: 1050,
    transportTimeline: 10,
  },
  "Las Vegas, NV": {
    description: "Las Vegas, NV",
    latitude: 36.17372,
    longitude: -115.10647,
    transportFee: 2950,
    transportTimeline: 29,
  },
  "Raleigh, NC": {
    description: "Raleigh, NC",
    latitude: 35.773632,
    longitude: -78.634457,
    transportFee: 550,
    transportTimeline: 5,
  },
  "Reno, NV": {
    description: "Reno, NV",
    latitude: 39.525749,
    longitude: -119.813051,
    transportFee: 3250,
    transportTimeline: 32,
  },
  "Baltimore, MD": {
    description: "Baltimore, MD",
    latitude: 39.296536,
    longitude: -76.623489,
    transportFee: 350,
    transportTimeline: 2,
  },
  "Cleveland, OH": {
    description: "Cleveland, OH",
    latitude: 41.489381,
    longitude: -81.667486,
    transportFee: 650,
    transportTimeline: 5,
  },
  "Detroit, MI": {
    description: "Detroit, MI",
    latitude: 42.347021,
    longitude: -83.060184,
    transportFee: 750,
    transportTimeline: 6,
  },
  "Grand Rapids , MI": {
    description: "Grand Rapids , MI",
    latitude: 42.984226,
    longitude: -85.629101,
    transportFee: 850,
    transportTimeline: 8,
  },
  "Atlanta, GA": {
    description: "Atlanta, GA",
    latitude: 33.844371,
    longitude: -84.47405,
    transportFee: 950,
    transportTimeline: 9,
  },
  "Dallas, TX": {
    description: "Dallas, TX",
    latitude: 32.781179,
    longitude: -96.790329,
    transportFee: 1750,
    transportTimeline: 17,
  },
  "Seattle, WA": {
    description: "Seattle, WA",
    latitude: 47.432251,
    longitude: -121.803388,
    transportFee: 3350,
    transportTimeline: 33,
  },
  "San Diego, CA": {
    description: "San Diego, CA",
    latitude: 32.724103,
    longitude: -117.170912,
    transportFee: 3150,
    transportTimeline: 30,
  },
  "Minneapolis, MN": {
    description: "Minneapolis, MN",
    latitude: 44.984577,
    longitude: -93.269097,
    transportFee: 1450,
    transportTimeline: 14,
  },
  "Santa Ana, CA": {
    description: "Santa Ana, CA",
    latitude: 33.74822,
    longitude: -117.858473,
    transportFee: 3150,
    transportTimeline: 31,
  },
  "Columbus, OH": {
    description: "Columbus, OH",
    latitude: 40.100924,
    longitude: -83.013402,
    transportFee: 650,
    transportTimeline: 6,
  },
  "Denver, CO": {
    description: "Denver, CO",
    latitude: 39.760189,
    longitude: -104.996553,
    transportFee: 2250,
    transportTimeline: 21,
  },
  "Houston Intercontinental, TX": {
    description: "Houston Intercontinental, TX",
    latitude: 29.813142,
    longitude: -95.309789,
    transportFee: 1750,
    transportTimeline: 17,
  },
  "San Francisco, CA": {
    description: "San Francisco, CA",
    latitude: 37.776646,
    longitude: -122.417481,
    transportFee: 3450,
    transportTimeline: 34,
  },
  "Indianapolis, IN": {
    description: "Indianapolis, IN",
    latitude: 39.775092,
    longitude: -86.13216,
    transportFee: 950,
    transportTimeline: 8,
  },
  // temp
  "Parsippany, NJ": {
    description: "Parsippany, NJ",
    latitude: 40.852118,
    longitude: -74.407706,
    transportFee: 200,
    transportTimeline: 0,
  },
  "Sacramento, CA": {
    description: "Sacramento, CA",
    latitude: 38.380456,
    longitude: -121.555406,
    transportFee: 3350,
    transportTimeline: 33,
  },
  "Austin, TX": {
    description: "Austin, TX",
    latitude: 30.326374,
    longitude: -97.771258,
    transportFee: 1850,
    transportTimeline: 18,
  },
  "Richmond, VA": {
    description: "Richmond, VA",
    latitude: 37.524246,
    longitude: -77.493157,
    transportFee: 450,
    transportTimeline: 3,
  },
  "Savannah, GA": {
    description: "Savannah, GA",
    latitude: 32.074679,
    longitude: -81.088261,
    transportFee: 850,
    transportTimeline: 8,
  },
  "Dayton, OH": {
    description: "Dayton, OH",
    latitude: 39.750471,
    longitude: -84.268593,
    transportFee: 750,
    transportTimeline: 7,
  },
  "Charlotte, NC": {
    description: "Charlotte, NC",
    latitude: 35.26002,
    longitude: -80.804151,
    transportFee: 650,
    transportTimeline: 6,
  },
  "Memphis, TN": {
    description: "Memphis, TN",
    latitude: 35.169255,
    longitude: -89.990415,
    transportFee: 1250,
    transportTimeline: 12,
  },
  "Nashville, TN": {
    description: "Nashville, TN",
    latitude: 36.165688,
    longitude: -86.778098,
    transportFee: 1050,
    transportTimeline: 9,
  },
  "Norfolk, VA": {
    description: "Norfolk, VA",
    latitude: 36.895911,
    longitude: -76.208521,
    transportFee: 350,
    transportTimeline: 3,
  },
  "Charleston, SC": {
    description: "Charleston, SC",
    latitude: 32.780326,
    longitude: -79.940844,
    transportFee: 750,
    transportTimeline: 7,
  },
  "Syracuse, NY": {
    description: "Syracuse, NY",
    latitude: 43.02143,
    longitude: -76.197701,
    transportFee: 350,
    transportTimeline: 2,
  },
  "Greensboro, NC": {
    description: "Greensboro, NC",
    latitude: 36.069144,
    longitude: -79.765156,
    transportFee: 550,
    transportTimeline: 5,
  },
  "Kansas City, MO": {
    description: "Kansas City, MO",
    latitude: 39.102404,
    longitude: -94.598583,
    transportFee: 1450,
    transportTimeline: 14,
  },
  "San Jose, CA": {
    description: "San Jose, CA",
    latitude: 37.189396,
    longitude: -121.705327,
    transportFee: 3350,
    transportTimeline: 33,
  },
  "Birmingham, AL": {
    description: "Birmingham, AL",
    latitude: 33.456412,
    longitude: -86.801904,
    transportFee: 1150,
    transportTimeline: 10,
  },
  "Westwood, MA": {
    description: "Westwood, MA",
    latitude: 42.220548,
    longitude: -71.199238,
    transportFee: 350,
    transportTimeline: 2,
  },
  "Kingston, RI": {
    description: "Kingston, RI",
    latitude: 41.482901,
    longitude: -71.524317,
    transportFee: 350,
    transportTimeline: 2,
  },
  "Westerly, RI": {
    description: "Westerly, RI",
    latitude: 41.37224,
    longitude: -71.80694,
    transportFee: 350,
    transportTimeline: 2,
  },
  "Mystic, CT": {
    description: "Mystic, CT",
    latitude: 41.35742,
    longitude: -71.95497,
    transportFee: 350,
    transportTimeline: 1,
  },
  "New London, CT": {
    description: "New London, CT",
    latitude: 41.35565,
    longitude: -72.09952,
    transportFee: 350,
    transportTimeline: 1,
  },
  "Old Saybrook, CT": {
    description: "Old Saybrook, CT",
    latitude: 41.300448,
    longitude: -72.389129,
    transportFee: 200,
    transportTimeline: 1,
  },
  "Springfield, MA": {
    description: "Springfield, MA",
    latitude: 42.170731,
    longitude: -72.604842,
    transportFee: 200,
    transportTimeline: 1,
  },
  "Windsor Locks, CT": {
    description: "Windsor Locks, CT",
    latitude: 41.933846,
    longitude: -72.660245,
    transportFee: 200,
    transportTimeline: 1,
  },
  "Windsor, CT": {
    description: "Windsor, CT",
    latitude: 41.879637,
    longitude: -72.734271,
    transportFee: 200,
    transportTimeline: 1,
  },
  "Berlin, CT": {
    description: "Berlin, CT",
    latitude: 41.6215,
    longitude: -72.7457,
    transportFee: 200,
    transportTimeline: 1,
  },
  "Meriden, CT": {
    description: "Meriden, CT",
    latitude: 41.536498,
    longitude: -72.801901,
    transportFee: 200,
    transportTimeline: 1,
  },
  "Wallingford, CT": {
    description: "Wallingford, CT",
    latitude: 41.456205,
    longitude: -72.811047,
    transportFee: 200,
    transportTimeline: 1,
  },
  "New Haven, CT": {
    description: "New Haven, CT",
    latitude: 41.365709,
    longitude: -72.927507,
    transportFee: 200,
    transportTimeline: 1,
  },
  "Bridgeport, CT": {
    description: "Bridgeport, CT",
    latitude: 41.308873,
    longitude: -73.363661,
    transportFee: 200,
    transportTimeline: 0,
  },
  "Stamford, CT": {
    description: "Stamford, CT",
    latitude: 41.054082,
    longitude: -73.536216,
    transportFee: 200,
    transportTimeline: 0,
  },
  "New Rochelle, NY": {
    description: "New Rochelle, NY",
    latitude: 40.91757,
    longitude: -73.784858,
    transportFee: 50,
    transportTimeline: 0,
  },
  "New York, NY": {
    description: "New York, NY",
    latitude: 40.68863,
    longitude: -74.018244,
    transportFee: 200,
    transportTimeline: 0,
  },
  "Metropark, NJ": {
    description: "Metropark, NJ",
    latitude: 40.5681,
    longitude: -74.3296,
    transportFee: 200,
    transportTimeline: 1,
  },
  "New Brunswick, NJ": {
    description: "New Brunswick, NJ",
    latitude: 40.486754,
    longitude: -74.444395,
    transportFee: 200,
    transportTimeline: 1,
  },
  "Princeton Junction, NJ": {
    description: "Princeton Junction, NJ",
    latitude: 40.266858,
    longitude: -74.651101,
    transportFee: 200,
    transportTimeline: 1,
  },
  "Trenton, NJ": {
    description: "Trenton, NJ",
    latitude: 40.280531,
    longitude: -74.712018,
    transportFee: 200,
    transportTimeline: 1,
  },
  "Cornwells Heights, PA": {
    description: "Cornwells Heights, PA",
    latitude: 40.076778,
    longitude: -74.948779,
    transportFee: 200,
    transportTimeline: 1,
  },
  "North Philadelphia, PA": {
    description: "North Philadelphia, PA",
    latitude: 39.9972,
    longitude: -75.1553,
    transportFee: 200,
    transportTimeline: 1,
  },
  "Philadelphia, PA": {
    description: "Philadelphia, PA",
    latitude: 40.001811,
    longitude: -75.11787,
    transportFee: 200,
    transportTimeline: 1,
  },
  "Wilmington, DE": {
    description: "Wilmington, DE",
    latitude: 39.727113,
    longitude: -75.547844,
    transportFee: 350,
    transportTimeline: 1,
  },
  "Newark, DE": {
    description: "Newark, DE",
    latitude: 39.614737,
    longitude: -75.701277,
    transportFee: 350,
    transportTimeline: 2,
  },
  "Aberdeen, MD": {
    description: "Aberdeen, MD",
    latitude: 39.499909,
    longitude: -76.231177,
    transportFee: 350,
    transportTimeline: 2,
  },

  "New Carrollton, MD": {
    description: "New Carrollton, MD",
    latitude: 38.969833,
    longitude: -76.879973,
    transportFee: 350,
    transportTimeline: 2,
  },
  // sort out washington
  "Washington, DC": {
    description: "Washington, DC",
    latitude: 38.911936,
    longitude: -77.016719,
    transportFee: 350,
    transportTimeline: 3,
  },
  "Alexandria, VA": {
    description: "Alexandria, VA",
    latitude: 38.819853,
    longitude: -77.059645,
    transportFee: 350,
    transportTimeline: 3,
  },
  "Burke Centre, VA": {
    description: "Burke Centre, VA",
    latitude: 38.831813,
    longitude: -77.288755,
    transportFee: 350,
    transportTimeline: 3,
  },
  "Manassas, VA": {
    description: "Manassas, VA",
    latitude: 38.744685,
    longitude: -77.487162,
    transportFee: 350,
    transportTimeline: 3,
  },
  "Culpeper, VA": {
    description: "Culpeper, VA",
    latitude: 38.511694,
    longitude: -77.9928,
    transportFee: 350,
    transportTimeline: 3,
  },
  "Charlottesville, VA": {
    description: "Charlottesville, VA",
    latitude: 38.026588,
    longitude: -78.48045,
    transportFee: 450,
    transportTimeline: 4,
  },
  "Lynchburg, VA": {
    description: "Lynchburg, VA",
    latitude: 37.359607,
    longitude: -79.141653,
    transportFee: 550,
    transportTimeline: 4,
  },
  "Roanoke, VA": {
    description: "Roanoke, VA",
    latitude: 37.274175,
    longitude: -79.95786,
    transportFee: 550,
    transportTimeline: 5,
  },
  "Woodbridge, VA": {
    description: "Woodbridge, VA",
    latitude: 38.625541,
    longitude: -77.271644,
    transportFee: 350,
    transportTimeline: 3,
  },
  "Quantico, VA": {
    description: "Quantico, VA",
    latitude: 38.52602,
    longitude: -77.322803,
    transportFee: 350,
    transportTimeline: 3,
  },
  "Fredericksburg, VA": {
    description: "Fredericksburg, VA",
    latitude: 38.295135,
    longitude: -77.483689,
    transportFee: 350,
    transportTimeline: 3,
  },
  "Ashland, VA": {
    description: "Ashland, VA",
    latitude: 37.754912,
    longitude: -77.480128,
    transportFee: 450,
    transportTimeline: 3,
  },
  "Petersburg, VA": {
    description: "Petersburg, VA",
    latitude: 37.209159,
    longitude: -77.497484,
    transportFee: 450,
    transportTimeline: 4,
  },
  "Williamsburg, VA": {
    description: "Williamsburg, VA",
    latitude: 37.310451,
    longitude: -76.746769,
    transportFee: 350,
    transportTimeline: 3,
  },
  "Newport News, VA": {
    description: "Newport News, VA",
    latitude: 37.058296,
    longitude: -76.463471,
    transportFee: 350,
    transportTimeline: 3,
  },
  "Harrisburg, PA": {
    description: "Harrisburg, PA",
    latitude: 40.258655,
    longitude: -76.894376,
    transportFee: 350,
    transportTimeline: 2,
  },
  "Middletown, PA": {
    description: "Middletown, PA",
    latitude: 40.191241,
    longitude: -76.720142,
    transportFee: 350,
    transportTimeline: 2,
  },
  "Elizabethtown, PA": {
    description: "Elizabethtown, PA",
    latitude: 40.150963,
    longitude: -76.61028,
    transportFee: 350,
    transportTimeline: 2,
  },
  "Mount Joy, PA": {
    description: "Mount Joy, PA",
    latitude: 40.107241,
    longitude: -76.510775,
    transportFee: 350,
    transportTimeline: 2,
  },
  "Lancaster, PA": {
    description: "Lancaster, PA",
    latitude: 40.076553,
    longitude: -76.31068,
    transportFee: 350,
    transportTimeline: 2,
  },
  "Parkesburg, PA": {
    description: "Parkesburg, PA",
    latitude: 39.95872,
    longitude: -75.91939,
    transportFee: 350,
    transportTimeline: 2,
  },
  "Coatesville, PA": {
    description: "Coatesville, PA",
    latitude: 39.968862,
    longitude: -75.829163,
    transportFee: 350,
    transportTimeline: 1,
  },
  "Downingtown, PA": {
    description: "Downingtown, PA",
    latitude: 40.031265,
    longitude: -75.722614,
    transportFee: 350,
    transportTimeline: 1,
  },
  "Exton, PA": {
    description: "Exton, PA",
    latitude: 40.049852,
    longitude: -75.637035,
    transportFee: 350,
    transportTimeline: 1,
  },
  "Paoli, PA": {
    description: "Paoli, PA",
    latitude: 40.040057,
    longitude: -75.488793,
    transportFee: 200,
    transportTimeline: 1,
  },
  "Ardmore, PA": {
    description: "Ardmore, PA",
    latitude: 39.998811,
    longitude: -75.30793,
    transportFee: 200,
    transportTimeline: 1,
  },
  "St. Albans, VT": {
    description: "St. Albans, VT",
    latitude: 44.809326,
    longitude: -73.081499,
    transportFee: 350,
    transportTimeline: 3,
  },
  "Essex Junction, VT": {
    description: "Essex Junction, VT",
    latitude: 44.485558,
    longitude: -73.025271,
    transportFee: 350,
    transportTimeline: 2,
  },
  "Waterbury, VT": {
    description: "Waterbury, VT",
    latitude: 44.259518,
    longitude: -72.585018,
    transportFee: 350,
    transportTimeline: 2,
  },
  "Montpelier, VT": {
    description: "Montpelier, VT",
    latitude: 44.19906,
    longitude: -72.559638,
    transportFee: 350,
    transportTimeline: 2,
  },
  "Randolph, VT": {
    description: "Randolph, VT",
    latitude: 43.985803,
    longitude: -72.694098,
    transportFee: 350,
    transportTimeline: 2,
  },
  "White River Junction, VT": {
    description: "White River Junction, VT",
    latitude: 43.672188,
    longitude: -72.380193,
    transportFee: 350,
    transportTimeline: 2,
  },
  "Windsor, VT": {
    description: "Windsor, VT",
    latitude: 43.5181,
    longitude: -72.418059,
    transportFee: 350,
    transportTimeline: 2,
  },
  "Claremont, NH": {
    description: "Claremont, NH",
    latitude: 43.37674,
    longitude: -72.34676,
    transportFee: 350,
    transportTimeline: 2,
  },
  "Bellows Falls, VT": {
    description: "Bellows Falls, VT",
    latitude: 43.13341,
    longitude: -72.44398,
    transportFee: 350,
    transportTimeline: 2,
  },
  "Brattleboro, VT": {
    description: "Brattleboro, VT",
    latitude: 42.884281,
    longitude: -72.760753,
    transportFee: 350,
    transportTimeline: 1,
  },
  "Greenfield, MA": {
    description: "Greenfield, MA",
    latitude: 42.601335,
    longitude: -72.623619,
    transportFee: 350,
    transportTimeline: 1,
  },
  "Northampton, MA": {
    description: "Northampton, MA",
    latitude: 42.321488,
    longitude: -72.630588,
    transportFee: 200,
    transportTimeline: 1,
  },
  "Holyoke, MA": {
    description: "Holyoke, MA",
    latitude: 42.198291,
    longitude: -72.64207,
    transportFee: 200,
    transportTimeline: 1,
  },
  "Spring Valley, NY": {
    description: "Spring Valley, NY",
    latitude: 41.115833,
    longitude: -74.047384,
    transportFee: 50,
    transportTimeline: 0,
  },
  "Nanuet, NY": {
    description: "Nanuet, NY",
    latitude: 41.097716,
    longitude: -74.010934,
    transportFee: 50,
    transportTimeline: 0,
  },
  "Pearl River, NY": {
    description: "Pearl River, NY",
    latitude: 41.061476,
    longitude: -74.018283,
    transportFee: 50,
    transportTimeline: 0,
  },
  "Montvale, NJ": {
    description: "Montvale, NJ",
    latitude: 41.054651,
    longitude: -74.045425,
    transportFee: 50,
    transportTimeline: 0,
  },
  "Park Ridge, NJ": {
    description: "Park Ridge, NJ",
    latitude: 41.032868,
    longitude: -74.042948,
    transportFee: 50,
    transportTimeline: 0,
  },
  "Woodcliff Lake, NJ": {
    description: "Woodcliff Lake, NJ",
    latitude: 41.025977,
    longitude: -74.061061,
    transportFee: 50,
    transportTimeline: 0,
  },
  "Hillsdale, NJ": {
    description: "Hillsdale, NJ",
    latitude: 41.008367,
    longitude: -74.048576,
    transportFee: 50,
    transportTimeline: 0,
  },
  "Westwood, NJ": {
    description: "Westwood, NJ",
    latitude: 40.99121,
    longitude: -74.03264,
    transportFee: 50,
    transportTimeline: 0,
  },
  "Emerson, NJ": {
    description: "Emerson, NJ",
    latitude: 40.973199,
    longitude: -74.021118,
    transportFee: 50,
    transportTimeline: 0,
  },
  "Oradell, NJ": {
    description: "Oradell, NJ",
    latitude: 40.955347,
    longitude: -74.026983,
    transportFee: 50,
    transportTimeline: 0,
  },
  "River Edge, NJ": {
    description: "River Edge, NJ",
    latitude: 40.926248,
    longitude: -74.038533,
    transportFee: 50,
    transportTimeline: 0,
  },
  "New Bridge Landing, NJ": {
    description: "New Bridge Landing, NJ",
    latitude: 40.905099,
    longitude: -74.665162,
    transportFee: 200,
    transportTimeline: 0,
  },
  "Hackensack, NJ": {
    description: "Hackensack, NJ",
    latitude: 40.889398,
    longitude: -74.045698,
    transportFee: 50,
    transportTimeline: 0,
  },
  "Teterboro, NJ": {
    description: "Teterboro, NJ",
    latitude: 40.863954,
    longitude: -74.055584,
    transportFee: 50,
    transportTimeline: 0,
  },
  "Wood Ridge, NJ": {
    description: "Wood Ridge, NJ",
    latitude: 40.848599,
    longitude: -74.089585,
    transportFee: 50,
    transportTimeline: 0,
  },
  "Hoboken, NJ": {
    description: "Hoboken, NJ",
    latitude: 40.746851,
    longitude: -74.033934,
    transportFee: 50,
    transportTimeline: 0,
  },
  "Albany, NY": {
    description: "Albany, NY",
    latitude: 42.614852,
    longitude: -73.970812,
    transportFee: 200,
    transportTimeline: 1,
  },
  "Kingston, NY": {
    description: "Kingston, NY",
    latitude: 41.96973,
    longitude: -74.06678,
    transportFee: 200,
    transportTimeline: 1,
  },
  "Rosendale, NY": {
    description: "Rosendale, NY",
    latitude: 41.84398,
    longitude: -74.08209,
    transportFee: 200,
    transportTimeline: 1,
  },
  "New Paltz, NY": {
    description: "New Paltz, NY",
    latitude: 41.74638,
    longitude: -74.109241,
    transportFee: 200,
    transportTimeline: 0,
  },
  "White Plains, NY": {
    description: "White Plains, NY",
    latitude: 41.031397,
    longitude: -73.769626,
    transportFee: 50,
    transportTimeline: 0,
  },
  "Queens, NY": {
    description: "Queens, NY",
    latitude: 40.6503694,
    longitude: -73.7563996,
    transportFee: 200,
    transportTimeline: 0,
  },
  "Queens Village, NY": {
    description: "Queens Village, NY",
    latitude: 40.55596,
    longitude: -73.923681,
    transportFee: 200,
    transportTimeline: 0,
  },
  "Mineola, NY": {
    description: "Mineola, NY",
    latitude: 40.74927,
    longitude: -73.64068,
    transportFee: 200,
    transportTimeline: 0,
  },
  "Hempstead, NY": {
    description: "Hempstead, NY",
    latitude: 40.71724,
    longitude: -73.602746,
    transportFee: 200,
    transportTimeline: 0,
  },
  "Massapequa, NY": {
    description: "Massapequa, NY",
    latitude: 40.66766,
    longitude: -73.47076,
    transportFee: 200,
    transportTimeline: 0,
  },
  "Babylon, NY": {
    description: "Babylon, NY",
    latitude: 40.69566,
    longitude: -73.32568,
    transportFee: 200,
    transportTimeline: 1,
  },
  "Tarrytown, NY": {
    description: "Tarrytown, NY",
    latitude: 41.089703,
    longitude: -73.844034,
    transportFee: 50,
    transportTimeline: 0,
  },
  "Suffern, NY": {
    description: "Suffern, NY",
    latitude: 41.137295,
    longitude: -74.105737,
    transportFee: 50,
    transportTimeline: 0,
  },
};
