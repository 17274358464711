import React from "react";
import { Link as RouterLink } from "@reach/router";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { FormControlLabel, Checkbox, FormGroup, FormHelperText, Box } from "@material-ui/core";
import { emailRegex } from "../constants"
import { Link } from "@reach/router";

const useStyles = makeStyles((theme) => ({
  labelLink: {
    "&:not(:hover)": { textDecoration: "none" },
  },
  formElement: theme.formElement
}))

export const INSERT_LEASEQUOTE = gql`
  mutation insertLeaseQuote($lease: LeaseInput) {
    insertLeaseQuote(lease: $lease) {
      yearMakeModel
      vin
      _id
    }
  }
`;

export const SEND_LEASEQUOTE_EMAIL = gql`
  mutation sendLeasequoteEmail($recipient: String!, $leaseQuoteId: String!) {
    sendLeasequoteEmail(recipient: $recipient, leaseQuoteId: $leaseQuoteId) {
      success
      message
    }
  }
`;

export const SEND_INSURANCE_LEASEQUOTE_EMAIL = gql`
  mutation sendInsuranceLeasequoteEmail($recipient: String!, $leaseQuoteId: String!, $vin: String!, $yearMakeModel: String! ) {
    sendInsuranceLeasequoteEmail(recipient: $recipient, leaseQuoteId: $leaseQuoteId, vin: $vin, yearMakeModel: $yearMakeModel) {
      success
      message
    }
  }
`;

export const SEND_INSURANCEREQUEST_EMAIL = gql`
  mutation sendInsurancerequestEmail($leaseQuoteId: String!, $customerLicenseUri: String!, $customerEmail: String!) {
    sendInsurancerequestEmail(leaseQuoteId: $leaseQuoteId, customerLicenseUri: $customerLicenseUri, customerEmail: $customerEmail) {
      success
      message
    }
  }
`;


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function InsuranceQuoteDialog({
  open,
  // car,
  leaseProp,
  quoteId,
  closeDialog,
  customerLicenseUri,
  initialEmail,
}) {
  const [isQuoteChecked, setIsQuoteChecked] = React.useState(true);
  const [isInformationChecked, setIsInformationChecked] = React.useState(true);
  const [emailAddress, setEmailAddress] = React.useState(initialEmail || "");
  const [submitStatus, setSubmitStatus] = React.useState("open");

  const classes = useStyles();

  // should more of this logic be on the resolver?  to prevent tampering.  just a sendInsuranceEmail mutation.
  // const [sendEmail, { loading: emailLoading, error: emailError }] = useMutation(
  //   SEND_EMAIL
  // );

  const checkFormValidity = () => (emailRegex.test(emailAddress) &&
    (isQuoteChecked || isInformationChecked))


  const [
    insertLeaseQuote,
    { loading: insertLoading, error: insertError },
  ] = useMutation(INSERT_LEASEQUOTE);

  const [
    sendLeasequoteEmail,
    { loading: leasequoteLoading, error: leasequoteEmail },
  ] = useMutation(SEND_LEASEQUOTE_EMAIL);

  const [
    sendInsuranceLeasequoteEmail,
    { loading: insuranceLeasequoteLoading, error: insuranceLeasequoteEmail },
  ] = useMutation(SEND_INSURANCE_LEASEQUOTE_EMAIL);

  const [
    sendInsurancerequestEmail,
    { loading: insurancerequestLoading, error: insurancerequestEmail },
  ] = useMutation(SEND_INSURANCEREQUEST_EMAIL);

  // should more of this logic be in the resolver?  that would prevent
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setSubmitStatus("attempted");
    // first check that the form's complete and valid
    if (!checkFormValidity()) return;
    const lease = leaseProp;
    delete lease.__typename;
    const insertQuoteResponse = await insertLeaseQuote({
      // plug in the whole lease?  should the lease have the car vin too?  or some other car details?
      variables: {
        lease
      },
    });

    console.log("insertQuoteResponse");
    console.log(insertQuoteResponse);

    let requestResponse, leasequoteResponse, insuranceLeasequoteResponse

    if (isQuoteChecked && !isInformationChecked) {
      // need to prepare a quote but not send insurance information, just leaseQuote
      requestResponse = await sendInsurancerequestEmail({ variables: { leaseQuoteId: insertQuoteResponse.data.insertLeaseQuote._id, customerLicenseUri, customerEmail: emailAddress } })
      leasequoteResponse = await sendLeasequoteEmail({ variables: { recipient: emailAddress, leaseQuoteId: insertQuoteResponse.data.insertLeaseQuote._id } })
      if (!requestResponse.data.sendInsurancerequestEmail.success || !leasequoteResponse.data.sendLeasequoteEmail.success) {
        setSubmitStatus("error")
        return
      }
    } else if (isInformationChecked && !isQuoteChecked) {
      // send info only
      insuranceLeasequoteResponse = await sendInsuranceLeasequoteEmail({ variables: { recipient: emailAddress, leaseQuoteId: insertQuoteResponse.data.insertLeaseQuote._id, vin: leaseProp.vin, yearMakeModel: leaseProp.yearMakeModel } })
      if (!insuranceLeasequoteResponse.data.sendInsuranceLeasequoteEmail) {
        setSubmitStatus("error")
        return
      }
    } else {
      // both are checked, prepare a quote and send requirements too
      requestResponse = await sendInsurancerequestEmail({ variables: { leaseQuoteId: insertQuoteResponse.data.insertLeaseQuote._id, customerLicenseUri, customerEmail: emailAddress } })
      console.log("requestResponse")
      console.log(requestResponse)
      insuranceLeasequoteResponse = await sendInsuranceLeasequoteEmail({ variables: { recipient: emailAddress, leaseQuoteId: insertQuoteResponse.data.insertLeaseQuote._id, vin: leaseProp.vin, yearMakeModel: leaseProp.yearMakeModel } })
      if (!requestResponse.data.sendInsurancerequestEmail.success || !insuranceLeasequoteResponse.data.sendInsuranceLeasequoteEmail.success) {
        setSubmitStatus("error")
        return
      }
    }

    // not errored/returned
    setSubmitStatus("succeeded")

    // setFormEmailAddress(emailAddress);
    // maybe update the status here or something
  };

  return (
    <Dialog
      open={open}
      // TransitionComponent={Transition}
      keepMounted
      // onClose={() => {closeDialog()})}
      fullWidth
      // fullScreen
      PaperProps={{ style: { height: "calc(100% - 64px)", maxWidth: null } }}
    // maxWidth={"xl"}
    >
      <DialogTitle id="alert-dialog-slide-title">
        {"Let's work on an insurance policy"}
      </DialogTitle>
      {submitStatus === "error" && <DialogContent><DialogContentText>Sorry, there was a problem emailing your insurance information.  Please contact us at (347) 754 7794, we'd be happy to get this done over the phone.</DialogContentText></DialogContent>}
      {submitStatus === "succeeded" && <DialogContent>
        <DialogContentText>Thanks! We've emailed you at {emailAddress} with a link you can use to resume this reservation anytime.  You can also complete your reservation now with a deposit.</DialogContentText>
        {isQuoteChecked && <DialogContentText>We typically have insurance quotes ready within one business day.</DialogContentText>}
        {/* <DialogContentText>When you're ready to reserve your lease, you can </DialogContentText> */}
      </DialogContent>}
      {(submitStatus === "open" || submitStatus === "attempted") &&
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-slide-description">
          Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running.
        </DialogContentText> */}
          <FormControl error={(!isQuoteChecked && !isInformationChecked) &&
            submitStatus === "attempted"}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={event => setIsQuoteChecked(event.target.checked)}
                    checked={isQuoteChecked}
                  />
                }
                label="Prepare an insurance quote for my lease.  Email me the quote once it's ready."
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={event => setIsInformationChecked(event.target.checked)}
                    checked={isInformationChecked}
                  />
                }
                label={<Typography component="span">Email me the <Link component={RouterLink} className={classes.labelLink} to="/insurance-requirements">policy requirements</Link> so I can look into insurance on my own.</Typography>}
                className={classes.formElement}
              />
            </FormGroup>
            {(!isQuoteChecked && !isInformationChecked) &&
              submitStatus === "attempted" && (
                <FormHelperText>Please check one of the boxes above</FormHelperText>
              )}
            <Box className={classes.formElement}>
              <TextField

                onChange={event => setEmailAddress(event.target.value)}
                defaultValue={initialEmail}
                label="Email address"
                // think we need error here to override the FormControl error, which is just there to direct the FormHelperText
                error={
                  !emailRegex.test(emailAddress) &&
                  submitStatus === "attempted"
                }
                variant="filled"
              />
            </Box>
          </FormControl>
        </DialogContent>}
      <DialogActions>
        <Button
          onClick={() => {
            setSubmitStatus("open")
            closeDialog();
          }}
          color="primary"
        >
          {submitStatus === "succeeded" ? "Close" : "Cancel"}
        </Button>
        {/* {submitStatus === "open" &&
          <Button
            onClick={event => {
              // do we even need an event
              handleFormSubmit(event)
            }}
            color="primary"
          >
            Email me
        </Button>} */}
        {submitStatus === "error" || submitStatus === "succeeded" ?
          <Button
            onClick={event => {
              // do we even need an event
              setIsQuoteChecked(true)
              setIsInformationChecked(true)
              setEmailAddress(initialEmail || "")
              setSubmitStatus("open")
            }}
            color="primary"
          >
            Start over
          </Button> :
          <Button
            onClick={event => {
              // do we even need an event
              handleFormSubmit(event)
            }}
            color="primary"
          >
            Email me
          </Button>}
      </DialogActions >
    </Dialog >
  );
}
