// ScrollToTop.js
import React from 'react'

export default function ScrollToTop({ children, location }) {
  React.useEffect(() => {
    console.log("use scrollTo");
    window.scrollTo(0, 0);
  }, [location.pathname]
  )
  return children
}