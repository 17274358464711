import React from "react";
import {
  useApolloClient, useQuery,
} from "@apollo/react-hooks";
import gql from "graphql-tag";
import { makeStyles } from "@material-ui/core/styles";
import QuoteDateForm from "../components/quote-date-form";
import addDays from "date-fns/addDays";
import { Loading, ChooseLease } from "../components";
import { Box } from "@material-ui/core";


export const GET_CARTVIN_CARTDELIVERY = gql`
  query getCartvin {
    cartLease @client {
      vin
    }
    cartCar @client {
      deliveryDays
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  formBox: { marginTop: 16 }
}));

export default function Dates() {

  const classes = useStyles();

  const client = useApolloClient();
  const { data, loading, error } = useQuery(GET_CARTVIN_CARTDELIVERY);

  if (loading || !data) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;

  return (!data.cartLease || !data.cartLease.vin || !data.cartCar ? <ChooseLease /> : (
    <Box className={classes.formBox}>
      <QuoteDateForm
        // we don't need to have a cartLease to use this page, null for onLeaseMonthsChange in this case
        onLeaseMonthsChange={data.cartLease.vin ? (viewCars) => {
          // console.log(viewCars);
          // const cartViewCar = viewCars.find(viewCar => viewCar.vin === data.cartLease.vin)
          console.log(viewCars)
          client.writeData({
            data: {
              cartLease: {
                ...viewCars.find(viewCar => viewCar.vin === data.cartLease.vin).leaseQuote,
                // customerPickupDate: data.customerPickupDate,
                // insuranceMonthly: data.insuranceMonthly,
                // insuranceAccuracy: data.insuranceAccuracy,
                // customerState: data.customerState,
                // stateAccuracy: data.stateAccuracy,
                // taxRate: data.customerTaxRate,
                // taxRateAccuracy: data.taxRateAccuracy,
                // vin: car.vin,
                // yearMakeModel: `${car.year} ${car.make} ${car.model}`,
              },
            },
          });
        } : undefined}
        // onPickupChange={data.cartLease.vin ? viewCars => console.log(viewCars) : undefined}
        minPickupDate={addDays(new Date(), data.cartCar.deliveryDays)}
      />
    </Box>
  ))
}
