import React, { Fragment } from "react";
// import { Router, Link as RouterLink } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import PageHeading from "../components/page-heading";
import { Typography, Box } from "@material-ui/core";
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  contactDetailContainer: { display: "flex", marginBottom: 2 },

  narrowPageBox: theme.narrowPageBox,
  detailIcon: theme.detailIcon,
  detailIconBox: theme.detailIconBox,
  detailBox: theme.detailBox
}));

export default function ContactUs({ ...other }) {
  const classes = useStyles();

  return (
    <Fragment>
      <Box className={classes.narrowPageBox}>
        <PageHeading isHeadline={true}>Contact us</PageHeading>
        <Box className={classes.contactDetailContainer}>
          <Box className={classes.detailIconBox}>
            {/* how about a &$ kind of double locatoin paddle to indicate that the return is different */}
            <QuestionAnswerIcon fontSize="small" className={classes.detailIcon} />
          </Box>
          <Box className={classes.detailBox}>
            <Typography>text: <Link href="sms:347-754-7794">(347) 754 7794</Link></Typography>
          </Box>
        </Box>
        <Box className={classes.contactDetailContainer}>
          <Box className={classes.detailIconBox}>
            {/* how about a &$ kind of double locatoin paddle to indicate that the return is different */}
            <PhoneIcon fontSize="small" className={classes.detailIcon} />
          </Box>
          <Box className={classes.detailBox}>
            <Typography>call: <Link href="tel:347-754-7794">(347) 754 7794</Link></Typography>
          </Box>
        </Box>
        <Box className={classes.contactDetailContainer}>
          <Box className={classes.detailIconBox}>
            {/* how about a &$ kind of double locatoin paddle to indicate that the return is different */}
            <EmailIcon fontSize="small" className={classes.detailIcon} />
          </Box>
          <Box className={classes.detailBox}>
            <Typography>email: <Link href="mailto:hi@driveskips.com">hi@driveskips.com</Link></Typography>
          </Box>
        </Box>
      </Box>
    </Fragment >
  );
}
