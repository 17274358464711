import React, { Fragment } from "react";
import { Link as RouterLink } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import PageHeading from "../components/page-heading";
import CarImage from "../components/car-image";
import { Typography, Box } from "@material-ui/core";
import { teal, orange, purple } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  staccatoHeading: {
    display: "inline-block",
    borderBottomWidth: 4,
    borderBottomStyle: "solid",
    lineHeight: "0.4",
    fontWeight: 500,
    marginBottom: 8,
    // compensate for reduced line-height
    paddingTop: 8
  },
  staccatoTeal: { borderBottomColor: teal['A200'] },
  staccatoOrange: { borderBottomColor: orange['A200'] },
  staccatoPurple: { borderBottomColor: purple['A200'] },
  whyButton: {
    float: "right"
  },

  calloutBox: {
    [theme.breakpoints.up("md")]: {
      margin: "0 12px",
    },
  },

  subsectionEndBox: theme.subsectionEndBox,
}));

export default function WhyFlexLease({ ...other }) {
  const classes = useStyles();

  return (
    <Fragment>
      <PageHeading isHeadline={true} >Flex lease benefits</PageHeading>
      <Box className={classes.calloutBox}>
        <Typography variant="h6" className={classes.staccatoHeading + " " + classes.staccatoTeal}>Flexible</Typography>
        <Typography variant="body1" className={classes.landerParagraph}>Plans change.  That’s why our lease is flexible.</Typography>
        <Typography variant="body1">Drive on.  Or turn-in without any notice.  You’re not beholden to a return date with Skip’s Flex.</Typography>
        <ul>
          <li><Typography variant="body1">Month-to-month with no set return date</Typography></li>
          <li><Typography variant="body1">Not a commitment to years of future payments</Typography></li>
        </ul>
      </Box>
      <CarImage className={classes.subsectionEndBox} aspectRatio={30} url="https://skips-assets-virginia.s3.amazonaws.com/expedition-3x2.jpg" />
      <Box className={classes.calloutBox + " " + classes.sectionEndBox}>
        <Typography variant="h6" className={classes.staccatoHeading + " " + classes.staccatoOrange}>Easy</Typography>
        <Typography variant="body1">Drive worry-free.  Let us deal with maintenance and repairs.  DMV paperwork?  We handle that too.</Typography>
        <ul>
          <li><Typography variant="body1">No drawn-out application process</Typography></li>
          <li><Typography variant="body1">Reserve online and pick up at a convenient location</Typography></li>
          <li><Typography variant="body1">Let us take care of registration, maintenance and warranty</Typography></li>
        </ul>
      </Box>
      <CarImage className={classes.subsectionEndBox} aspectRatio={30} url="https://skips-assets-virginia.s3.amazonaws.com/Chris_suburban-enhanced-cropped.jpg" />
      <Box className={classes.calloutBox + " " + classes.sectionEndBox}>
        <Typography variant="h6" className={classes.staccatoHeading + " " + classes.staccatoPurple}>Great value</Typography>
        <Typography variant="body1">Simple pricing, competitive rates.</Typography>
        <ul>
          <li><Typography variant="body1">No registration fees, no early termination fees, no turn-in fees</Typography></li>
          <li><Typography variant="body1">Save on sales tax plus get a tax deduction if you use your lease for business</Typography></li>
        </ul>
      </Box>
      <Button
        className={classes.whyButton}
        component={RouterLink}
        variant="contained"
        color="primary"
        disableElevation={true}
        to="/leases"
      >
        Find a flex lease
              </Button>
    </Fragment>
  );
}
