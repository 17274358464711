import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InfiniteScroll from "react-infinite-scroll-component"


// const useStyles = makeStyles((theme) => ({

// }));

export default function InfiniteScrollList({ viewCars, ListItem }) {

  const [scrolledViewCars, setScrolledViewCars] = React.useState([]);
  const [hasMore, setHasMore] = React.useState(true);

  // "listen" on viewCars
  React.useEffect(() => {
    if (viewCars) {
      setScrolledViewCars(viewCars.slice(0, 5));
    }
    setHasMore(true);
  }, [viewCars])

  const addCars = () => {
    if (scrolledViewCars.length >= viewCars.length) {
      setHasMore(false)
      return;
    }
    setScrolledViewCars(viewCars.slice(0, scrolledViewCars.length + 5));
  }

  return (<InfiniteScroll dataLength={scrolledViewCars.length} next={() => { addCars() }} hasMore={hasMore} >
    {scrolledViewCars.map(scrolledCar => <ListItem car={scrolledCar} />)}
  </InfiniteScroll>

  );
}
