import React, { Fragment } from "react";
import {
  useQuery,
} from "@apollo/react-hooks";
import gql from "graphql-tag";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { navigate } from "@reach/router";
import { Loading, PageHeading } from "../components";
import Login from "./login";
import { Grid, Typography, ButtonBase } from "@material-ui/core";

// loadStripe.setLoadParameters({advancedFraudSignals: false})

const useStyles = makeStyles(() => ({
  reserveHeading: { marginBottom: 6 },
  // menuItem: { width: "100%" },
  buttonBase: { display: "block" }
}))

export const GET_CARTLEASE_CARTCAR = gql`
  query getCartLeaseCartCar {
    cartCar @client {
      vin
      year
      make
      model
      images
    }
    cartLease @client {
      __typename
      pickupLocation
      dropoffLocation
      transportCost
      secondLegFee
      dropoffLegFee
      leaseTotal
      firstMonthSalesTax
      firstMonthRentalTax
      monthlySalesTax
      monthlyRentalTax
      proration
      dropoffLegSalesTax
      dropoffLegRentalTax
      paymentsTotal
      leaseFirst
      leaseMonthly
      customerPickupDate
      customerLeaseMonths
      vin
      yearMakeModel
      insuranceMonthly
      insuranceAccuracy
      customerTaxRate
      taxRateAccuracy
      customerState
      stateAccuracy
      createdDate
    }
    customerPickupDate @client
    customerEmail @client
    customerPhone @client
    customerLicenseUri @client
  }
`;

export const GET_CARTLEASE = gql`
  query cartLease @client {
    __typename
    pickupLocation
    dropoffLocation
    transportCost
    secondLegFee
    dropoffLegFee
    leaseTotal
    firstMonthSalesTax
    firstMonthRentalTax
    monthlySalesTax
    monthlyRentalTax
    proration
    dropoffLegSalesTax
    dropoffLegRentalTax
    paymentsTotal
    leaseFirst
    leaseMonthly
    customerPickupDate
    customerLeaseMonths
    vin
    yearMakeModel
    insuranceMonthly
    insuranceAccuracy
    customerTaxRate
    taxRateAccuracy
    customerState
    stateAccuracy
    createdDate
  }
`;

export const GET_LEASEACCOUNTS = gql`
  query getLeaseAccounts {
    accounts {
      skipsLeaseId
      yearMakeModel
      conditionImages
    }
    isLoggedIn @client
  }
`;

export default function AccountsMenu({ subPath, label }) {
  const classes = useStyles();
  const { data, loading, error } = useQuery(GET_LEASEACCOUNTS);

  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;
  if (!data.isLoggedIn) return <Login />

  console.log("data");
  console.log(data);

  return (
    <Fragment>
      <PageHeading>{label}</PageHeading>
      <Grid container spacing={2}>
        {data.accounts.map(account => <Grid item md={6} className={classes.menuItem}><ButtonBase component={Box} className={classes.buttonBase} onClick={() => navigate(`/${subPath}/${account.skipsLeaseId}`)} ><Typography variant="h6">{account.yearMakeModel}</Typography><Typography>{account.skipsLeaseId}</Typography><Typography>{label}</Typography></ButtonBase></Grid>)}
      </Grid>
    </Fragment>
  )
}
